/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useEffect, useState } from 'react'
import { ImCheckmark } from 'react-icons/im'
import { useNavigate } from 'react-router-dom'
import { TextNew } from '../../../components/TextNew'
import { Flex } from '../../../components/layout/Flex'
import { useAuthContext } from '../../../lib/AuthContext'
import { AnalyticsEventType, trackEvent } from '../../../lib/analytics'
import { useT } from '../../../lib/i18n/useT'
import { Spacer } from '../../../styles/margin'
import { buttonReset } from '../../../styles/styles'
import { colors, hexOpacity } from '../../../styles/theme'
import { calculateTimeRemainingClock } from '../../Admin/Utils'
import {
  LeagueQuizMinimal,
  PromotedPredictionQuizQueryResult,
} from '../../League/queries'
import { ShimmerComponent } from '../ShimmerComponent'
import FixtureDisplayComponent from './FixtureDisplayComponent'

const getQuiz = (data: PromotedPredictionQuizQueryResult | undefined) => {
  return data?.league?.latestStartableQuiz
    ? data.league.latestStartableQuiz
    : data?.league?.currentQuiz
    ? data.league.currentQuiz
    : null
}

const getQuizWaitingForResults = (data: PromotedPredictionQuizQueryResult) => {
  return data?.league?.quizzesWaitingForResults
    ? data.league.quizzesWaitingForResults[0]
    : null
}

const isTimeRemaining = (date: Date) => {
  return date > new Date()
}

const BackgroundDecorations = () => (
  <>
    <Flex
      css={css`
        position: absolute;
        top: 0;
        left: 0;
        background-color: ${colors.black};
        width: 45%;
        height: 100%;
      `}
    >
      <Flex
        css={css`
          position: absolute;
          top: 40%; /* Move down */
          right: -50px; /* Move slightly more to the left */
          background-color: ${colors.black};
          width: 50vw;
          height: 30vh;
          transform: rotate(70deg);
          transform-origin: top right;
        `}
      />
      <Flex
        css={css`
          position: absolute;
          top: 100%; /* Move down */
          right: -50px; /* Move slightly more to the left */
          background-color: ${colors.black};
          width: 50vw;
          height: 30vh;
          transform: rotate(70deg);
          transform-origin: top right;
        `}
      />
    </Flex>
  </>
)

const PredictionQuizActive = ({ quiz }: { quiz: LeagueQuizMinimal }) => {
  const [currentTime, setCurrentTime] = useState(new Date())
  const leagueSlug = 'promoted-prediction-league'
  const t = useT()
  const navigate = useNavigate()
  const [currentFixtureIndex, setCurrentFixtureIndex] = useState(0)

  const { authUser, signInAsAnonymous } = useAuthContext()

  useEffect(() => {
    if (!quiz.fixtures || quiz.fixtures.length === 0) return

    const interval = setInterval(() => {
      setCurrentFixtureIndex(
        (prevIndex) => (prevIndex + 1) % quiz.fixtures.length
      )
    }, 5000)

    // Cleanup interval on component unmount
    return () => clearInterval(interval)
  }, [quiz.fixtures, 5000])

  const fixture = quiz.fixtures[currentFixtureIndex]

  // Update the current time every second
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date())
    }, 1000)

    // Clear the interval on component unmount
    return () => clearInterval(interval)
  }, [])

  const play = async (e: any) => {
    if (!authUser) {
      await signInAsAnonymous()
    }

    const quizId = quiz?.id

    if (!quizId) {
      console.log('No quiz found')
      return
    }

    if (quiz.quizType === 'prediction') {
      trackEvent(AnalyticsEventType.StartQuiz, {
        quizId,
        leagueSlug,
      })
      navigate(`/play-quiz/prediction/${quizId}?quizType=${quiz.quizType}`)
      return
    }
    e.stopPropagation()
  }
  return (
    <>
      <button
        onClick={play}
        css={[
          buttonReset,
          css`
            cursor: pointer;
            background-color: ${colors.black};
            border-radius: 10px;
            position: relative;
          `,
        ]}
        aria-label={'Play the prediction quiz'}
      >
        <Flex
          css={css`
            position: absolute;
            top: -8px;
            left: -8px;
            padding: 4px 16px;
            background-color: ${colors.yellow400};
            border-radius: 16px;
            box-shadow: 0 4px 4px ${hexOpacity(colors.black, 0.25)};
            z-index: 2;
          `}
        >
          <TextNew
            strong
            italic
            condensed
            css={css`
              font-size: 16px;
            `}
          >
            {t(`New Game mode!`)}
          </TextNew>
        </Flex>

        <Flex
          css={css`
            height: 250px;
            position: relative;
            overflow: hidden;

            border: 1px solid #565656;

            padding: 20px 15px;

            border-radius: 10px;
            background-color: rgba(35, 241, 179, 0.16);
          `}
          column
          horizontal="center"
          gap="medium"
          vertical="space-between"
        >
          <BackgroundDecorations />

          <Flex
            column
            css={css`
              position: absolute;
              top: 0;
              right: 0;
              padding: 16px;
              text-align: right;
            `}
          >
            <TextNew
              css={css`
                text-shadow: 1px 3px 0 rgba(0, 0, 0, 0.45);
              `}
              size={10}
              condensed
              italic
              uppercase
              weight={700}
              color={colors.white}
            >
              {t('Deadline')}
            </TextNew>
            <TextNew
              css={css`
                text-shadow: 1px 3px 0 rgba(0, 0, 0, 0.45);
              `}
              size={26}
              italic
              condensed
              weight={500}
              color={colors.white}
            >
              {fixture &&
                quiz.availableTo &&
                calculateTimeRemainingClock(
                  currentTime,
                  new Date(quiz.availableTo)
                )}
            </TextNew>
          </Flex>

          <Flex
            column
            vertical={'space-between'}
            horizontal={'center'}
            css={css`
              width: 100%;
              height: 100%;
              z-index: 2;
            `}
          >
            <TextNew
              css={css`
                text-shadow: 1px 3px 0 rgba(0, 0, 0, 0.45);
              `}
              size={25}
              italic
              condensed
              color={colors.white}
            >
              {quiz.title}
            </TextNew>

            {fixture && (
              <Flex
                css={css`
                  width: 100%;
                  z-index: 2;
                `}
              >
                <FixtureDisplayComponent fixture={fixture} />
              </Flex>
            )}

            <Flex
              css={css`
                border-radius: 99px;
                padding: 10px 40px;
                background-color: ${colors.yellow400};
                border: 0;
                box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
                z-index: 2;
              `}
            >
              <TextNew
                size={24}
                condensed
                italic
                strong
                css={css`
                  white-space: nowrap;
                  text-transform: capitalize;
                `}
              >
                {t(`Play the Prediction Quiz`)}
              </TextNew>
            </Flex>
          </Flex>
        </Flex>
      </button>
    </>
  )
}

export const PredictionQuizWaiting = ({
  quiz,
}: {
  quiz: LeagueQuizMinimal
}) => {
  const [currentTime, setCurrentTime] = useState(new Date())
  const t = useT()
  const [currentFixtureIndex, setCurrentFixtureIndex] = useState(0)

  const navigate = useNavigate()

  useEffect(() => {
    if (!quiz.fixtures || quiz.fixtures.length === 0) return

    const interval = setInterval(() => {
      setCurrentFixtureIndex(
        (prevIndex) => (prevIndex + 1) % quiz.fixtures.length
      )
    }, 5000)

    // Cleanup interval on component unmount
    return () => clearInterval(interval)
  }, [quiz.fixtures, 5000])

  const fixture = quiz.fixtures[currentFixtureIndex]

  // Update the current time every second
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date())
    }, 1000)

    // Clear the interval on component unmount
    return () => clearInterval(interval)
  }, [])

  const goToQuiz = () => {
    navigate(`/play-quiz/prediction/${quiz.id}?quizType=prediction`)
  }

  return (
    <>
      <button
        onClick={goToQuiz}
        css={[
          buttonReset,
          css`
            cursor: pointer;
            background-color: ${colors.black};
            border-radius: 10px;
            position: relative;
          `,
        ]}
        aria-label={t('Play the daily quiz')}
      >
        <Flex
          css={css`
            position: absolute;
            top: -8px;
            left: 0;
            right: 0;
            margin: 0 auto;
            padding: 4px 16px;
            background-color: ${colors.green300};
            border-radius: 16px;
            box-shadow: 0 4px 4px ${hexOpacity(colors.black, 0.25)};
            z-index: 2;
            width: fit-content; /* Ensure the width is only as wide as the content */
          `}
        >
          <TextNew
            strong
            italic
            condensed
            css={css`
              font-size: 16px;
            `}
          >
            {t(`Waiting for the results`)}
          </TextNew>
        </Flex>
        <Flex
          css={css`
            position: absolute;
            top: -8px;
            right: -8px;
            padding: 4px;
            background-color: ${colors.green300};
            border-radius: 50%;
            box-shadow: 0 4px 4px ${hexOpacity(colors.black, 0.25)};
            z-index: 2;
            width: fit-content; /* Ensure the width is only as wide as the content */
          `}
        >
          <ImCheckmark size={16} />
        </Flex>
        <Flex
          css={css`
            height: 250px;
            position: relative;
            overflow: hidden;

            border: 1px solid #565656;

            padding: 20px 15px;

            border-radius: 10px;
            background-color: rgba(35, 241, 179, 0.16);
          `}
          column
          horizontal="center"
          gap="medium"
          vertical="space-between"
        >
          <BackgroundDecorations />

          {isTimeRemaining(new Date(quiz.availableTo || '')) && (
            <Flex
              column
              css={css`
                position: absolute;
                top: 0;
                right: 0;
                padding: 16px;
                text-align: right;
              `}
            >
              <TextNew
                css={css`
                  text-shadow: 1px 3px 0 rgba(0, 0, 0, 0.45);
                `}
                size={10}
                condensed
                italic
                uppercase
                weight={700}
                color={colors.white}
              >
                {t('Deadline')}
              </TextNew>
              <TextNew
                css={css`
                  text-shadow: 1px 3px 0 rgba(0, 0, 0, 0.45);
                `}
                size={26}
                italic
                condensed
                weight={500}
                color={colors.white}
              >
                {fixture &&
                  quiz.availableTo &&
                  calculateTimeRemainingClock(
                    currentTime,
                    new Date(quiz.availableTo)
                  )}
              </TextNew>
            </Flex>
          )}

          <Flex
            column
            vertical={'space-between'}
            horizontal={'center'}
            css={css`
              width: 100%;
              height: 100%;
              z-index: 2;
            `}
          >
            <TextNew
              css={css`
                text-shadow: 1px 3px 0 rgba(0, 0, 0, 0.45);
              `}
              size={25}
              italic
              condensed
              color={colors.white}
            >
              {quiz.title}
            </TextNew>

            {fixture && (
              <Flex
                css={css`
                  width: 100%;
                  z-index: 2;
                `}
              >
                <FixtureDisplayComponent fixture={fixture} />
              </Flex>
            )}

            <Flex
              css={css`
                border-radius: 99px;
                padding: 10px 40px;
                background-color: ${colors.grey300};
                border: 0;
                box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
                z-index: 2;
              `}
            >
              <TextNew
                size={24}
                condensed
                italic
                strong
                css={css`
                  white-space: nowrap;
                  text-transform: capitalize;
                `}
              >
                {t(`Review your predictions`)}
              </TextNew>
            </Flex>
          </Flex>
        </Flex>
      </button>
    </>
  )
}

const PredictionQuizFinished = ({ quiz }: { quiz: LeagueQuizMinimal }) => {
  const t = useT()

  const navigate = useNavigate()

  const fixture = quiz.fixtures[0] ?? undefined

  const goToQuiz = () => {
    navigate(`/play-quiz/prediction/${quiz.id}?quizType=prediction`)
  }

  return (
    <>
      <button
        onClick={goToQuiz}
        css={[
          buttonReset,
          css`
            cursor: pointer;
            background-color: ${colors.black};
            border-radius: 10px;
            position: relative;
          `,
        ]}
        aria-label={t('Play the daily quiz')}
      >
        <Flex
          css={css`
            position: absolute;
            top: -8px;
            left: 0;
            right: 0;
            margin: 0 auto;
            padding: 4px 16px;
            background-color: ${colors.green300};
            border-radius: 16px;
            box-shadow: 0 4px 4px ${hexOpacity(colors.black, 0.25)};
            z-index: 2;
            width: fit-content; /* Ensure the width is only as wide as the content */
          `}
        >
          <TextNew
            strong
            italic
            condensed
            css={css`
              font-size: 16px;
            `}
          >
            {t(`Results are in!`)}
          </TextNew>
        </Flex>
        <Flex
          css={css`
            position: absolute;
            top: -8px;
            right: -8px;
            padding: 4px;
            background-color: ${colors.green300};
            border-radius: 50%;
            box-shadow: 0 4px 4px ${hexOpacity(colors.black, 0.25)};
            z-index: 2;
            width: fit-content;
          `}
        >
          <ImCheckmark size={16} />
        </Flex>
        <Flex
          css={css`
            height: 250px;
            position: relative;
            overflow: hidden;

            border: 1px solid #565656;

            padding: 20px 15px;

            border-radius: 10px;
            background-color: rgba(35, 241, 179, 0.16);
          `}
          column
          horizontal="center"
          gap="medium"
          vertical="space-between"
        >
          <BackgroundDecorations />

          <Flex
            column
            vertical={'space-between'}
            horizontal={'center'}
            css={css`
              width: 100%;
              height: 100%;
              z-index: 2;
            `}
          >
            <Spacer height={'medium'} />
            {fixture && (
              <Flex
                css={css`
                  width: 100%;
                  z-index: 2;
                `}
              >
                <FixtureDisplayComponent fixture={fixture} />
              </Flex>
            )}

            <Flex
              css={css`
                border-radius: 99px;
                padding: 10px 40px;
                background-color: ${colors.green300};
                border: 0;
                box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
                z-index: 2;
              `}
            >
              <TextNew
                size={24}
                condensed
                italic
                strong
                css={css`
                  white-space: nowrap;
                  text-transform: capitalize;
                `}
              >
                {t(`View your results!`)}
              </TextNew>
            </Flex>
          </Flex>
        </Flex>
      </button>
    </>
  )
}

export const PromotedPredictionQuiz = ({
  data,
  loading,
}: {
  data?: PromotedPredictionQuizQueryResult
  loading?: boolean
}) => {
  if (loading) {
    return (
      <ShimmerComponent
        backgroundColor={colors.dailyYellow}
        css={css`
          border-radius: 10px;
        `}
      />
    )
  }

  if (!data) {
    return <></>
  }

  const todaysQuiz = getQuiz(data)

  const quizWaitingForResults = getQuizWaitingForResults(data)

  const isWaiting =
    (!todaysQuiz || !todaysQuiz.viewerCanStartQuiz) &&
    quizWaitingForResults != null

  const latestCompletedPredictionQuiz =
    data?.league?.latestCompletedPredictionQuiz

  if (isWaiting) {
    return (
      <>
        <PredictionQuizWaiting quiz={quizWaitingForResults} />
        <Spacer height={'large'} />
      </>
    )
  }

  if (todaysQuiz) {
    return (
      <>
        <PredictionQuizActive quiz={todaysQuiz} />
        <Spacer height={'large'} />
      </>
    )
  }

  if (latestCompletedPredictionQuiz) {
    return (
      <>
        <PredictionQuizFinished quiz={latestCompletedPredictionQuiz} />
        <Spacer height={'large'} />
      </>
    )
  }

  return <></>
}
