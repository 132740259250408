/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { DefaultError } from '../../../components/DefaultError'
import { Header } from '../../../components/Header'
import { Heading } from '../../../components/Heading'
import { Loader } from '../../../components/Loader'
import { Flex } from '../../../components/layout/Flex'
import { Layout } from '../../../components/layout/Layout'
import { Page } from '../../../components/leagueQuiz/Page'
import { useAuthContext, useViewerQuery } from '../../../lib/AuthContext'
import { getCurrentLocale, Locale, locales } from '../../../lib/i18n/locale'
import { useT } from '../../../lib/i18n/useT'
import { colors } from '../../../styles/theme'
import { hasOneOfClubRoles } from '../../Clubs/clubUtils'
import { useCheckSlugAccess } from '../../Generate/queries'
import { TranslateQuestion } from '../TranslateQuestion'
import { QuestionTranslate, useGetQuizQuery } from '../queries'
import { Overview } from './Overview'

enum Step {
  None,
  Questions,
  Finish,
}

export type Question = {
  id: string
  question: string
  alternatives: {
    text: string
    reference?: string
  }[]
}

export const questionsFromData = (
  dataQuestions: QuestionTranslate[] | undefined,
  language: Locale
) => {
  if (!dataQuestions) return []
  return dataQuestions.map((it) => {
    const isFilled =
      it.question[language] &&
      it.alternatives.map((it) => it.text[language]).every(Boolean)

    function isNumeric(text: string) {
      return /^-?\d+$/.test(text)
    }

    const isNumbers = it.alternatives
      .map((it) => Object.values(it.text))
      .every((it) => it.every(isNumeric))

    if (!isFilled && isNumbers) {
      return {
        id: it.id,
        question: it.question[language] ?? '',
        alternatives: it.alternatives.map((it) => {
          return {
            text: Object.values(it.text).find((it) => it !== '') ?? '',
            reference: it.reference,
          }
        }),
      }
    }

    return {
      id: it.id,
      question: it.question[language] ?? '',
      alternatives: it.alternatives.map((it) => {
        return { text: it.text[language] ?? '', reference: it.reference }
      }),
    }
  })!
}

const Content = (props: { leagueSlug: string; quizSlug: string }) => {
  const t = useT()
  const { authUser } = useAuthContext()
  const authenticated = Boolean(authUser)
  const viewerQuery = useViewerQuery({ skip: !authenticated })
  const isTranslater = viewerQuery.data?.viewer?.isTranslater

  const [step, setStep] = useState<Step>(Step.Finish)
  const [editQuestionIndex, setEditQuestionIndex] = useState(-1)

  const [fromLanguage, setFromLanguage] = useState<Locale | null>(null)
  const [availableLanguages, setAvailableLanguages] = useState<Locale[]>([])
  const [toLanguage, setToLanguage] = useState<Locale | null>(null)
  const [prevToLanguage, setPrevToLanguage] = useState<string | null>(null)
  const [questionFrom, setQuestionFrom] = useState<Question | null>(null)
  const [questionTo, setQuestionTo] = useState<Question | null>(null)

  const [questionsFrom, setQuestionsFrom] = useState<Question[]>([])
  const [questionsTo, setQuestionsTo] = useState<Question[]>([])
  const [prevQuestionsTo, setPrevQuestionsTo] = useState<Question[]>([])

  const [cacheQuizData, setCacheQuizData] = useState<string>('')

  const quiz = useGetQuizQuery(
    { leagueSlug: props.leagueSlug, quizid: props.quizSlug },
    false
  )

  const quizData =
    quiz.data && 'getQuiz' in quiz.data ? quiz.data?.getQuiz?.questions : []
  const quizTitle =
    quiz.data && 'getQuiz' in quiz.data ? quiz.data?.getQuiz?.title : undefined
  const quizPromoText =
    quiz.data && 'getQuiz' in quiz.data
      ? quiz.data?.getQuiz?.promotionalText
      : undefined

  const [title, setTitle] = useState<string | undefined | null>(
    quizTitle && toLanguage ? quizTitle[toLanguage] : null
  )

  const [promoText, setPromoText] = useState<string | undefined | null>(
    quizPromoText && toLanguage ? quizPromoText[toLanguage] : null
  )

  const currentVersion = 'V2' //Bump this whenever new fields or changes to fields are introduced

  useEffect(() => {
    if (!props.leagueSlug || !props.quizSlug) return
    const oldDataJSON = window.localStorage.getItem(
      props.leagueSlug + '|' + props.quizSlug
    )
    const oldDataVersion = window.localStorage.getItem(
      props.leagueSlug + '|' + props.quizSlug + '|version'
    )
    if (oldDataVersion !== currentVersion) return
    if (!oldDataJSON) return
    const {
      quizData,
      fromLanguage,
      questionsTo,
      prevQuestionsTo,
      toLanguage,
      title,
      promoText,
    } = JSON.parse(oldDataJSON)

    setCacheQuizData(JSON.stringify(quizData))
    setFromLanguage(fromLanguage)
    setQuestionsTo(questionsTo)
    setPrevQuestionsTo(prevQuestionsTo)
    setPrevToLanguage(toLanguage)
    setToLanguage(toLanguage)
    setTitle(title)
    setPromoText(promoText)
  }, [])

  const jsonData = () => {
    return JSON.stringify({
      quizData: cacheQuizData !== '' ? JSON.parse(cacheQuizData) : quizData,
      fromLanguage,
      questionsTo,
      prevQuestionsTo,
      toLanguage,
      title,
      promoText,
    })
  }

  useEffect(() => {
    if (!props.leagueSlug || !props.quizSlug) return
    if (!quiz.data) return
    window.localStorage.setItem(
      props.leagueSlug + '|' + props.quizSlug,
      jsonData()
    )
    window.localStorage.setItem(
      props.leagueSlug + '|' + props.quizSlug + '|version',
      currentVersion
    )
  }, [
    quizData,
    cacheQuizData,
    fromLanguage,
    questionsTo,
    prevQuestionsTo,
    toLanguage,
    title,
    promoText,
  ])

  useEffect(() => {
    if (quizTitle && toLanguage) {
      setTitle(quizTitle[toLanguage])
    }
    if (quizPromoText && toLanguage) {
      setPromoText(quizPromoText[toLanguage])
    }
  }, [toLanguage])

  const [useAI, setUseAI] = useState<boolean>(true)

  const navigate = useNavigate()

  useEffect(() => {
    setQuestionFrom(questionsFrom[editQuestionIndex])
    setQuestionTo(questionsTo[editQuestionIndex])
  }, [editQuestionIndex])

  useEffect(() => {
    if (quiz.data && fromLanguage) {
      loadQuestionsFrom(fromLanguage as Locale)
    }
  }, [quiz.data, fromLanguage])

  useEffect(() => {
    if (!quiz.data || !toLanguage) return
    const cacheDiffers =
      toLanguage === prevToLanguage &&
      cacheQuizData !== '' &&
      JSON.stringify(quizData) !== cacheQuizData
    if (toLanguage !== prevToLanguage || cacheDiffers) {
      if (!cacheDiffers) {
        if (JSON.stringify(prevQuestionsTo) !== JSON.stringify(questionsTo)) {
          if (
            !confirm(
              'This will clear your progress, are you sure you want to switch language?'
            )
          ) {
            setToLanguage(prevToLanguage as Locale)
            return
          }
        }
      } else {
        if (
          JSON.stringify(questionsTo) !== JSON.stringify(prevQuestionsTo) &&
          !confirm(
            'New data was retrieved from server. Do you want to override your local changes?'
          )
        ) {
          return
        }
        setCacheQuizData('') // This allows saving a new cacheQuizData
      }
      loadQuestionsTo(toLanguage as Locale)
    }
  }, [quiz.data, toLanguage])

  useEffect(() => {
    window.onbeforeunload = confirmExit
    function confirmExit() {
      return t(
        `Are you sure you want to leave? Changes you made may not be saved.`
      )
    }
    return () => {
      window.onbeforeunload = null
    }
  }, [])

  const loadQuestionsFrom = (fromLanguage: Locale) => {
    if (!quiz.data) return

    setFromLanguage(fromLanguage)

    const questionsFrom = questionsFromData(quizData, fromLanguage)

    setQuestionsFrom(questionsFrom)
  }

  const loadQuestionsTo = (toLanguage: Locale) => {
    if (!quiz.data) return

    setToLanguage(toLanguage)
    setPrevToLanguage(toLanguage)

    setQuestionsTo(questionsFromData(quizData, toLanguage))
    setPrevQuestionsTo(questionsFromData(quizData, toLanguage))
  }

  useEffect(() => {
    if (quiz.data && 'getQuiz' in quiz.data && quiz.data?.getQuiz?.questions) {
      const questions = quiz.data?.getQuiz?.questions
      const availableLanguages = locales.filter((locale) =>
        questions.every((it) => {
          const isFilled =
            it.question[locale] &&
            it.alternatives.map((it) => it.text[locale]).every(Boolean)

          function isNumeric(text: string) {
            return /^-?\d+$/.test(text)
          }

          const isNumbers = it.alternatives
            .map((it) => Object.values(it.text))
            .every((it) => it.every(isNumeric))
          return isFilled || isNumbers
        })
      )
      setAvailableLanguages(availableLanguages)
      if (availableLanguages.length == 0) {
        alert(
          'No language available to translate from in this quiz. Is it missing some data? Exiting...'
        )
        navigate('/')
      }
      const fromLanguage = availableLanguages.includes(Locale.en)
        ? Locale.en
        : availableLanguages[0]
      setFromLanguage(fromLanguage) //loadQuestionFrom is called by useEffect
      const missingLanguages = locales.filter(
        (it) => !availableLanguages.includes(it)
      )
      if (missingLanguages.length == 0) {
        alert('All languages has been translated. Exiting...')
        navigate('/')
      }

      if (!prevToLanguage) {
        const toLanguage = missingLanguages.includes(getCurrentLocale())
          ? getCurrentLocale()
          : missingLanguages[0]
        setToLanguage(toLanguage) //loadQuestionFrom is called by useEffect
      }
    }
  }, [quiz.data])

  const { data, loading } = useCheckSlugAccess(props.leagueSlug ?? '')
  const clubAccess = data?.checkSlugAccess.find((club) =>
    hasOneOfClubRoles(club.viewerRole, 'admin')
  )

  const hasAccess = isTranslater || (props.leagueSlug && clubAccess)
  if (!hasAccess) {
    if (props.leagueSlug && loading) {
      return (
        <Flex horizontal="center">
          <Loader />
        </Flex>
      )
    }
    return (
      <>
        <Heading level={2}>Forbidden</Heading>
        <Heading level={5} strong={false}>
          You don't have permission to access this resource.
        </Heading>
      </>
    )
  }
  if (!fromLanguage || !toLanguage || !quizTitle) {
    return (
      <Flex horizontal="center" column gap="medium">
        <Loader />
      </Flex>
    )
  }
  return (
    <>
      <Flex horizontal="center">
        <Flex
          css={css`
            position: absolute;
            margin-top: -95px;
          `}
          gap="huge"
          vertical="center"
        >
          <Flex gap="small">
            <Heading
              level={6}
              strong={false}
              css={css`
                margin: auto 0;
              `}
            >
              Use AI:
            </Heading>
            <input
              type="checkbox"
              checked={useAI}
              onChange={(event) => {
                setUseAI(event.target.checked)
              }}
              css={css`
                margin: 0;
                height: 30px;
                width: 30px;
              `}
            />
          </Flex>
        </Flex>
      </Flex>

      {step === Step.Questions && questionFrom && questionTo && (
        <TranslateQuestion
          fromLanguage={fromLanguage}
          toLanguage={toLanguage}
          questionFrom={questionFrom}
          setQuestionFrom={setQuestionFrom}
          questionTo={questionTo}
          setQuestionTo={setQuestionTo}
          goback={() => {
            setEditQuestionIndex(-1)
            setStep(Step.Finish)
          }}
          editQuestionIndex={editQuestionIndex}
          questionsTo={questionsTo}
          setQuestionsTo={setQuestionsTo}
          quizSlug={props.quizSlug}
          useAI={useAI}
          leagueSlug={
            quiz.data && 'getQuiz' in quiz.data && quiz.data?.getQuiz?.leagueId
              ? quiz.data?.getQuiz?.leagueId
              : ''
          }
        />
      )}

      {step === Step.Finish && (
        <Overview
          questionsFrom={questionsFrom}
          questionsTo={questionsTo}
          setEditQuestionIndex={setEditQuestionIndex}
          addMoreQuestions={() => {
            setStep(Step.Questions)
          }}
          fromLanguage={fromLanguage}
          toLanguage={toLanguage}
          setFromLanguage={setFromLanguage}
          setToLanguage={setToLanguage}
          availableLanguages={availableLanguages}
          title={title}
          setTitle={setTitle}
          promoText={promoText}
          setPromoText={setPromoText}
          quizId={props.quizSlug}
          quizTitle={quizTitle}
          quizPromoText={quizPromoText}
          leagueId={
            quiz.data && 'getQuiz' in quiz.data && quiz.data?.getQuiz?.leagueId
              ? quiz.data?.getQuiz?.leagueId
              : ''
          }
        />
      )}
    </>
  )
}

export const TranslateQuizPage = () => {
  const { slug, quizId } = useParams<{ slug: string; quizId: string }>()

  if (!slug || !quizId) {
    return (
      <DefaultError sentryErrorMessage="TranslateQuizPage | No slug provided" />
    )
  }

  return (
    <Layout backgroundColor={colors.grey100}>
      <Header color="grey" />
      <Page dense>
        <Content leagueSlug={slug} quizSlug={quizId} />
      </Page>
    </Layout>
  )
}
