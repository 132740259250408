import { useEffect, useState } from 'react'
import { Locale } from '../../../lib/i18n/locale'
import { useT } from '../../../lib/i18n/useT'

export type ScreenSize = 'small' | '1080p' | '1440p' | '4k'

export const itemHeightFromScreenSize = (screenSize: ScreenSize) => {
  switch (screenSize) {
    case 'small':
      return 50
    case '1080p':
      return 55
    case '1440p':
      return 80
    case '4k':
      return 90
  }
}

export const imageMaxHeightFromScreenSize = (screenSize: ScreenSize) => {
  switch (screenSize) {
    case 'small':
      return 200
    case '1080p':
      return 200
    case '1440p':
      return 300
    case '4k':
      return 400
  }
}

export const imageMaxHeightFromScreenSizeDisplayIngame = (
  screenSize: ScreenSize
) => {
  switch (screenSize) {
    case 'small':
      return 100
    case '1080p':
      return 100
    case '1440p':
      return 200
    case '4k':
      return 300
  }
}

export const itemFontSizeFromScreenSize = (screenSize: ScreenSize) => {
  switch (screenSize) {
    case 'small':
      return 28
    case '1080p':
      return 30
    case '1440p':
      return 34
    case '4k':
      return 48
  }
}
export const itemNumberFontSizeFromScreenSize = (screenSize: ScreenSize) => {
  switch (screenSize) {
    case 'small':
      return 32
    case '1080p':
      return 38
    case '1440p':
      return 42
    case '4k':
      return 56
  }
}

export const itemBottomMarginFromScreenSize = (screenSize: ScreenSize) => {
  switch (screenSize) {
    case 'small':
      return 3
    case '1080p':
      return 3
    case '1440p':
      return 8
    case '4k':
      return 10
  }
}

export const itemAvatarSpacingFromScreenSize = (screenSize: ScreenSize) => {
  switch (screenSize) {
    case 'small':
      return 12
    case '1080p':
      return 12
    case '1440p':
      return 16
    case '4k':
      return 20
  }
}

export const itemScoreMarginFromScreenSize = (screenSize: ScreenSize) => {
  switch (screenSize) {
    case 'small':
      return 4
    case '1080p':
      return 8
    case '1440p':
      return 12
    case '4k':
      return 16
  }
}

export const leaderboardHeaderHeightFromScreenSize = (
  screenSize: ScreenSize
) => {
  switch (screenSize) {
    case 'small':
      return 58
    case '1080p':
      return 58
    case '1440p':
      return 72
    case '4k':
      return 80
  }
}

export type ClubExtraInfo = {
  ctaText?: string
  logoUrl?: string
  textColor?: string
  language?: string
  partnerGraphicUrl?: string
}

export const tromsoInfo = {
  ctaText: 'Bli med i TIL Quiz-ligaen',
  language: 'no',
}

export const obosInfo = {
  ctaText: 'Spill OBOS-quizen',
  language: 'no',
}

export const fotballFestenInfo = {
  ctaText: 'Bli med på Fotballfesten',
  language: 'no',
  partnerGraphicUrl: '/images/fotballfesten-partner.png',
}

export const toToInfo = {
  ctaText: 'Bli med i To-To-ligaen',
  language: 'no',
}

export const scotsmanInfo = {
  ctaText: 'Spill Scotsmans fotball-quiz',
  language: 'no',
}

export const slakternInfo = {
  partnerGraphicUrl: '/images/slaktern-partner.png',
}

export const specialClubInfo: Record<string, ClubExtraInfo> = {
  tromso: tromsoInfo,
  obos: obosInfo,
  fotballfesten: fotballFestenInfo,
  'to-to': toToInfo,
  scotsman: scotsmanInfo,
}

export const specialClubs = [
  'tromso',
  'obos',
  'fotballfesten',
  'to-to',
  'scotsman',
  'slaktern-pub',
]

export const getSpecialClubLanguage = ({
  slug,
  locale,
}: {
  slug?: string
  locale?: Locale
}) => {
  if (!slug) {
    return locale
  }

  return specialClubInfo[slug]?.language ?? locale
}

export const isSpecialClub = (slug: string) => {
  return specialClubs.includes(slug)
}

export const getTextColorForClub = ({
  slug,
  textColor,
}: {
  slug?: string
  textColor?: string
}) => {
  if (!slug) {
    return textColor
  }

  if (isSpecialClub(slug)) {
    const specialColor = specialClubInfo[slug].textColor

    if (specialColor) {
      return specialColor
    }
  }

  return textColor
}

export const getIconImageForClub = ({
  slug,
  logoUrl,
}: {
  slug?: string
  logoUrl?: string | null
}) => {
  if (!slug) {
    return logoUrl
  }

  if (isSpecialClub(slug)) {
    const specialLogoUrl = specialClubInfo[slug].logoUrl

    if (specialLogoUrl) {
      return specialLogoUrl
    }
  }
  return logoUrl
}

export const getCTATextForClub = ({
  slug,
  name,
}: {
  slug?: string
  name?: string
}) => {
  const t = useT()

  if (!slug) {
    return name
  }

  if (isSpecialClub(slug)) {
    const specialCtaText = specialClubInfo[slug].ctaText

    if (specialCtaText) {
      return specialCtaText
    }
  }

  return t('Join {{name}}', {
    name: name,
  })
}

export const getLiveQuizJoinUrl = (invitationCode: string, locale?: string) => {
  if (locale) {
    return `${window.location.protocol}//${window.location.host}/${locale}/join/${invitationCode}`
  }

  return `${window.location.protocol}//${window.location.host}/join/${invitationCode}`
}

export const getParnerGraphicUrlForLeague = ({ slug }: { slug?: string }) => {
  if (!slug) {
    return undefined
  }
  return specialClubInfo[slug]?.partnerGraphicUrl
}

export const useScreenWidth = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return screenWidth
}
