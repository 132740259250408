import { gql, useMutation, useQuery } from '@apollo/client'
import { QuizType } from '../Generate/GeneratePage'
import {
  authenticLeagueFragment,
  authenticTeamFragment,
  homePageQuizFragment,
} from '../Home/queries'
import { LeagueQuizMinimal } from '../League/queries'

type QuestionAnswer = {
  questionId: string
  alternativeId: string | null
  startedAt: Date
  answeredAt: Date
  correct: boolean
  score: number
  timeBonus: number
}

type SubmitQuizAnswersInput = {
  quizId: string
  leagueId?: string
  answers: QuestionAnswer[]
  quizType: QuizType
}

export type QuizStatistics = {
  id: string
  questions: {
    id: string
    alternatives: {
      id: string
      percentage: number
    }[]
  }[]
}

const submitQuizAnwersMutation = gql`
  mutation submitQuizAnswers($input: SubmitQuizAnswersInput!) {
    submitQuizAnswers(input: $input) {
      id
    }
  }
`

export const useSubmitQuizAnswersMutation = () => {
  return useMutation<
    { submitQuizAnswers: { id: string } },
    { input: SubmitQuizAnswersInput }
  >(submitQuizAnwersMutation)
}

//quizStatistics(quizId: String!): QuizStatistcs

const quizStatisticsQuery = gql`
  query quizStatistics($quizId: String!) {
    quizStatistics(quizId: $quizId) {
      id
      questions {
        id
        alternatives {
          id
          percentage
        }
      }
    }
  }
`

export const useQuizStatisticsQuery = (quizId: string) => {
  return useQuery<{ quizStatistics: QuizStatistics }>(quizStatisticsQuery, {
    variables: { quizId },
  })
}

export const useGetPredictionQuizInstancesByUserQuery = (params: {
  userId: string
  skip?: boolean
}) => {
  return useQuery<{ getPredictionQuizzesByUser: LeagueQuizMinimal[] }>(
    gql`
      query getPredictionQuizzesByUser($userId: String!) {
        getPredictionQuizInstancesByUser(userId: $userId) {
          ...QuizFragment
          isMissingAnswers
        }
      }
      ${authenticTeamFragment}
      ${authenticLeagueFragment}
      ${homePageQuizFragment}
    `,
    {
      variables: { userId: params.userId },
      skip: params.skip ?? false,
    }
  )
}
