/** @jsxImportSource @emotion/react */

import { Fixture } from '../../Generate/queries'
import { Flex } from '../../../components/layout/Flex'
import { css, keyframes } from '@emotion/react'
import { TextNew } from '../../../components/TextNew'
import { colors } from '../../../styles/theme'
import { useEffect, useState } from 'react'

const flipIn = keyframes`
    0% { opacity: 0; transform: rotateY(90deg); }
    100% { opacity: 1; transform: rotateY(0deg); }
`

const flipOut = keyframes`
  0% { opacity: 1; transform: rotateY(0deg); }
  100% { opacity: 0; transform: rotateY(-90deg); }
`

const FixtureDisplayComponent = ({ fixture }: { fixture: Fixture }) => {
  const [localFixture, setLocalFixture] = useState(fixture)
  const [isAnimating, setIsAnimating] = useState(false)

  // Trigger animation when fixture changes
  useEffect(() => {
    if (fixture !== localFixture) {
      setIsAnimating(true)
      setTimeout(() => {
        setLocalFixture(fixture)
        setIsAnimating(false)
      }, 300) // Match animation duration
    }
  }, [fixture, localFixture])

  const dateObj = new Date(localFixture.date)

  return (
    <Flex
      horizontal={'space-between'}
      css={css`
        width: 100%;
      `}
    >
      {/* Home Team */}
      <Flex
        column
        horizontal="center"
        gap={'small'}
        css={css`
          width: 40%;
          max-width: 40%;
          text-align: center;
        `}
      >
        <TextNew
          condensed
          uppercase
          strong
          size={20}
          color={colors.white}
          css={css`
            animation: ${isAnimating ? flipOut : flipIn} 0.3s ease-out;
          `}
        >
          {localFixture.homeTeam.name}
        </TextNew>
        <img
          src={localFixture.homeTeam.logoUrl}
          alt={localFixture.homeTeam.name}
          width={60}
          css={css`
            animation: ${isAnimating ? flipOut : flipIn} 0.3s ease-out;
          `}
        />
      </Flex>

      {/* League Info */}
      <Flex
        column
        horizontal="center"
        vertical="center"
        gap={'small'}
        css={css`
          width: 20%;
        `}
      >
        <img
          src={localFixture.league.logoUrl}
          alt={localFixture.league.logoUrl}
          css={css`
            width: 36px;
            max-width: 500px;
          `}
        />
        <Flex column horizontal={'center'}>
          <TextNew
            condensed
            uppercase
            strong
            size={18}
            color={colors.white}
            css={css`
              padding: 0;
            `}
          >
            {dateObj.toLocaleDateString(undefined, { day: '2-digit' })}{' '}
            {dateObj.toLocaleDateString(undefined, { month: 'long' })}
          </TextNew>

          <TextNew
            condensed
            uppercase
            strong
            size={18}
            color={colors.white}
            css={css`
              padding: 0;
            `}
          >
            {dateObj.toLocaleTimeString(undefined, {
              hour: '2-digit',
              minute: '2-digit',
            })}
          </TextNew>
        </Flex>
      </Flex>

      {/* Away Team */}
      <Flex
        column
        horizontal="center"
        gap={'small'}
        css={css`
          width: 40%;
          max-width: 40%;
          text-align: center;
        `}
      >
        <TextNew
          condensed
          uppercase
          strong
          size={20}
          color={colors.white}
          css={css`
            animation: ${isAnimating ? flipOut : flipIn} 0.3s ease-out;
          `}
        >
          {localFixture.awayTeam.name}
        </TextNew>
        <img
          src={localFixture.awayTeam.logoUrl}
          alt={localFixture.awayTeam.name}
          width={60}
          css={css`
            animation: ${isAnimating ? flipOut : flipIn} 0.3s ease-out;
          `}
        />
      </Flex>
    </Flex>
  )
}

export default FixtureDisplayComponent
