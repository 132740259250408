/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useMemo, useState } from 'react'
import { IconButton, PrimaryButton } from '../../../components/Button'
import { Flex } from '../../../components/layout/Flex'
import { Loader } from '../../../components/Loader'
import { Modal } from '../../../components/modal/Modal'
import { TextNew } from '../../../components/TextNew'
import { colors } from '../../../styles/theme'
import { Fixture, useGetFixturesQuery } from '../queries'
import { FixtureDisplay } from './FixtureDisplay'

export default function FixtureSelectModal({
  onClose,
  setFixtures,
  fixtures,
}: {
  onClose: () => void
  setFixtures: (fixtures: Fixture[]) => void
  fixtures: Fixture[]
}) {
  const { data: fixtureData, loading: fixtureLoading } = useGetFixturesQuery()
  const [selectedFixtures, setSelectedFixtures] = useState<Fixture[]>(fixtures)
  const selectedIds = selectedFixtures.map((f) => f.id)

  const [filterLeague, setFilterLeague] = useState<string | undefined>(
    undefined
  )

  const handleClickFixture = (fixture: Fixture) => {
    if (selectedIds.includes(fixture.id)) {
      setSelectedFixtures(selectedFixtures.filter((f) => f.id !== fixture.id))
    } else {
      setSelectedFixtures([...selectedFixtures, fixture])
    }
  }

  const fetchedFixtures = fixtureData?.getFixtures ?? []

  const sorted = useMemo(() => {
    return [...fetchedFixtures].sort((a, b) => {
      const aDate = new Date(a.date)
      const bDate = new Date(b.date)
      return aDate.getTime() - bDate.getTime()
    })
  }, [fetchedFixtures])

  const leagues = useMemo(() => {
    return [...new Set(fetchedFixtures.map((f) => f.league))].sort((a, b) => {
      return a.name.localeCompare(b.name)
    })
  }, [fetchedFixtures])

  const filtered = sorted.filter((fixture) => {
    if (!filterLeague) {
      return true
    }

    if (filterLeague === 'SELECTED_FIXTURES') {
      return selectedIds.includes(fixture.id)
    }

    return fixture.league.id === filterLeague
  })

  const handleClose = () => {
    setFixtures(selectedFixtures)
    onClose()
  }

  return (
    <Modal
      heading={'Select fixture(s)'}
      closeModal={handleClose}
      maxWidth={600}
      css={css`
        text-align: center;
      `}
    >
      <Flex
        column
        css={css`
          position: relative;
          max-height: 60vh;
          overflow-y: auto;
        `}
      >
        <Flex
          column
          gap="small"
          horizontal={'center'}
          css={css`
            flex: 1;
            overflow-y: auto;
            padding-bottom: 70px; /* Space for the button */
            width: 100%;
          `}
        >
          {!fixtureLoading && (
            <Flex
              column
              gap={'medium'}
              css={css`
                margin-bottom: 16px;
                padding: 8px;
              `}
            >
              <Flex wrap gap={'small'}>
                <IconButton
                  rounded
                  backgroundColor={
                    filterLeague === undefined ? colors.green500 : 'white'
                  }
                  textColor={filterLeague === undefined ? 'white' : 'black'}
                  borderColor="black"
                  onClick={() => setFilterLeague(undefined)}
                  text="All"
                />

                {selectedFixtures.length > 0 && (
                  <IconButton
                    rounded
                    backgroundColor={
                      filterLeague === 'SELECTED_FIXTURES'
                        ? colors.green500
                        : 'white'
                    }
                    textColor={
                      filterLeague === 'SELECTED_FIXTURES' ? 'white' : 'black'
                    }
                    borderColor="black"
                    onClick={() => setFilterLeague('SELECTED_FIXTURES')}
                    text={`Selected: ${selectedFixtures.length}`}
                  />
                )}

                {leagues.map((league) => {
                  const selected = filterLeague === league.id
                  return (
                    <IconButton
                      rounded
                      backgroundColor={selected ? colors.green500 : 'white'}
                      borderColor="black"
                      key={league.id}
                      onClick={() => setFilterLeague(league.id)}
                      text={
                        <TextNew
                          color={selected ? 'white' : 'black'}
                          condensed
                          size={'large'}
                        >
                          {league.name}
                        </TextNew>
                      }
                      icon={
                        <img
                          src={league.logoUrl}
                          alt={league.name}
                          width={24}
                          loading="lazy"
                        />
                      }
                    />
                  )
                })}
              </Flex>
            </Flex>
          )}

          {fixtureLoading ? (
            <Loader />
          ) : sorted.length === 0 ? (
            <TextNew>No fixtures found</TextNew>
          ) : (
            filtered.map((fixture) => (
              <Flex
                key={fixture.id}
                onClick={() => handleClickFixture(fixture)}
                css={css`
                  cursor: pointer;
                  width: 100%;
                `}
              >
                <FixtureDisplay
                  fixture={fixture}
                  selected={selectedIds.includes(fixture.id)}
                />
              </Flex>
            ))
          )}
        </Flex>
        <div
          css={css`
            position: sticky;
            bottom: 0;
            background-color: white; /* Match modal background */
            padding: 16px;
            box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
            z-index: 10;
          `}
        >
          <PrimaryButton
            onClick={handleClose}
            css={css`
              width: 100%;
            `}
          >
            Confirm
          </PrimaryButton>
        </div>
      </Flex>
    </Modal>
  )
}
