/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { Link as RouterLink } from 'react-router-dom'
import { Heading } from '../../components/Heading'
import { Logo } from '../../components/icons'
import { Flex } from '../../components/layout/Flex'
import { Layout } from '../../components/layout/Layout'
import { PageContent } from '../../components/layout/PageContent'
import { Text } from '../../components/Text'
import { useT } from '../../lib/i18n/useT'
import { useNativeApp } from '../../lib/native/NativeAppContext'
import { margin } from '../../styles/margin'
import { buttonReset } from '../../styles/styles'
import { colors } from '../../styles/theme'

const Content = () => {
  return (
    <PageContent
      gap={'medium'}
      css={css`
        section {
          margin-bottom: 24px;
        }

        h2 {
          margin-bottom: 16px;
        }

        p {
          margin-bottom: 12px;
        }

        ul {
          list-style: inside;
        }

        li {
          margin-bottom: 4px;
        }
      `}
    >
      <Heading level={1} looksLikeLevel={3}>
        {'Competition Rules for Contests, Competitions and Challenges'}
      </Heading>
      <section>
        <Heading level={2} looksLikeLevel={4}>
          General Overview:
        </Heading>
        <Text>
          The aim of Challenges, Contests and Competitions is to create
          engagement around events in football. Both Physical and digital prizes
          and achievements are used to support this goal. Prizes are never in
          cash, and never used in a way that creates a "risk of loosing" or
          betting situation.
        </Text>
      </section>
      <section>
        <Heading level={2} looksLikeLevel={4}>
          Responsibilities:
        </Heading>
        <Text>
          Contests are organised by fcQuiz and/or our verified partners, and are
          solely the responsibilities of these actors. Other mentioned or
          indirectly connected platforms or actors, like Apple, Google, UEFA,
          The Premier League, Real Madrid or the likes are in no way involved or
          associated with the competitions, unless otherwise directly stated.
        </Text>
      </section>
      <section>
        <Heading level={2} looksLikeLevel={4}>
          Participation:
        </Heading>
        <Text>
          <ul>
            <li>
              Participants can join contests and challenges by playing quizzes.
            </li>
            <li>
              The number of quizzes played is tracked, and quiz scores are
              tracked and displayed on the leaderboards. In some competitions
              participants earn virtual tickets based on the contest's ticket
              tier structure.
            </li>
          </ul>
        </Text>
      </section>
      <section>
        <Heading level={2} looksLikeLevel={4}>
          Anonymisation and Personal Information:
        </Heading>
        <Text>
          <ul>
            <li>
              By entering a contest the player agrees to be shown in the
              leaderboards, and that fcQuiz can contact them for prizes and
              other competition related information.
            </li>
            <li>
              The player is free to remain anonymous by choosing a non-personal
              user name.
            </li>
            <li>
              Any player can request a total removal from the system within 30
              days of notices.
            </li>
          </ul>
        </Text>
      </section>

      <section>
        <Heading level={2} looksLikeLevel={4}>
          Eligibility:
        </Heading>
        <Text>
          <ul>
            <li>
              A minimum threshold of quizzes played may be required to qualify
              for tickets and prizes.
            </li>
            <li>
              The a required age limit for contests set to 12 years of age.
            </li>
          </ul>
        </Text>
      </section>
      <section>
        <Heading level={2} looksLikeLevel={4}>
          Ticket Validity:
        </Heading>
        <Text>
          <ul>
            <li>
              If the contest has tickets, these are exclusive to the contest in
              which they are earned and cannot be carried over to subsequent
              contests.
            </li>
          </ul>
        </Text>
      </section>
      <section>
        <Heading level={2} looksLikeLevel={4}>
          Winner Selection:
        </Heading>
        <Text>
          <ul>
            <li>
              Winners can be randomly drawn from a participant pool or given to
              the highest scorers.
            </li>
            <li>
              If the contest has tickets, all earned tickets are entered into a
              draw.
            </li>
          </ul>
        </Text>
      </section>
      <section>
        <Heading level={2} looksLikeLevel={4}>
          Prize Distribution:
        </Heading>
        <Text>
          <ul>
            <li>
              The winner will be contacted to provide the necessary information
              to claim the contest prize, and announced to the other
              participants both in and outside the game.
            </li>
          </ul>
        </Text>
      </section>
      <section>
        <Text>
          By participating in the contest, you agree to these rules and the
          terms of the competition.
        </Text>
      </section>
    </PageContent>
  )
}

export const ContestsPage = () => {
  return (
    <Layout hideNavbar>
      <TermsHeader />

      <PageContent>
        <Content />
      </PageContent>
    </Layout>
  )
}

export const TermsHeader = () => {
  const t = useT()
  const { isNativeApp } = useNativeApp()

  return (
    <header
      css={css`
        padding-top: 50px;
        padding-bottom: 50px;
      `}
    >
      <PageContent>
        {isNativeApp && (
          <Flex>
            <button
              onClick={() => window.history.back()}
              css={[buttonReset, margin.bottom()]}
            >
              <Text>ᐊ {t('Back')}</Text>
            </button>
          </Flex>
        )}
        <Flex vertical="center" horizontal="space-between">
          <RouterLink
            to="/"
            css={css`
              text-decoration: none;
              cursor: pointer;
            `}
          >
            <Flex vertical="center" gap="medium">
              <Logo color={colors.green500} />
              <Heading level={1} looksLikeLevel={3} color={colors.green500}>
                fcQuiz
              </Heading>
            </Flex>
          </RouterLink>
        </Flex>
      </PageContent>
    </header>
  )
}
