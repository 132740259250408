/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import {
  ComponentPropsWithoutRef,
  FC,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Text } from '../../components/Text'
import { TextArea } from '../../components/TextInput'
import { CorrectAnswer, IncorrectAnswer } from '../../components/icons'
import { Flex } from '../../components/layout/Flex'
import { useT } from '../../lib/i18n/useT'
import { colors } from '../../styles/theme'

type Props = {
  field?: any
  setField?: any
  type: 'Question' | 'Alternative'
  isCorrect?: boolean
  index?: number
  hideCorrect?: boolean
  label?: string
  discardField?: string
  autoFocus?: Boolean
  languageOverride?: string
} & ComponentPropsWithoutRef<'input'>

export const FieldInput: FC<Props> = ({
  field,
  setField,
  type,
  isCorrect,
  index,
  hideCorrect,
  label,
  discardField,
  autoFocus,
  languageOverride,
  ...rest
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null)

  const boxShadowStrength = type === 'Question' ? 0.1 : 0
  const indexDisplay = index !== undefined ? index + 1 : ''
  const t = useT(languageOverride)
  const optional =
    indexDisplay && indexDisplay > 2 && !label ? `(${t(`optional`)})` : ''
  const inCorrectColor = optional && !field ? colors.grey300 : 'white'
  const isCorrectShow = isCorrect && !hideCorrect

  const [lastField, setLastField] = useState<string | null>(null)

  useEffect(() => {
    if (autoFocus) {
      textAreaRef?.current?.focus()
    }
  }, [field])

  // Do not change this to <InputTextArea>, then it will refresh
  // during editing, resulting in blurring at all updates.
  const inputTextArea = () => (
    <TextArea
      ref={(me) => {
        if (!me) return

        // This shouldnt be necessary, but likely the height fix
        // Does not work for every browser or situation, therefore
        // Use this to prevent crashes
        if (field === lastField) return
        setLastField(field)

        textAreaRef.current = me
      }}
      value={type === 'Question' ? field : field.text}
      onValue={(text: string) => {
        if (type === 'Question') {
          setField(text)
        } else {
          if (text.split('\n').length > 2) {
            // Block \n
            setField({ text: text.slice(0, text.lastIndexOf('\n')) })
          } else {
            setField({ text })
          }
        }
      }}
      css={css`
        width: 100%;
      `}
      textCss={css`
        background-color: transparent;
        box-shadow: 0 4px 6px rgba(0, 0, 0, ${boxShadowStrength});
        border: ${type === 'Alternative'
          ? 'none'
          : `1px solid ${colors.grey400}`};
        font-size: ${18}px;
        border-radius: 10px;
        text-align: ${type === 'Question' ? 'center' : 'left'};
      `}
      placeholder={`${t(type)} ${indexDisplay} ${optional}`}
    />
  )

  const Label = () => (
    <Flex horizontal={type === 'Question' ? 'center' : 'flex-start'}>
      <Text
        css={css`
          padding: 0px 10px;
          font-size: 12px;
          text-align: center;
          margin-top: ${type === 'Question' ? '10px' : '0px'};
        `}
        color={colors.grey400}
      >
        {label}
      </Text>
    </Flex>
  )

  if (type === 'Question') {
    return (
      <Flex {...rest} column>
        {inputTextArea()}
        <Label />
      </Flex>
    )
  }

  return (
    <Flex {...rest} column>
      <Flex
        css={css`
          width: 100%;
          background-color: ${isCorrectShow ? colors.green300 : inCorrectColor};
          border-radius: 10px;
          position: relative;
        `}
        vertical="center"
      >
        {inputTextArea()}
        {!hideCorrect && type === 'Alternative' && (
          <Flex
            css={css`
              padding: 10px;
            `}
          >
            {isCorrectShow ? (
              <CorrectAnswer
                css={css`
                  width: 25px;
                  height: 25px;
                `}
              />
            ) : (
              <IncorrectAnswer
                css={css`
                  width: 25px;
                  height: 25px;
                `}
              />
            )}
          </Flex>
        )}

        {discardField && (
          <Flex
            css={[
              css`
                position: absolute;
                right: -10px;
                top: 50%;
                transform: translate(100%, -50%);
                opacity: 0.75;
              `,
              field == discardField
                ? css`
                    opacity: 0;
                  `
                : css`
                    &:hover {
                      cursor: pointer;
                    }
                  `,
            ]}
            onClick={() => {
              setField({ text: discardField })
            }}
          >
            <img src="/cancel.svg"></img>
          </Flex>
        )}
      </Flex>
      <Label />
    </Flex>
  )
}
