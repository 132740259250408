/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import {
  ComponentPropsWithoutRef,
  FC,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Flex } from '../../../components/layout/Flex'
import { useT } from '../../../lib/i18n/useT'
import { colors } from '../../../styles/theme'
import { TextArea } from '../../../components/TextInput'
import { CorrectAnswer, IncorrectAnswer } from '../../../components/icons'
import { Text } from '../../../components/Text'
import {
  CareerPathTeam,
  useGetAuthenticTeamByIdQuery,
  useGetAuthenticTeamsByNameQuery,
} from '../queries'
import { useDebouncedString } from '../../../utils/useDebouncedValue'
import { Chip } from '../../../components/Chip'

type Props = {
  field?: any
  reference?: any
  setField?: any
  type: 'Question' | 'Alternative'
  isCorrect?: boolean
  index?: number
  hideCorrect?: boolean
  label?: string
  discardField?: string
  autoFocus?: Boolean
  languageOverride?: string
} & ComponentPropsWithoutRef<'input'>

export const ReferenceFieldInput: FC<Props> = ({
  field,
  reference,
  setField,
  type,
  isCorrect,
  index,
  hideCorrect,
  label,
  discardField,
  autoFocus,
  languageOverride,
  ...rest
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null)
  const dropdownRef = useRef<HTMLDivElement | null>(null)

  const boxShadowStrength = type === 'Question' ? 0.1 : 0
  const indexDisplay = index !== undefined ? index + 1 : ''
  const t = useT(languageOverride)
  const optional =
    indexDisplay && indexDisplay > 2 && !label ? `(${t(`optional`)})` : ''
  const inCorrectColor = optional && !field ? colors.grey300 : 'white'
  const isCorrectShow = isCorrect && !hideCorrect

  const [lastField, setLastField] = useState<string | null>(null)
  const [team, setTeam] = useState<CareerPathTeam | null>(null)
  const [searchQuery, setSearchQuery] = useState('')
  const debouncedSearchQuery = useDebouncedString(searchQuery, 300, {
    minLength: 3,
  })
  const queryResult = useGetAuthenticTeamsByNameQuery(
    debouncedSearchQuery && debouncedSearchQuery !== team?.name
      ? debouncedSearchQuery
      : ''
  )

  const { data: referenceObject, loading: referenceObjectLoading } =
    useGetAuthenticTeamByIdQuery(reference)

  // Effect to close the dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        textAreaRef.current &&
        !textAreaRef.current.contains(event.target as Node) &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setSearchQuery('') // Clear the search query to close the dropdown
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [textAreaRef, dropdownRef])

  // Handle selecting a team from search results
  const handleTeamSelection = (selectedTeam: CareerPathTeam) => {
    setTeam(selectedTeam)
    setField({
      text: selectedTeam.name,
      reference: selectedTeam.id,
      logoUrl: selectedTeam.logoUrl,
    })
    setSearchQuery('') // Clear search query to close the dropdown
  }

  const inputTextArea = () => (
    <Flex
      css={css`
        width: 100%;
      `}
    >
      {type === 'Alternative' && referenceObjectLoading && (
        <Flex
          css={css`
            position: absolute;
            right: 0;
            top: -10px;
          `}
        >
          <Chip
            label={'Loading...'}
            backgroundColor="black"
            color="white"
            css={css`
              z-index: 10;
            `}
          />
        </Flex>
      )}
      {type === 'Alternative' && (team || referenceObject) && (
        <>
          <Flex
            horizontal={'center'}
            vertical={'flex-start'}
            css={css`
              border-radius: 50%;
              padding: 6px;
              width: 36px;
              overflow: hidden;
            `}
          >
            <img
              src={
                referenceObject?.getAuthenticTeamById.logoUrl ?? team?.logoUrl
              }
              height={24}
            />
          </Flex>
          <Flex
            css={css`
              position: absolute;
              right: 0;
              top: -10px;
            `}
            onClick={() => {
              setTeam(null)
              setField({ text: '' })
            }}
          >
            <Chip
              label={'Remove'}
              backgroundColor="black"
              color="white"
              css={css`
                z-index: 10;
              `}
            >
              {/*<GiCancel
                width={10}
                color={colors.white}
                css={css`
                  margin-right: 5px;
                `}
              />*/}
            </Chip>
          </Flex>
        </>
      )}
      <TextArea
        ref={(me) => {
          if (!me) return

          if (field === lastField) return
          setLastField(field)

          textAreaRef.current = me
        }}
        value={field}
        onValue={(text: string) => {
          if (type === 'Question') {
            setField(text)
          } else {
            setSearchQuery(text)
            setField({ text })
          }
        }}
        css={css`
          width: 100%;
        `}
        textCss={css`
          background-color: transparent;
          box-shadow: 0 4px 6px rgba(0, 0, 0, ${boxShadowStrength});
          border: ${type === 'Alternative'
            ? 'none'
            : `1px solid ${colors.grey400}`};
          font-size: ${18}px;
          border-radius: 10px;
          text-align: ${type === 'Question' ? 'center' : 'left'};
        `}
        placeholder={`${t(type)} ${indexDisplay} ${optional}`}
      />
      {type === 'Alternative' &&
        queryResult.data &&
        queryResult?.data?.getTeamsByName.length > 0 &&
        searchQuery && (
          <div
            ref={dropdownRef} // Attach ref to the dropdown
            css={css`
              position: absolute;
              background-color: white;
              border: 1px solid ${colors.grey300};
              border-radius: 8px;
              z-index: 10;
              width: 100%;
              max-height: 200px;
              overflow-y: auto;
              margin-top: 70px;
            `}
          >
            <Flex
              horizontal={'space-between'}
              vertical={'center'}
              css={css`
                padding: 10px;
                background-color: ${colors.grey200};
                border-bottom: 2px solid ${colors.grey500};
              `}
            >
              <Flex vertical={'center'} gap={'small'}>
                {/*         <TbShieldPlus />*/}
                <Text>Select authentic reference</Text>
              </Flex>

              <Flex onClick={() => setSearchQuery('')}>
                <img src="/cancel.svg" alt="Cancel"></img>
              </Flex>
            </Flex>
            {queryResult.data.getTeamsByName.map((team: CareerPathTeam) => (
              <Flex
                key={team.id}
                vertical={'center'}
                gap={'small'}
                css={css`
                  padding: 10px;
                  cursor: pointer;
                  &:hover {
                    background-color: ${colors.grey200};
                  }
                  border-bottom: 1px solid ${colors.grey300};
                `}
                onClick={() => handleTeamSelection(team)}
              >
                <img src={team.logoUrl} width={24} />
                <Text size="medium">{team.name}</Text>
              </Flex>
            ))}
          </div>
        )}
    </Flex>
  )

  const Label = () => (
    <Flex horizontal={type === 'Question' ? 'center' : 'flex-start'}>
      <Text
        css={css`
          padding: 0px 10px;
          font-size: 12px;
          text-align: center;
          margin-top: ${type === 'Question' ? '10px' : '0px'};
        `}
        color={colors.grey400}
      >
        {label}
      </Text>
    </Flex>
  )

  if (type === 'Question') {
    return (
      <Flex {...rest} column>
        {inputTextArea()}
        <Label />
      </Flex>
    )
  }

  return (
    <Flex {...rest} column>
      <Flex
        css={css`
          width: 100%;
          background-color: ${isCorrectShow ? colors.green300 : inCorrectColor};
          border-radius: 10px;
          position: relative;
        `}
        vertical="center"
      >
        {inputTextArea()}
        {!hideCorrect && type === 'Alternative' && (
          <Flex
            css={css`
              padding: 10px;
            `}
          >
            {isCorrectShow ? (
              <CorrectAnswer
                css={css`
                  width: 25px;
                  height: 25px;
                `}
              />
            ) : (
              <IncorrectAnswer
                css={css`
                  width: 25px;
                  height: 25px;
                `}
              />
            )}
          </Flex>
        )}

        {discardField && (
          <Flex
            css={[
              css`
                position: absolute;
                right: -10px;
                top: 50%;
                transform: translate(100%, -50%);
                opacity: 0.75;
              `,
              field == discardField
                ? css`
                    opacity: 0;
                  `
                : css`
                    &:hover {
                      cursor: pointer;
                    }
                  `,
            ]}
            onClick={() => {
              setField(discardField)
            }}
          >
            <img src="/cancel.svg" alt="Cancel" />
          </Flex>
        )}
      </Flex>
      <Label />
    </Flex>
  )
}
