/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { Flex } from '../../../components/layout/Flex'
import { TextNew } from '../../../components/TextNew'
import { Locale } from '../../../lib/i18n/locale'
import { colors } from '../../../styles/theme'
import { Fixture } from '../queries'

export function FixtureDisplay({
  fixture,
  compact,
  selected,
}: {
  fixture: Fixture
  compact?: boolean
  selected?: boolean
}) {
  const homeTeam = fixture.homeTeam
  const awayTeam = fixture.awayTeam
  const date = new Date(fixture.date)
  const imageSize = 32

  return (
    <Flex
      css={css`
        width: 100%;
        border-radius: 10px;
        padding: ${compact ? '5px' : '10px'};
        background-color: ${selected ? colors.green300 : colors.grey100};
        border: 2px solid ${selected ? colors.green500 : 'transparent'};
        overflow: hidden;
      `}
      horizontal="space-between"
      vertical="center"
      gap="small"
    >
      <Flex
        vertical={'center'}
        gap={'small'}
        css={css`
          flex: 4;
        `}
      >
        <Flex
          column
          gap={'small'}
          horizontal={'center'}
          css={css`
            overflow: hidden;
            flex: 1;
          `}
        >
          <img
            src={homeTeam.logoUrl}
            alt={homeTeam.name}
            width={imageSize}
            loading="lazy"
          />

          {!compact && (
            <TextNew size={'large'} condensed>
              {fixture.homeTeam.name}
            </TextNew>
          )}
        </Flex>

        <TextNew condensed size={'large'}>
          vs
        </TextNew>
        <Flex
          column
          gap={'small'}
          horizontal={'center'}
          css={css`
            overflow: hidden;
            flex: 1;
          `}
        >
          <img
            src={awayTeam.logoUrl}
            alt={awayTeam.name}
            width={imageSize}
            loading="lazy"
          />
          {!compact && (
            <TextNew
              size={'large'}
              condensed
              css={css`
                text-overflow: ellipsis;
              `}
            >
              {fixture.awayTeam.name}
            </TextNew>
          )}
        </Flex>
      </Flex>

      {!compact && (
        <Flex
          column
          gap={'small'}
          css={css`
            flex: 1;
          `}
        >
          <TextNew>
            {date.toLocaleDateString(Locale.no, {
              day: '2-digit',
              month: 'short',
              year: '2-digit',
            })}{' '}
          </TextNew>
          <TextNew>
            {date.toLocaleTimeString(Locale.no, {
              hour: '2-digit',
              minute: '2-digit',
            })}
          </TextNew>
        </Flex>
      )}
    </Flex>
  )
}
