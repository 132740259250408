/** @jsxImportSource @emotion/react */

import { Fixture } from '../../Generate/queries'
import { css } from '@emotion/react'
import { Flex } from '../../../components/layout/Flex'
import { CHALLENGE_MODE_MAX_W } from '../../../constants'
import { TextNew } from '../../../components/TextNew'
import { colors } from '../../../styles/theme'

export const FixtureHeader = ({
  fixture,
  hasSanityCampaign,
}: {
  fixture: Fixture
  hasSanityCampaign?: boolean
}) => {
  const homeTeam = fixture.homeTeam
  const awayTeam = fixture.awayTeam
  const date = new Date(fixture.date)

  return (
    <Flex
      column
      horizontal="center"
      vertical="center"
      css={css`
        padding-top: 24px;
        width: 100%;
        padding-left: 16px;
        padding-right: 16px;
      `}
    >
      <Flex
        css={css`
          margin-top: ${hasSanityCampaign ? '-72px' : '0'};
          z-index: 1;
          max-width: ${CHALLENGE_MODE_MAX_W}px;
          width: 100%;
        `}
      >
        <Flex
          horizontal="space-between"
          vertical="center"
          css={css`
            width: 100%;
            border-radius: 15px;
            padding: 10px;
            background-color: ${colors.yellow100};
            overflow: hidden;
          `}
        >
          <Flex
            horizontal="space-between"
            vertical="center"
            css={css`
              width: 100%;
            `}
          >
            <Flex
              column
              horizontal="center"
              gap={'small'}
              css={css`
                width: 40%;
                max-width: 40%;

                text-align: center;
              `}
            >
              <TextNew
                condensed
                uppercase
                strong
                size={16}
                css={css`
                  padding: 0;
                `}
              >
                {fixture.homeTeam.name}
              </TextNew>
              <img src={homeTeam.logoUrl} alt={homeTeam.name} width={40} />
            </Flex>

            <Flex
              column
              horizontal="center"
              vertical="center"
              css={css`
                width: 20%;
                margin-top: 8px;
              `}
            >
              <img
                src={fixture.league.logoUrl}
                alt={fixture.league.logoUrl}
                css={css`
                  width: 42px;
                  max-width: 500px;
                  pointer-events: none;
                `}
              />
              <TextNew
                condensed
                uppercase
                strong
                size={18}
                css={css`
                  padding: 0;
                `}
              >
                {date.toLocaleDateString(undefined, { day: '2-digit' })}{' '}
                {date.toLocaleDateString(undefined, { month: 'long' })}
              </TextNew>

              <TextNew
                condensed
                uppercase
                strong
                size={18}
                css={css`
                  padding: 0;
                `}
              >
                {date.toLocaleTimeString(undefined, {
                  hour: '2-digit',
                  minute: '2-digit',
                })}
              </TextNew>
            </Flex>

            <Flex
              column
              horizontal="center"
              gap={'small'}
              css={css`
                width: 40%;
                max-width: 40%;
                text-align: center;
              `}
            >
              <TextNew
                condensed
                uppercase
                strong
                size={16}
                css={css`
                  padding: 0;
                `}
              >
                {fixture.awayTeam.name}
              </TextNew>
              <img src={awayTeam.logoUrl} alt={awayTeam.name} width={40} />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
