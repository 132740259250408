/** @jsxImportSource @emotion/react */

import { useNavigate } from 'react-router-dom'
import { SecondaryButton } from '../../components/Button'
import { Heading } from '../../components/Heading'
import { Flex } from '../../components/layout/Flex'
import { useT } from '../../lib/i18n/useT'
import { useFinishContext } from './GeneratePage'

export const Finish = (props: { leagueSlug: string }) => {
  const navigate = useNavigate()
  const { language } = useFinishContext()
  const t = useT(language ?? 'en')
  return (
    <Flex horizontal="center" column gap="small">
      <Heading level={5}>{t(`Good job!`)}</Heading>
      <Flex gap="medium">
        <SecondaryButton
          noWrap
          onClick={() => {
            navigate(`/series/${props.leagueSlug}`)
          }}
        >
          {t('To the series page')}
        </SecondaryButton>
      </Flex>
    </Flex>
  )
}
