/** @jsxImportSource @emotion/react */

import { gql, useQuery } from '@apollo/client'
import { css } from '@emotion/react'
import { useState } from 'react'
import { CategoryWithQuestionCount } from '../../components/GenerateQuizButton'
import { Header } from '../../components/Header'
import { HeadingNew } from '../../components/HeadingNew'
import { Flex } from '../../components/layout/Flex'
import { Layout } from '../../components/layout/Layout'
import { PageContent } from '../../components/layout/PageContent'
import { TextNew } from '../../components/TextNew'
import { Locale } from '../../lib/i18n/locale'
import { Spacer } from '../../styles/margin'
import { colors } from '../../styles/theme'
import { LanguagePicker } from '../Clubs/ClubProfilePage/EmbedCodeModal'
import { AdminAccessControlComponent } from './AdminAccessControlComponent'
import { AdminNavbar } from './Navbar'

//categoriesWithQuestionCount(locale: String): [CategoryWithQuestionCount!]

const categoriesWithQuestionCountQuery = gql`
  query categoriesWithQuestionCount($locale: String!) {
    categoriesWithQuestionCount(locale: $locale) {
      id
      title
      questionsCount
    }
  }
`

const useCategoriesWithQuestionCountQuery = (locale: Locale) => {
  return useQuery<{ categoriesWithQuestionCount: CategoryWithQuestionCount[] }>(
    categoriesWithQuestionCountQuery,
    {
      variables: { locale },
    }
  )
}

const Content = () => {
  const [currentLocale, setCurrentLocale] = useState(Locale.en)

  const categoriesQuery = useCategoriesWithQuestionCountQuery(currentLocale)

  const data = categoriesQuery.data

  const categories = data?.categoriesWithQuestionCount ?? []
  return (
    <Flex
      column
      grow
      gap={'medium'}
      css={css`
        margin-top: 32px;
      `}
    >
      <HeadingNew level={2} looksLikeLevel={4}>
        Categories with timeless questions
      </HeadingNew>
      <LanguagePicker locale={currentLocale} onLocale={setCurrentLocale} />

      <Flex wrap gap={'small'}>
        {categories.map((category) => (
          <Flex
            key={category.id}
            css={css`
              background-color: ${colors.yellow400};
              padding: 8px 12px;
              border-radius: 4px;
            `}
          >
            <TextNew>{`${category.title} (${category.questionsCount})`}</TextNew>
          </Flex>
        ))}
      </Flex>
    </Flex>
  )
}

export const AdminTimelessPage = () => {
  return (
    <Layout>
      <AdminAccessControlComponent>
        <Header color="green" />
        <PageContent grow>
          <AdminNavbar activePage="timeless" />
          <Content />
          <Spacer height={'huge'} />
        </PageContent>
      </AdminAccessControlComponent>
    </Layout>
  )
}
