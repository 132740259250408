import { Avatar } from '../../views/Avatar/Avatar'
import { css, keyframes } from '@emotion/react'
import { Flex } from '../layout/Flex'
import { colors } from '../../styles/theme'
import { useAvatarContext } from '../../views/Avatar/AvatarPage'
import { useEffect, useState } from 'react'
import { makeAvatarParts } from '../../views/Avatar/util'
import { shuffle } from 'lodash'
import SantaHat from '../../views/Home/assets/SantaHat.png'

// Define keyframes for the animation

const flipIn = keyframes`
    0% { opacity: 0; transform: rotateY(90deg); }
    100% { opacity: 1; transform: rotateY(0deg); }
`

const flipOut = keyframes`
    0% { opacity: 1; transform: rotateY(0deg); }
    100% { opacity: 0; transform: rotateY(-90deg); }
`

export const ShiftingAvatar = () => {
  const { parts, setParts } = useAvatarContext()
  const [isChanging, setIsChanging] = useState(false)
  const [avatars] = useState(shuffle(predefinedPlayers))
  const [currentAvatarIndex, setCurrentAvatarIndex] = useState(0)
  const [isOut, setIsOut] = useState(false)

  useEffect(() => {
    setParts(makeAvatarParts(avatars[currentAvatarIndex]))
  }, [setParts, avatars, currentAvatarIndex])

  useEffect(() => {
    const updateAvatar = () => {
      setIsOut(true)
      setTimeout(() => {
        setIsChanging(true)
        const data = avatars[currentAvatarIndex]
        setParts(makeAvatarParts(data))
        if (currentAvatarIndex === predefinedPlayers.length - 1) {
          setCurrentAvatarIndex(0)
        } else {
          setCurrentAvatarIndex(currentAvatarIndex + 1)
        }

        setTimeout(() => {
          setIsChanging(false)
          setIsOut(false)
        }, 800) // Adjust this timeout based on the animation duration
      }, 400)
    }

    // Update the avatar every 5 seconds
    const intervalId = setInterval(updateAvatar, 5000)

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId)
  }, [setParts, currentAvatarIndex, avatars])

  if (!parts) {
    return null
  }

  const isDecember = new Date().getMonth() === 11

  return (
    <Flex
      className="Avatar"
      column
      horizontal="center"
      css={css`
        position: relative;
        width: 100%;
        z-index: 1;
        ${isChanging &&
        css`
          animation: ${!isOut ? flipOut : flipIn} 0.3s ease-in-out;
        `}
      `}
    >
      {isDecember && (
        <Flex
          css={css`
            position: absolute;
            top: -6px;
            rotate: -10deg;
          `}
        >
          <img src={SantaHat} height={72} width={72} />
        </Flex>
      )}

      <Avatar
        userId={'uid'}
        css={css`
          @media (min-width: 599px) {
            transform: scale(1.3);
            margin-top: 40px;
            margin-bottom: 40px;
          }
          // Apply the animation only when the avatar is changing
        `}
        height={150}
        FGColor={'#191919'}
        BGColor={colors.green600}
        partsOverride={{
          ...parts,
        }}
      />
    </Flex>
  )
}

// Row of avatars
export const AvatarDisplay = (player: any) => {
  const { parts, setParts } = useAvatarContext()

  useEffect(() => {
    setParts(makeAvatarParts(player))
  }, [setParts, player])

  if (!parts) {
    return null
  }

  return (
    <Avatar
      userId={'uid'}
      height={45}
      partsOverride={{
        ...parts,
      }}
    />
  )
}

export const predefinedPlayers = [
  // SALAH
  {
    Hair: { style: 'HairO', colors: ['#202020', '#202020'] },
    Brows: { style: 'BrowsI', colors: ['#161616', '#409fac'] },
    Eyes: { style: 'EyesC', colors: ['#161616', '#161616'] },
    Mouth: { style: 'MouthO', colors: ['#202020', '#6DD5E3'] },
    Skin: { style: 'SkinA', colors: ['#D8A66D', '#63331F'] },
    Chest: { style: 'ChestA', colors: ['#930A02', '#202020'] },
    Arms: { style: 'Arms0', colors: ['#202020', '#003ACF'] },
    Shoulders: { style: 'Shoulders0', colors: ['#ffffff', '#930A02'] },
    Collar: { style: 'CollarA', colors: ['#ffffff', '#FECB17'] },
    Sleeves: { style: 'SleevesA', colors: ['#ffffff', '#3A6DF2'] },
    IsBase: false,
  },
  // MESSI
  {
    Hair: { style: 'HairB', colors: ['#202020', '#202020'] },
    Brows: { style: 'BrowsB', colors: ['#161616', '#409fac'] },
    Eyes: { style: 'EyesA', colors: ['#552400', '#161616'] },
    Mouth: { style: 'MouthO', colors: ['#CF662A', '#6DD5E3'] },
    Skin: { style: 'SkinA', colors: ['#F5BFA1', '#63331F'] },
    Chest: { style: 'ChestC', colors: ['#930A02', '#003ACF'] },
    Arms: { style: 'Arms0', colors: ['#003ACF', '#003ACF'] },
    Shoulders: { style: 'Shoulders0', colors: ['#ffffff', '#930A02'] },
    Collar: { style: 'CollarA', colors: ['#FECB17', '#FECB17'] },
    Sleeves: { style: 'SleevesA', colors: ['#FECB17', '#3A6DF2'] },
    IsBase: false,
  },
  // MBAPPE
  {
    Hair: { style: 'HairT', colors: ['#202020', '#202020'] },
    Brows: { style: 'BrowsF', colors: ['#161616', '#409fac'] },
    Eyes: { style: 'EyesA', colors: ['#161616', '#161616'] },
    Mouth: { style: 'MouthD', colors: ['#CF662A', '#6DD5E3'] },
    Skin: { style: 'SkinA', colors: ['#B97552', '#63331F'] },
    Chest: { style: 'ChestA', colors: ['#ffffff', '#003ACF'] },
    Arms: { style: 'Arms0', colors: ['#003ACF', '#003ACF'] },
    Shoulders: { style: 'ShouldersC', colors: ['#202020', '#ffffff'] },
    Collar: { style: 'CollarA', colors: ['#ffffff', '#ffffff'] },
    Sleeves: { style: 'Sleeves0', colors: ['#202020', '#3A6DF2'] },
    IsBase: false,
  },
  // RONALDO
  {
    Hair: { style: 'HairS', colors: ['#202020', '#202020'] },
    Brows: { style: 'BrowsJ', colors: ['#161616', '#409fac'] },
    Eyes: { style: 'EyesA', colors: ['#161616', '#161616'] },
    Mouth: { style: 'MouthI', colors: ['#CF662A', '#6DD5E3'] },
    Skin: { style: 'SkinA', colors: ['#CD8F55', '#63331F'] },
    Chest: { style: 'ChestA', colors: ['#930A02', '#003ACF'] },
    Arms: { style: 'Arms0', colors: ['#003ACF', '#003ACF'] },
    Shoulders: { style: 'Shoulders0', colors: ['#202020', '#ffffff'] },
    Collar: { style: 'CollarA', colors: ['#034B13', '#ffffff'] },
    Sleeves: { style: 'SleevesA', colors: ['#034B13', '#3A6DF2'] },
    IsBase: false,
  },
  // SIMONS
  {
    Hair: { style: 'HairR', colors: ['#202020', '#202020'] },
    Brows: { style: 'BrowsH', colors: ['#161616', '#409fac'] },
    Eyes: { style: 'EyesA', colors: ['#161616', '#161616'] },
    Mouth: { style: 'MouthA', colors: ['#CF662A', '#6DD5E3'] },
    Skin: { style: 'SkinA', colors: ['#CD8F55', '#63331F'] },
    Chest: { style: 'ChestF', colors: ['#202020', '#EE7836'] },
    Arms: { style: 'ArmsA', colors: ['#EE7836', '#3A6DF2'] },
    Shoulders: { style: 'ShouldersA', colors: ['#EE7836', '#930A02'] },
    Collar: { style: 'CollarA', colors: ['#202020', '#FECB17'] },
    Sleeves: { style: 'SleevesA', colors: ['#202020', '#3A6DF2'] },
    IsBase: false,
  },
  // HAALAND
  {
    Hair: { style: 'HairX', colors: ['#f1cc8f', '#202020'] },
    Brows: { style: 'BrowsF', colors: ['#a06c2f', '#409fac'] },
    Eyes: { style: 'EyesA', colors: ['#409fac', '#161616'] },
    Mouth: { style: 'MouthJ', colors: ['#CF662A', '#6DD5E3'] },
    Skin: { style: 'SkinA', colors: ['#F5BFA1', '#63331F'] },
    Chest: { style: 'ChestA', colors: ['#FECB17', '#202020'] },
    Arms: { style: 'ArmsC', colors: ['#FECB17', '#202020'] },
    Shoulders: { style: 'ShouldersA', colors: ['#202020', '#ffffff'] },
    Collar: { style: 'CollarA', colors: ['#202020', '#ffffff'] },
    Sleeves: { style: 'SleevesA', colors: ['#202020', '#3A6DF2'] },
    IsBase: false,
  },
  // KANTE
  {
    Hair: { style: 'Hair0', colors: ['#202020', '#202020'] },
    Brows: { style: 'BrowsE', colors: ['#161616', '#409fac'] },
    Eyes: { style: 'EyesE', colors: ['#161616', '#161616'] },
    Mouth: { style: 'MouthI', colors: ['#CF662A', '#6DD5E3'] },
    Skin: { style: 'SkinA', colors: ['#63331F', '#63331F'] },
    Chest: { style: 'ChestA', colors: ['#003ACF', '#202020'] },
    Arms: { style: 'Arms0', colors: ['#FECB17', '#202020'] },
    Shoulders: { style: 'Shoulders0', colors: ['#202020', '#ffffff'] },
    Collar: { style: 'CollarA', colors: ['#202020', '#ffffff'] },
    Sleeves: { style: 'SleevesA', colors: ['#202020', '#3A6DF2'] },
    IsBase: false,
  },
  // Griezmann
  {
    Hair: { style: 'HairK', colors: ['#D29956', '#202020'] },
    Brows: { style: 'BrowsA', colors: ['#161616', '#409fac'] },
    Eyes: { style: 'EyesA', colors: ['#409fac', '#161616'] },
    Mouth: { style: 'MouthA', colors: ['#CF662A', '#6DD5E3'] },
    Skin: { style: 'SkinA', colors: ['#F5BFA1', '#63331F'] },
    Chest: { style: 'ChestD', colors: ['#ffffff', '#C63C34'] },
    Arms: { style: 'ArmsA', colors: ['#C63C34', '#C63C34'] },
    Shoulders: { style: 'ShouldersA', colors: ['#C63C34', '#ffffff'] },
    Collar: { style: 'Collar0', colors: ['#003ACF', '#ffffff'] },
    Sleeves: { style: 'Sleeves0', colors: ['#003ACF', '#C63C34'] },
    IsBase: false,
  },
]
