/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { orderBy } from 'lodash'
import { FC, Fragment, useEffect, useMemo, useState } from 'react'
import { Snowfall } from 'react-snowfall'
import { PrimaryButton } from '../../components/Button'
import { ClubGridElement } from '../../components/ClubGridElement'
import { Link } from '../../components/Link'
import { Text } from '../../components/Text'
import { TextNew } from '../../components/TextNew'
import { ToggleSwitch } from '../../components/ToggleSwitch'
import { CheckClubMembershipBadges } from '../../components/badges/CheckClubMembershipBadges'
import { Flex } from '../../components/layout/Flex'
import { Layout } from '../../components/layout/Layout'
import { PageContent } from '../../components/layout/PageContent'
import { isProduction } from '../../config/config'
import { useAuthContext, useViewerQuery } from '../../lib/AuthContext'
import { AnalyticsEventType, useTrackEvent } from '../../lib/analytics'
import { useT } from '../../lib/i18n/useT'
import { useNativeApp } from '../../lib/native/NativeAppContext'
import { margin, Spacer } from '../../styles/margin'
import { colors } from '../../styles/theme'
import { PageRefreshComponent } from '../Challenge/DisplayPage/PageRefreshComponent'
import { useScreenWidth } from '../Challenge/DisplayPage/util'
import UserChallengeList from '../Challenge/UserChallengesList'
import { TopPlayersTodayPreviewComponent } from '../Leaderboards/TopPlayersTodayPreviewComponent'
import { AvatarPromoBanner } from './AvatarPromoBanner'
import { DailyCareerPathQuiz } from './DailyCareerPathQuiz'
import { DailyQuiz } from './DailyQuiz'
import { DateRangeChallengeItem } from './DateRangeChallenge'
import { EnablePushNotificationsBanner } from './EnablePushNotificationsBanner'
import { FriendActivity } from './FriendActivity'
import { LeagueItem } from './LeagueItem'
import { PopularQuizzesComponent } from './PopularQuizzesComponent'
import { SpecialQuiz } from './SpecialQuiz'
import { WelcomeBanner } from './WelcomeBanner'
import { PromotedPredictionQuiz } from './prediction/PromotedPredictionQuiz'
import { HomePageLeague, useHomePageQuery } from './queries'
import PromotedItem from './PromotedItem'
import ConfettiExplosion from 'react-confetti-explosion'

export const sortLeagues = (leagues: HomePageLeague[]) => {
  return orderBy(
    leagues,
    [
      (league) => {
        const canStartAQuiz = league.quizzes.some(
          (quiz) =>
            quiz.viewerCanStartQuiz || quiz.viewerData.activeQuizInstance
        )

        return canStartAQuiz ? 0 : 1
      },
      (league) => {
        return league.availableFrom
      },
    ],
    ['asc', 'desc']
  )
}

const JoinLiveQuizButton = () => {
  const t = useT()

  return (
    <Link
      to="/join"
      css={css`
        text-decoration: none;
      `}
    >
      <PrimaryButton
        css={[
          css`
            width: 100%;
            padding: 16px;
            border-radius: 10px;
            margin: 0 auto;
            background-color: ${colors.purple500};
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 0px 4px 0px ${colors.black};
          `,
        ]}
      >
        <TextNew size={24}>🎉</TextNew>
        <Spacer width={10} />
        <TextNew
          size={32}
          color="white"
          italic
          condensed
          css={css`
            font-weight: 600;
          `}
        >
          {t(`Join live quiz`)}
        </TextNew>
        <Spacer width={14} />
        <TextNew size={24}>🎉</TextNew>
      </PrimaryButton>
    </Link>
  )
}

type HomePageItemType =
  | 'dailyQuiz'
  | 'dateRangeChallenges'
  | 'dailyCareerPath'
  | 'specialQuiz'
  | 'leaderboard'
  | 'promotedPredictionQuiz'
  | 'friendActivity'
  | 'promotedLeagueCollection'
  | 'popularQuizzes'
  | 'promotedLeagues'
  | 'userChallengeList'
  | 'leagueCollections'
  | 'promotedClubs'
  | 'promotedItem'

const homepageItemOrder: HomePageItemType[] = [
  'dailyQuiz',
  'promotedItem',
  'specialQuiz',
  'dailyCareerPath',
  'dateRangeChallenges',
  'friendActivity',
  'popularQuizzes',
  'leaderboard',
  'promotedPredictionQuiz',
  'promotedLeagueCollection',
  'promotedLeagues',
  'userChallengeList',
  'leagueCollections',
  'promotedClubs',
]

const Content = () => {
  const t = useT()

  const { checkForRedirectResult } = useAuthContext()

  const homePageQuery = useHomePageQuery()

  const { authUser } = useAuthContext()
  const viewerQuery = useViewerQuery({ skip: !authUser })

  const authenticated = Boolean(authUser)

  const hasAvatar = () =>
    viewerQuery.data?.viewer?.avatarData &&
    !JSON.parse(viewerQuery.data?.viewer?.avatarData).IsBase

  const nativeApp = useNativeApp()

  useEffect(() => {
    if (!nativeApp.isNativeApp) {
      return
    }

    if (
      !nativeApp.trackingPermissionStatus ||
      nativeApp.trackingPermissionStatus === 'undetermined'
    ) {
      nativeApp.requestTrackingPermission()
    }

    if (nativeApp.pushNotificationsPermissionStatus === 'undetermined') {
      nativeApp.askForPushNotificationPermission()
    }
  }, [
    nativeApp.isNativeApp,
    nativeApp.pushNotificationsPermissionStatus,
    nativeApp.trackingPermissionStatus,
  ])

  useEffect(() => {
    const checkAndHandleRedirect = async () => {
      await checkForRedirectResult()
    }

    checkAndHandleRedirect()
  }, [])

  const { promotedLeagues, showPromotedLeagueCollection } = useMemo(() => {
    const promotedLeagues = sortLeagues(
      homePageQuery.data?.homePage.promotedLeagueCollection?.leagues ?? []
    )

    const showPromotedLeagueCollection =
      homePageQuery.data?.homePage.promotedLeagueCollection &&
      promotedLeagues.length > 0

    return {
      promotedLeagues,
      showPromotedLeagueCollection,
    }
  }, [homePageQuery.data?.homePage.promotedLeagueCollection])

  const leagueCollections = useMemo(
    () => homePageQuery.data?.homePage.leagueCollections ?? [],
    [homePageQuery.data?.homePage.leagueCollections]
  )

  const popularQuizzes = useMemo(
    () => homePageQuery.data?.homePage.popularQuizzes ?? [],
    [homePageQuery.data?.homePage.popularQuizzes]
  )

  const friendActivity = useMemo(
    () => homePageQuery.data?.homePage.friendActivity,
    [homePageQuery.data?.homePage.friendActivity]
  )

  const dateRangeChallenges = homePageQuery.data?.homePage.dateRangeChallenges

  const PromotedLeagues = () => (
    <>
      {showPromotedLeagueCollection &&
        promotedLeagues.map((league) => (
          <LeagueItem
            key={league.id}
            league={league}
            css={css`
              max-width: 380px;
              flex: 1;
              margin-right: 10px;
            `}
          />
        ))}
    </>
  )

  // unpack homepage data
  const specialQuiz = homePageQuery.data?.homePage.specialQuiz
  const promotedClubs = homePageQuery.data?.homePage.promotedClubs ?? []
  const joinableLiveQuizExists = homePageQuery.data?.getJoinableLiveQuizExists
  const earnedBadges = homePageQuery.data?.earnedBadges

  const [playConfetti, setPlayConfetti] = useState(false)

  const BuildHomePageItem: FC<{
    item: HomePageItemType
  }> = ({ item }: { item: HomePageItemType }) => {
    switch (item) {
      case 'dailyQuiz':
        return (
          <DailyQuiz
            data={{ league: homePageQuery.data?.dailyQuiz }}
            loading={homePageQuery.loading}
          />
        )
      case 'dateRangeChallenges':
        if (!dateRangeChallenges || !dateRangeChallenges.length) {
          return null
        }

        return (
          <Flex column gap={'large'}>
            {dateRangeChallenges?.map((dateRangeChallenge) => (
              <DateRangeChallengeItem
                key={dateRangeChallenge.id}
                dateRangeChallenge={dateRangeChallenge}
                loading={homePageQuery.loading}
              />
            ))}
          </Flex>
        )

      case 'dailyCareerPath':
        return (
          <DailyCareerPathQuiz
            data={{ league: homePageQuery.data?.dailyCareerPath }}
            loading={homePageQuery.loading}
            css={margin.vertical('large')}
          />
        )

      case 'promotedPredictionQuiz':
        return (
          <PromotedPredictionQuiz
            data={{ league: homePageQuery.data?.promotedPredictionQuiz }}
            loading={homePageQuery.loading}
          />
        )

      case 'specialQuiz':
        if (!specialQuiz) {
          return null
        }

        return (
          <SpecialQuiz
            leaguePageQueryResult={{
              league: specialQuiz,
            }}
          />
        )
      case 'leaderboard':
        return (
          <TopPlayersTodayPreviewComponent
            entries={homePageQuery.data?.usersGlobalLeaderboard ?? []}
            loading={homePageQuery.loading}
          />
        )
      case 'friendActivity':
        if (!friendActivity) {
          return null
        }

        return <FriendActivity friendActivity={friendActivity} />
      case 'popularQuizzes':
        if (!popularQuizzes || !popularQuizzes.length) {
          return null
        }

        return <PopularQuizzesComponent quizzes={popularQuizzes} />
      case 'promotedLeagues':
        if (!promotedLeagues || !promotedLeagues.length) {
          return null
        }

        return <PromotedLeagues />
      case 'userChallengeList':
        return <UserChallengeList daysLimit={7} isOnHomePage />
      case 'promotedLeagueCollection':
        if (!leagueCollections || !leagueCollections.length) {
          return null
        }

        return (
          <Flex column>
            {(leagueCollections ?? []).map((leagueCollection) => {
              if (leagueCollection.leagues.length === 0) {
                return null
              }

              return (
                <Fragment key={leagueCollection.id}>
                  <Spacer height="large" />

                  <Flex horizontal="space-between">
                    <Text color="white" size="small">
                      {leagueCollection.title}
                    </Text>

                    <Text color="white" size="small">
                      ({leagueCollection.leagues.length})
                    </Text>
                  </Flex>

                  {leagueCollection.leagues.length === 1 ? (
                    <LeagueItem
                      key={leagueCollection.leagues[0].id}
                      league={leagueCollection.leagues[0]}
                      css={css`
                        max-width: 380px;
                      `}
                    />
                  ) : (
                    <Flex
                      gap="medium"
                      css={css`
                        overflow-x: scroll;
                        padding-top: 32px;
                        padding-bottom: 16px;
                      `}
                    >
                      {leagueCollection.leagues.map((league) => (
                        <LeagueItem
                          key={league.id}
                          league={league}
                          css={css`
                            max-width: 380px;
                          `}
                        />
                      ))}
                    </Flex>
                  )}
                </Fragment>
              )
            })}
          </Flex>
        )
      case 'promotedClubs':
        if (!promotedClubs || !promotedClubs.length) {
          return null
        }
        return (
          <>
            <Text color="white" size="small">
              {t('Popular clubs')}
            </Text>

            <Flex
              gap="medium"
              css={css`
                overflow-x: scroll;
              `}
            >
              {promotedClubs.map((club) => {
                return <ClubGridElement key={club.id} club={club} />
              })}
            </Flex>
          </>
        )
      case 'promotedItem':
        return (
          <>
            {playConfetti && (
              <Flex
                horizontal={'center'}
                css={css`
                  width: 100%;
                `}
              >
                <ConfettiExplosion
                  css={css`
                    position: absolute;
                  `}
                  particleCount={100}
                  force={0.5}
                  duration={3500}
                  zIndex={10}
                  colors={[
                    colors.green300,
                    colors.green400,
                    colors.yellow300,
                    colors.yellow400,
                    colors.orange400,
                    colors.red400,
                  ]}
                />
              </Flex>
            )}
            <PromotedItem
              authUser={authUser}
              setPlayConfetti={setPlayConfetti}
            />
          </>
        )
      default:
        return null
    }
  }

  return (
    <Flex column grow>
      <PageContent>
        <PageRefreshComponent />
        <WelcomeBanner css={margin.bottom('large')} />
        <EnablePushNotificationsBanner css={margin.bottom('large')} />
        <CheckClubMembershipBadges earnedBadges={earnedBadges} />

        {!homePageQuery.loading &&
          authenticated &&
          !authUser?.isAnonymous &&
          !hasAvatar() && <AvatarPromoBanner />}

        {joinableLiveQuizExists && (
          <>
            <Spacer height="large" />
            <JoinLiveQuizButton />
            <Spacer height="large" />
          </>
        )}

        <Flex column gap="large">
          {homepageItemOrder.map((item) => {
            return <BuildHomePageItem key={item} item={item} />
          })}
        </Flex>
      </PageContent>

      <Spacer height="large" />
      <ToggleSnowComponent />
      <Spacer height="large" />
    </Flex>
  )
}

export const HomePage = () => {
  useTrackEvent(AnalyticsEventType.PageViewHome)
  const { authUser } = useAuthContext()
  const authenticated = Boolean(authUser)

  return (
    <Layout
      withHeader={!(authenticated && !authUser?.isAnonymous)}
      profileBanner
      withFooter
      showDownLoadButtons
      backgroundColor={colors.green600}
      showMaintenanceMessage
      showCookiebotButton={isProduction}
      showFooterDownloadButtons
      css={css`
        position: relative;
      `}
    >
      <SnowComponent />
      <Flex column grow>
        <Content />
      </Flex>
    </Layout>
  )
}

const SnowComponent = () => {
  const isDecember = new Date().getMonth() === 11

  const snowDisabled = window.localStorage.getItem('snowDisabled') === 'true'

  const screenWidth = useScreenWidth()

  if (!isDecember || snowDisabled) {
    return null
  }

  const getParticleCount = (): number => {
    if (screenWidth < 450) return 30
    if (screenWidth < 600) return 50
    if (screenWidth < 800) return 80
    return 100
  }

  return (
    <Snowfall
      snowflakeCount={getParticleCount()}
      style={{ zIndex: 3, position: 'fixed', width: '100vw', height: '100vh' }}
    />
  )
}

const ToggleSnowComponent = () => {
  const t = useT()
  const isDecember = new Date().getMonth() === 11

  const [snowDisabled, setSnowDisabled] = useState(
    window.localStorage.getItem('snowDisabled') === 'true' || !isDecember
  )

  if (!isDecember) {
    return null
  }

  return (
    <Flex horizontal="center" vertical="center">
      <ToggleSwitch
        label={
          <TextNew
            color={colors.white}
            css={css`
              text-transform: capitalize;
            `}
          >
            {t(`Snow`)}
          </TextNew>
        }
        value={!snowDisabled}
        onValue={(value) => {
          console.log('snowDisabled', !value)
          setSnowDisabled(!value)
          window.localStorage.setItem('snowDisabled', String(!value))
          window.location.reload()
        }}
      />
    </Flex>
  )
}
