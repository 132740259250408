/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { storeItems } from '../../common/storeItems'
import { PrimaryButton } from '../../components/Button'
import { Divider } from '../../components/Divider'
import { HeadingNew } from '../../components/HeadingNew'
import { Loader } from '../../components/Loader'
import { TextNew } from '../../components/TextNew'
import { Flex } from '../../components/layout/Flex'
import { Layout } from '../../components/layout/Layout'
import { PageContent } from '../../components/layout/PageContent'
import { useAuthContext } from '../../lib/AuthContext'
import { useT } from '../../lib/i18n/useT'
import { Spacer } from '../../styles/margin'
import { colors } from '../../styles/theme'
import { Avatar } from '../Avatar/Avatar'
import { AvatarParts, makeAvatarParts } from '../Avatar/util'
import { User, useUserProfileQuery } from '../Profile/queries'
import { useBuyStoreItemMutation } from './queries'
import { OperationVariables, QueryResult } from '@apollo/client'

const ShopItem = ({
  title,
  part,
  style,
  avatarParts,
  cost,
  useBorder,
  profileQuery,
}: {
  title: string
  part:
    | 'Hair'
    | 'Eyes'
    | 'Brows'
    | 'Mouth'
    | 'Chest'
    | 'Arms'
    | 'Sleeves'
    | 'Shoulders'
    | 'Collar'
  style: string
  avatarParts: AvatarParts
  cost: number
  useBorder?: boolean
  profileQuery: QueryResult<
    {
      user: User | null
    },
    OperationVariables
  >
}) => {
  const t = useT()
  const user = profileQuery.data?.user
  const hasStyle = user?.unlockedStyles.includes(style)
  const [buyStoreItemMutation, { loading }] = useBuyStoreItemMutation()

  if (!user) {
    return <></>
  }
  return (
    <Flex
      css={css`
        position: relative;
      `}
      column
      gap="medium"
      horizontal="center"
    >
      {hasStyle && (
        <img
          src="Checkmark.svg"
          css={css`
            z-index: 1;
            margin: 20%;
            height: 60%;
            position: absolute;
            filter: invert(48%) sepia(13%) saturate(3207%) hue-rotate(130deg)
              brightness(95%);
          `}
        />
      )}
      <TextNew color="white" size={'large'}>
        {title}
      </TextNew>
      <PrimaryButton
        variant="lightGreen"
        css={
          hasStyle &&
          css`
            pointer-events: none;
          `
        }
        onClick={async () => {
          if (hasStyle) return
          if (user.coins < cost) {
            alert(
              t(`You don't have enough fcCoins to purchase this style.`) +
                '\n' +
                t(`Play the daily to earn more!`)
            )
            return
          }
          const styles = user?.unlockedStyles
          await buyStoreItemMutation({
            variables: {
              input: { style },
            },
          })
          const updatedProfileQuery = await profileQuery.refetch()
          const updatedUser = updatedProfileQuery?.data.user
          const updatedStyles = updatedUser?.unlockedStyles ?? []
          // Small timeout to let the components update
          setTimeout(() => {
            if (styles.length < updatedStyles.length) {
              /*alert(
                t(
                  `Purchase was successful. Congratulations with your new style!`
                )
              )*/
            } else {
              alert(
                t(`Something went wrong with your purchase. Please try again`)
              )
            }
          }, 10)
        }}
      >
        <Flex>
          {loading ? (
            <Flex
              css={css`
                height: 200px;
                width: 183px;
              `}
            >
              <Loader center delay={0} />
            </Flex>
          ) : (
            <Avatar
              userId={style} // Just an unique ID to differentiate each svg (They need to use different classes)
              height={200}
              FGColor={'#191919'}
              BGColor={colors.green600}
              partsOverride={{
                ...avatarParts,
                [part]: { ...avatarParts[part], style },
              }}
              //hideOthers //If you want to hide eyes/brows/mouth
              iconPart={part} //If you want to zoom into the part
              border={useBorder ? avatarParts[part].borders : undefined} //If you want to zoom into the part with specific border
              bgasfg={avatarParts[part].bgasfg}
            />
          )}
        </Flex>
      </PrimaryButton>

      <Flex horizontal="center" vertical="flex-end" gap={1}>
        <TextNew color="white" size={30}>
          {cost}
        </TextNew>
        <img
          css={css`
            height: 25px;
          `}
          src="/Coin.svg"
        />
      </Flex>
    </Flex>
  )
}

const Content = () => {
  const { authUser } = useAuthContext()

  const userSlug = authUser?.uid
  const profileQuery = useUserProfileQuery(userSlug ?? '', userSlug === null)
  const user = profileQuery.data?.user
  const avatarDataRaw = user?.avatarData
  if (!avatarDataRaw) {
    return <></>
  }
  const avatarData = JSON.parse(avatarDataRaw)
  const avatarParts = makeAvatarParts(avatarData)

  const sectionsParts = [...new Set(storeItems.map(({ part }) => part))]
  return (
    <>
      {sectionsParts.map((part) => (
        <>
          <Divider size={1} />
          <HeadingNew
            css={css`
              text-align: center;
            `}
            color="white"
            level={1}
          >
            {part}
          </HeadingNew>
          <Divider size={1} />
          <Spacer height={30} />
          <Flex
            css={css`
              width: 100%;
            `}
            horizontal="space-around"
            wrap
            vertical="center"
            gap="huge"
          >
            {storeItems
              .filter((it) => it.part === part)
              .map(({ title, id, part, cost, useBorder }) => (
                <ShopItem
                  title={title}
                  part={part}
                  style={id}
                  cost={cost}
                  avatarParts={avatarParts}
                  useBorder={useBorder}
                  profileQuery={profileQuery}
                />
              ))}
          </Flex>
          <Spacer height={80} />
        </>
      ))}
    </>
  )
}
export const StorePage = () => {
  const t = useT()
  const { authUser } = useAuthContext()
  const authenticated = Boolean(authUser)
  return (
    <Layout
      backgroundColor={colors.green600}
      withHeader={!(authenticated && !authUser?.isAnonymous)}
      profileBanner
      withFooter
      showCookiebotButton
      title={`fcQuiz | ${t('Store')}`}
    >
      <PageContent>
        <Content />
      </PageContent>
    </Layout>
  )
}
