/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FC, useState } from 'react'
import {
  SanityAuthenticTeam,
  SanityCareerPathQuestion,
  SanityQuestion,
} from '../../lib/sanity/types'
import { margin, Spacer } from '../../styles/margin'
import theme from '../../styles/theme'
import { useLeagueQuizContext } from '../LeagueQuiz/LeagueQuizContext'
import { Text } from '../../components/Text'
import { Flex } from '../../components/layout/Flex'
import { PageContent } from '../../components/layout/PageContent'
import { QuizStatusComponent } from '../../components/leagueQuiz/QuizStatusComponent'
import { QuestionAnswer } from '../../components/clientSideQuiz/ClientSideQuiz'
import { PredictionAlternativeButton } from './PredictionAlternativeButton'

export const ActiveQuestionScreen: FC<{
  numCompletedQuestions: number
  activeQuestion: SanityQuestion
  isEmbed: boolean
  questionsCount: number
  answerQuestion: (answer: QuestionAnswer) => Promise<void>
}> = ({
  numCompletedQuestions,
  activeQuestion,
  isEmbed,
  questionsCount,
  answerQuestion,
}) => {
  return (
    <AnswerQuestion
      {...{
        numCompletedQuestions,
        activeQuestion,
        isEmbed,
        questionsCount,
        answerQuestion,
      }}
    />
  )
}

export const AnswerQuestion: FC<{
  numCompletedQuestions: number
  activeQuestion: SanityQuestion
  isEmbed?: boolean
  questionsCount: number
  answerQuestion: (answer: QuestionAnswer) => Promise<void>
}> = ({
  numCompletedQuestions,
  activeQuestion,
  isEmbed,
  questionsCount,
  answerQuestion,
}) => {
  const [startedAt] = useState(new Date())

  const [questionAnswered, setQuestionAnswered] = useState(false)

  const { campaignHeaderHeight } = useLeagueQuizContext()

  const textColor = theme.colors.black

  return (
    <PageContent
      css={css`
        padding-top: 24px;
        height: calc(100vh - ${campaignHeaderHeight});
      `}
    >
      <Flex
        column
        grow
        css={css`
          max-width: 560px;
          margin: 0 auto;
          width: 100%;
          height: 100%;
        `}
      >
        <Spacer height={isEmbed ? 0 : 'small'} />
        <QuizStatusComponent
          currentQuestion={numCompletedQuestions + 1}
          totalQuestions={questionsCount}
        />
        <Spacer height={isEmbed ? 0 : 'small'} />
        <Text
          key={activeQuestion.text}
          color={textColor}
          css={margin.vertical(isEmbed ? 'small' : 'medium')}
          textAlign="center"
          size="medium"
        >
          {activeQuestion.text}
        </Text>

        <Flex column>
          {activeQuestion.alternatives
            .sort((a, b) => (a.index ?? 0) - (b.index ?? 0))
            .map((alternative) => {
              const answeredAt = new Date()
              const correct = alternative.correct
              return (
                <PredictionAlternativeButton
                  key={alternative.id}
                  onClick={() => {
                    setQuestionAnswered(true)
                    answerQuestion({
                      questionId: activeQuestion.id,
                      alternativeId: alternative.id,
                      startedAt,
                      answeredAt,
                      correct,
                      score: alternative.correct ? 10 : 0,
                      timeBonus: 0,
                    })
                  }}
                  disabled={questionAnswered}
                >
                  {alternative.reference?._id
                    ? renderReferenceSanity(alternative.reference, textColor)
                    : alternative.text}
                </PredictionAlternativeButton>
              )
            })}
        </Flex>
      </Flex>
    </PageContent>
  )
}

export const renderReferenceSanity = (
  reference: SanityAuthenticTeam | SanityCareerPathQuestion | null,
  textColor: string
) => {
  if (!reference?._id) {
    return null
  }

  // Check for AuthenticTeam
  if (reference._type === 'authenticTeam') {
    return (
      <Flex vertical={'center'} gap={'small'}>
        <img
          src={reference.logoUrl}
          alt={`${reference.name} logo`}
          height={24}
        />
        <Text size="medium" color={textColor}>
          {reference.name}
        </Text>
      </Flex>
    )
  }

  // Check for CareerPathQuestion
  if (reference._type === 'careerPathQuestion') {
    return (
      <Flex>
        <img
          src={reference.photoUrl}
          alt={`${reference.firstName} ${reference.lastName}`}
        />
        <Text size="medium" color={textColor}>
          {reference.firstName} {reference.lastName}
        </Text>
      </Flex>
    )
  }

  return null
}
