/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PrimaryButton, SecondaryButton } from '../../../components/Button'
import { Heading } from '../../../components/Heading'
import { Loader } from '../../../components/Loader'
import { SelectInput } from '../../../components/SelectInput'
import { Text } from '../../../components/Text'
import { TextInput } from '../../../components/TextInput'
import { Flex } from '../../../components/layout/Flex'
import {
  Locale,
  locales,
  LocaleString,
  localeToFlag,
} from '../../../lib/i18n/locale'
import { useT } from '../../../lib/i18n/useT'
import { Spacer } from '../../../styles/margin'
import {
  usePrimeLeagueQuizMutation,
  useUpdateQuestionMutation,
  useUpdateQuizMutation,
} from '../queries'
import { TranslateQuizPromoTextComponent } from './TranslateQuizPromoTextComponent'
import { TranslateQuizTitleComponent } from './TranslateQuizTitleComponent'

type Question = {
  id: string
  question: string
  alternatives: {
    text: string
    reference?: string
  }[]
}

export const Overview = (props: {
  questionsFrom: Question[]
  questionsTo: Question[]
  addMoreQuestions: any
  setEditQuestionIndex: any
  fromLanguage: Locale
  setFromLanguage: any
  toLanguage: Locale
  setToLanguage: any
  availableLanguages: Locale[]
  title: string | undefined | null
  setTitle: (value: string | undefined) => void
  promoText: string | undefined | null
  setPromoText: (value: string | undefined | null) => void
  quizId: string
  quizTitle: LocaleString
  quizPromoText: LocaleString | undefined
  leagueId: string
}) => {
  const t = useT()
  const [updateQuestionMutation, { error: updateQuestionError }] =
    useUpdateQuestionMutation()
  const [updateQuizMutation, { error: updateQuizError }] =
    useUpdateQuizMutation()
  const [primeLeagueQuizMutation, { error: primeLeagueQuizError }] =
    usePrimeLeagueQuizMutation()

  const [isLoading, setIsLoading] = useState(false)
  const [uploadIndex, setUploadIndex] = useState(0)
  const [uploadingQuiz, setUploadingQuiz] = useState(false)

  const hasPromoText =
    (props.quizPromoText
      ? props.quizPromoText[props.fromLanguage] ?? ''
      : '') !== ''

  const fieldIsValid = (title: string | undefined | null) => {
    if (!title || title === '' || title.trim() === '') {
      return false
    }

    return true
  }

  const publish = async () => {
    try {
      setIsLoading(true)

      await updateQuizMutation({
        variables: {
          input: {
            quizid: props.quizId,
            title: props.title ?? '',
            promotionalText: props.promoText ?? '',
            language: props.toLanguage,
            leagueSlug: props.leagueId,
          },
        },
      })

      const promises = []
      for (let question of props.questionsTo) {
        promises.push(
          updateQuestionMutation({
            variables: {
              input: {
                id: question.id,
                question: question.question, //! is safe since it has been nullchecked
                alternatives: question.alternatives.map((it) => it.text), //! is safe since it has been nullchecked
                language: props.toLanguage,
                leagueSlug: props.leagueId,
              },
            },
          }).finally(() => setUploadIndex(uploadIndex + 1))
        )
      }

      await Promise.all(promises)
      await primeLeagueQuizMutation({
        variables: {
          input: { leagueSlug: props.leagueId, quizId: props.quizId },
        },
      })
      setIsLoading(false)
      setUploadingQuiz(true)
      window.localStorage.removeItem(props.leagueId + '|' + props.quizId)
    } catch {
      //Error is handled earlier in code
      return
    }
  }

  // Displays the "Successfully uploaded quiz"
  useEffect(() => {
    if (uploadingQuiz && !isLoading) {
      setTimeout(() => {
        setUploadingQuiz(false)
      }, 2000)
    }
  }, [uploadingQuiz, isLoading])

  const navigate = useNavigate()

  const questionIsCorrectlyFilled = (question: Question) => {
    return (
      question?.id &&
      question?.question !== '' &&
      question?.alternatives.every((it) => it.text !== '')
    )
  }

  if (updateQuizError || updateQuestionError || primeLeagueQuizError) {
    return (
      <Flex horizontal="center" column gap="medium">
        <Heading level={4}>
          {t(`Something went wrong while publishing the quiz`)}
        </Heading>
        <Text size="small">
          {t(
            `Please refresh the page and try again, if this keeps occuring, contact support with info:`
          )}
        </Text>
        <Flex gap="small" vertical="center">
          {updateQuestionError && <Text size="small">updateQuestion</Text>}
          {updateQuizError && <Text size="small">updateQuiz</Text>}
          {primeLeagueQuizError && <Text size="small">primeLeagueQuiz</Text>}
          <Text>-</Text>
          <Text size="small">
            {updateQuizError?.message ||
              updateQuestionError?.message ||
              primeLeagueQuizError?.message}
          </Text>
        </Flex>
      </Flex>
    )
  }

  if (isLoading) {
    return (
      <Flex horizontal="center" column gap="medium">
        <Loader />
        <Heading level={3}>
          {uploadIndex}/{props.questionsTo.length}
        </Heading>
      </Flex>
    )
  }
  if (uploadingQuiz) {
    return (
      <Flex horizontal="center">
        <Heading
          level={3}
          css={css`
          opacity: 0; /* Set initial opacity to 0 */
          animation: fadeIn 2s ease-in-out forwards;

          @keyframes fadeIn {
            0%, 100% {
              opacity: 0; /* Start with opacity 0 */
            }
            10%, 90% {
              opacity: 1; /* End with opacity 1 */
            }
            
          }
        }
        `}
        >
          {t(`Successfully translated quiz`)}
        </Heading>
      </Flex>
    )
  }
  if (uploadIndex > 0) {
    return (
      <Flex horizontal="center" column gap={'medium'}>
        <Heading level={5}>{t(`Good job!`)}</Heading>
        <Flex gap="medium">
          <SecondaryButton
            noWrap
            onClick={() => {
              navigate(`/series/${props.leagueId}/${props.quizId}`)
            }}
          >
            {t('To the series page')}
          </SecondaryButton>
        </Flex>
      </Flex>
    )
  }

  return (
    <Flex gap="medium" column>
      <Flex
        css={css`
          width: 100%;
        `}
        gap="medium"
      >
        <Flex
          css={css`
            flex: 1;
          `}
          column
        >
          <Text size="medium">{t('From')}</Text>
          <SelectInput
            value={props.fromLanguage}
            onValue={props.setFromLanguage}
            selectCss={css`
              height: 40px;
              border-radius: 5px;
              font-size: 20px;
            `}
          >
            {props.availableLanguages.map((locale: Locale) => (
              <option value={locale} key={locale}>
                {localeToFlag[locale]}
              </option>
            ))}
          </SelectInput>
        </Flex>
        <Flex
          css={css`
            flex: 1;
          `}
          column
        >
          <Text size="medium">{t('To')}</Text>
          <SelectInput
            value={props.toLanguage}
            onValue={props.setToLanguage}
            selectCss={css`
              height: 40px;
              border-radius: 5px;
              font-size: 20px;
            `}
          >
            {locales.map((locale: Locale) => (
              <option value={locale} key={locale}>
                {localeToFlag[locale]}
              </option>
            ))}
          </SelectInput>
        </Flex>
      </Flex>

      <TranslateQuizTitleComponent
        {...props}
        isValid={fieldIsValid(props.title)}
      />
      <Spacer height={4} />

      {hasPromoText && (
        <TranslateQuizPromoTextComponent
          {...props}
          isValid={fieldIsValid(props.promoText)}
        />
      )}
      <Spacer height={4} />

      <Flex
        css={css`
          width: 100%;
        `}
        column
        gap="small"
      >
        <Text size="medium">{t('Questions')}</Text>
        {[...new Array(props.questionsFrom.length).keys()].map(
          (index: number) => {
            const questionFrom = props.questionsFrom[index]
            const questionTo = props.questionsTo[index]
            return (
              <Flex
                key={index}
                css={css`
                  width: 100%;
                  border-radius: 5px;
                `}
                horizontal="space-between"
                vertical="center"
                gap="small"
              >
                <Text
                  css={css`
                    width: 30px;
                  `}
                >
                  Q{index + 1}
                </Text>
                <TextInput
                  css={css`
                    flex: 1;
                  `}
                  value={questionFrom?.question ?? ''}
                  textCss={css`
                    border-color: black;
                  `}
                  onValue={() => {}}
                  onFocus={() => {
                    props.setEditQuestionIndex(index)
                    props.addMoreQuestions()
                  }}
                  disabled={index >= props.questionsFrom.length}
                />
                {questionIsCorrectlyFilled(questionTo) && (
                  <img
                    src="/thumbs_up2.png"
                    css={css`
                      position: absolute;
                      right: 0px;
                      width: 22px;
                    `}
                  ></img>
                )}
              </Flex>
            )
          }
        )}
      </Flex>
      <Flex
        css={css`
          width: 100%;
        `}
        horizontal="flex-end"
      >
        <PrimaryButton
          css={css`
            padding-left: 40px;
            padding-right: 40px;
          `}
          disabled={
            !props.questionsTo.every(questionIsCorrectlyFilled) ||
            !fieldIsValid(props.title) ||
            (hasPromoText && !fieldIsValid(props.promoText))
          }
          onClick={publish}
        >
          {t('Publish')}
        </PrimaryButton>
      </Flex>
    </Flex>
  )
}
