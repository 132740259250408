/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useEffect, useState } from 'react'
import { PrimaryButton } from '../../components/Button'
import { Loader } from '../../components/Loader'
import { Text } from '../../components/Text'
import { ErrorMessage } from '../../components/TextInput'
import { Flex } from '../../components/layout/Flex'
import { colors } from '../../styles/theme'
import { Alternative } from '../Generate/Prediction/PredicitonQuestionForm'
import { QuestionForm } from '../Generate/QuestionForm'
import { useTranslateQuestionQuery, useUpdateQuestionMutation } from './queries'

type Question = {
  id: string
  question: string
  alternatives: {
    text: string
    reference?: string
  }[]
}

export const TranslateQuestion = (props: {
  fromLanguage: string
  toLanguage: string
  questionFrom: Question
  setQuestionFrom: any
  questionTo: Question | null
  setQuestionTo: any
  goback?: any
  quizSlug?: string
  editQuestionIndex?: number
  questionsTo?: Question[]
  setQuestionsTo?: any
  refetchQuestionsRef?: any
  useAI?: boolean
  leagueSlug: string
}) => {
  const {
    fromLanguage,
    toLanguage,
    questionFrom,
    setQuestionFrom,
    questionTo,
    setQuestionTo,
    goback,
    quizSlug,
    editQuestionIndex,
    questionsTo,
    setQuestionsTo,
    refetchQuestionsRef,
    useAI,
  } = props
  const noQuestionFrom = questionFrom.question == '' || !questionFrom.question
  //const hasAlreadyTranslated = questionTo?.id && questionTo.id !== ''
  const skip = noQuestionFrom || !useAI
  const { data, loading: loadingAI } = useTranslateQuestionQuery(
    {
      question: questionFrom.question,
      alternatives: questionFrom.alternatives.map((it) => it.text),
      toLanguage: toLanguage,
      fromLanguage: fromLanguage,
      leagueSlug: props.leagueSlug,
    },
    skip
  )

  const [errorMsg, setErrorMsg] = useState<string>('')

  useEffect(() => {
    if (data && data.translateQuestion) {
      const translatedAlternatives = data.translateQuestion.alternatives

      const sanityQuestionOkay = (questionTo?.question ?? '').trim() !== ''

      const sanityAlternativesOkay =
        questionTo?.alternatives.every((it) => (it.text ?? '').trim() !== '') &&
        questionTo?.alternatives.length > 0

      const sanityQuestion = sanityQuestionOkay ? questionTo?.question : null

      const sanityAlternatives = sanityAlternativesOkay
        ? questionTo?.alternatives
        : null

      setQuestionTo({
        id: questionFrom.id, // I use ID also to say "I've started translating"
        question: sanityQuestion ?? data.translateQuestion.question,
        alternatives:
          sanityAlternatives ??
          translatedAlternatives.map((it) => {
            return {
              text: it,
            }
          }),
      })
      setErrorMsg('')
    }
    if (!useAI && questionTo?.id !== questionFrom.id) {
      setQuestionTo({
        id: questionFrom.id, // I use ID also to say "I've started translating"
        question: '',
        alternatives: questionFrom.alternatives.map((_) => ''),
      })
      setErrorMsg('')
    }
  }, [data])

  const [updateQuestionMutation] = useUpdateQuestionMutation()

  const exitQuestion = () => {
    setQuestionTo(null)
    setQuestionFrom(null)
    goback && goback()
  }

  const translateQuestion = () => {
    if (!quizSlug) {
      updateQuestionMutation({
        variables: {
          input: {
            id: questionFrom.id,
            question: questionTo!.question, //! is safe since it has been nullchecked
            alternatives: questionTo!.alternatives.map((it) => it.text), //! is safe since it has been nullchecked
            language: toLanguage,
            leagueSlug: props.leagueSlug,
          },
        },
      }).then(() => {
        refetchQuestionsRef?.current()
      })
    } else {
      setQuestionsTo([
        ...questionsTo!.slice(0, editQuestionIndex),
        {
          id: questionFrom.id,
          question: questionTo!.question, //! is safe since it has been nullchecked
          alternatives: questionTo!.alternatives, //! is safe since it has been nullchecked
        },
        ...questionsTo!.slice(editQuestionIndex! + 1),
      ])
    }
  }

  if (loadingAI || !questionTo || questionTo.id == '') {
    if (loadingAI) {
      return (
        <Loader
          css={css`
            margin-top: 50px;
            align-self: center;
          `}
        />
      )
    } else {
      return (
        <>
          <ErrorMessage
            css={css`
              margin-top: 10px;
            `}
          >
            Something went wrong
          </ErrorMessage>
          <PrimaryButton
            css={css`
              background-color: ${colors.grey100};
              width: 60px;
              height: 30px;
              margin: 20px auto;
              border-radius: 5px;
              &:hover {
                cursor: pointer;
              }
            `}
            onClick={exitQuestion}
          >
            <Flex vertical="center" horizontal="center">
              <Text
                css={css`
                  font-size: 12px;
                `}
              >
                Cancel
              </Text>
            </Flex>
          </PrimaryButton>
        </>
      )
    }
  }

  return (
    <>
      <QuestionForm
        question={questionTo.question}
        setQuestion={(it: string) => {
          setQuestionTo({
            ...questionTo,
            question: it,
          })
        }}
        alternatives={questionTo.alternatives.map((it) => {
          return { text: it.text, reference: it.reference }
        })}
        setAlternatives={(it: Alternative[]) => {
          setQuestionTo({
            ...questionTo,
            alternatives: it.map((alternative) => alternative),
          })
        }}
        hideCorrect
        questionLabel={questionFrom.question}
        alternativesLabel={questionFrom.alternatives.map((it) => it.text)}
        showDiscard
      />
      <PrimaryButton
        css={css`
          background-color: black;
          width: 100%;
          height: 60px;
          margin-top: 30px;
          border-radius: 5px;
          &:hover {
            cursor: pointer;
          }
        `}
        onClick={() => {
          try {
            if (questionTo.question === '') {
              setErrorMsg('Question must be filled')
              return
            }

            for (let n = 0; n < questionFrom.alternatives.length; n++) {
              if (
                questionTo.alternatives[n].text === '' &&
                questionFrom.alternatives[n].text !== ''
              ) {
                setErrorMsg('Alternatives must be filled')
                return
              }
            }

            if (questionTo.question.includes('\n')) {
              setErrorMsg('Error: Question contains line breaks')
              return
            }

            if (questionTo.alternatives.find((it) => it.text.includes('\n'))) {
              setErrorMsg('Error: Alternatives contains line breaks')
              return
            }

            translateQuestion()
            exitQuestion()
          } catch (e) {
            console.log(e)
          }
        }}
      >
        Save
      </PrimaryButton>
      <Flex
        horizontal="center"
        column
        css={css`
          margin-top: 10px;
        `}
      >
        {errorMsg && <ErrorMessage>{errorMsg}</ErrorMessage>}
      </Flex>
      <PrimaryButton
        css={css`
          background-color: ${colors.grey100};
          width: 60px;
          height: 30px;
          margin: 20px auto;
          border-radius: 5px;
          &:hover {
            cursor: pointer;
          }
        `}
        onClick={exitQuestion}
      >
        <Flex vertical="center" horizontal="center">
          <Text
            css={css`
              font-size: 12px;
            `}
          >
            Cancel
          </Text>
        </Flex>
      </PrimaryButton>
    </>
  )
}
