/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { User } from 'firebase/auth'
import { PrimaryButton } from '../../components/Button'
import { Flex } from '../../components/layout/Flex'
import { Loader } from '../../components/Loader'
import { TextNew } from '../../components/TextNew'
import { useT } from '../../lib/i18n/useT'
import { colors } from '../../styles/theme'
import { useSetAvatarMutation } from '../Avatar/queries'
import { makeAvatarData, makeAvatarParts } from '../Avatar/util'
import SantaHatBackground from '../Home/assets/SantaHatBackground.png'
import { ReactComponent as Coin } from '../Home/coin/Coin.svg'
import { useUserProfileQuery } from '../Profile/queries'
import { useBuyStoreItemMutation } from '../Store/queries'
import { useEffect, useState } from 'react'
import { AnalyticsEventType, trackEvent } from '../../lib/analytics'

const PROMOTED_ITEM_ID = 'Hair7'
const PROMOTED_ITEM_COST = 50

export default function PromotedItem({
  authUser,
  setPlayConfetti,
}: {
  authUser: User | null
  setPlayConfetti: (value: boolean) => void
}) {
  const t = useT()

  const userSlug = authUser?.uid
  const profileQuery = useUserProfileQuery(userSlug ?? '', userSlug === null)
  const user = profileQuery.data?.user
  const hasStyle = user?.unlockedStyles.includes(PROMOTED_ITEM_ID) ?? false
  const [confirmActive, setConfirmActive] = useState(false)
  const [buyStoreItemMutation, { loading }] = useBuyStoreItemMutation()
  const cost = PROMOTED_ITEM_COST

  const [setAvatarMutation, { loading: setAvatarLoading }] =
    useSetAvatarMutation()

  const disabled = hasStyle || !user || user?.coins < cost

  useEffect(() => {
    if (confirmActive) {
      const timeout = setTimeout(() => {
        setConfirmActive(false)
      }, 5000)
      return () => clearTimeout(timeout)
    }
  }, [confirmActive])

  if (!user || hasStyle) {
    return <></>
  }

  const handlePurchase = async () => {
    if (user.coins < cost) {
      return
    }

    if (!confirmActive) {
      setConfirmActive(true)
      return
    }

    try {
      const styles = user?.unlockedStyles ?? []
      await buyStoreItemMutation({
        variables: {
          input: { style: PROMOTED_ITEM_ID },
        },
      })

      const avatarData = user?.avatarData
      if (avatarData) {
        const avatarDataJson = JSON.parse(avatarData)
        const parts = makeAvatarParts(avatarDataJson)
        const data = makeAvatarData(parts)
        const dataJson = JSON.parse(data)
        const newAvatarData = JSON.stringify({
          ...dataJson,
          Hair: {
            style: 'Hair7',
            colors: ['#202020', '#000000'],
          },
        })

        await setAvatarMutation({
          variables: {
            input: newAvatarData,
          },
        })

        setConfirmActive(false)
      }

      const updatedProfileQuery = await profileQuery.refetch()
      const updatedUser = updatedProfileQuery?.data.user
      const updatedStyles = updatedUser?.unlockedStyles

      setTimeout(() => {
        if (!((styles?.length ?? 0) < (updatedStyles?.length ?? 0))) {
          alert(t(`Something went wrong with your purchase. Please try again`))
        } else {
          trackEvent(AnalyticsEventType.Purchase, {
            itemId: PROMOTED_ITEM_ID,
            type: 'HAIR',
          })
          setPlayConfetti(true)
          setTimeout(() => {
            setPlayConfetti(false)
          }, 5000)
        }
      }, 1000)
    } catch (error) {
      console.error('Error during purchase:', error)
    }
  }

  return (
    <Flex
      horizontal={'center'}
      css={css`
        width: 100%;
        height: 100%;
        position: relative;
      `}
    >
      <Flex
        horizontal={'center'}
        vertical={'space-between'}
        column
        css={css`
          width: 100%;
          padding: 10px 20px;
          height: 100px;
          border-radius: 10px;

          background-color: #165b33;
          background-image: url(${SantaHatBackground});
          background-size: cover;
          background-position: center;
          opacity: 1;
        `}
      >
        <TextNew
          size={32}
          color={colors.white}
          condensed
          strong
          css={css`
            margin-bottom: 10px;
          `}
        >
          {t(`Santa hat!`)}
        </TextNew>
        {loading || setAvatarLoading ? (
          <Loader size={40} center delay={0} />
        ) : (
          <PrimaryButton
            disabled={disabled}
            css={css`
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 99px;
              padding: 5px 20px 5px 15px;
              opacity: ${disabled ? 0.7 : 1};
              background-color: ${disabled ? colors.grey400 : colors.yellow400};
              border: 0;
              box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            `}
            onClick={handlePurchase}
            signUpRequired
          >
            {confirmActive ? (
              <TextNew size={24} color={colors.black} condensed strong italic>
                {t(`Confirm`)}
              </TextNew>
            ) : (
              <>
                <Coin
                  css={css`
                    height: 25px;
                    width: 25px;
                  `}
                />
                <TextNew size={24} color={colors.black} condensed strong italic>
                  {cost}
                </TextNew>
              </>
            )}
          </PrimaryButton>
        )}
      </Flex>
    </Flex>
  )
}
