import { Locale as DateLocale, format } from 'date-fns'
import arLocale from 'date-fns/locale/ar-SA'
import daLocale from 'date-fns/locale/da'
import deLocale from 'date-fns/locale/de'
import enLocale from 'date-fns/locale/en-US'
import esLocale from 'date-fns/locale/es'
import frLocale from 'date-fns/locale/fr'
import itLocale from 'date-fns/locale/it'
import jaLocale from 'date-fns/locale/ja'
import nbLocale from 'date-fns/locale/nb'
import ptLocale from 'date-fns/locale/pt'
import svLocale from 'date-fns/locale/sv'
import thLocale from 'date-fns/locale/th'

import { useCallback } from 'react'
import { Locale, useCurrentLocale } from '../lib/i18n/locale'
import { LeagueFrequency } from '../views/League/queries'

export const formatDate = (date: Date, formatString = 'dd. MMM HH.mm') => {
  return format(date, formatString, { locale: nbLocale })
}

const dateLocales: Record<Locale, DateLocale> = {
  en: enLocale,
  no: nbLocale,
  es: esLocale,
  de: deLocale,
  fr: frLocale,
  it: itLocale,
  ar: arLocale,
  pt: ptLocale,
  ja: jaLocale,
  sv: svLocale,
  da: daLocale,
  th: thLocale,
}

type DateFormat = 'short-datetime' | 'full-date' | 'full-datetime'
const dateFormats: Record<DateFormat, Record<Locale, string>> = {
  'short-datetime': {
    en: 'dd MMM h:mm aaa',
    es: 'dd MMM HH:mm',
    de: 'dd MMM HH:mm',
    no: 'dd. MMM HH.mm',
    fr: 'dd MMM HH:mm',
    it: 'dd MMM HH:mm',
    ar: 'dd MMM HH:mm',
    pt: 'dd MMM HH:mm',
    ja: 'dd MMM HH:mm',
    sv: 'dd. MMM HH.mm',
    da: 'dd. MMM HH.mm',
    th: 'dd. MMM HH.mm',
  },
  'full-date': {
    en: 'dd MMM yyyy',
    es: 'dd MMM yyyy',
    de: 'dd MMM yyyy',
    no: 'dd. MMM yyyy',
    fr: 'dd MMM yyyy',
    it: 'dd MMM yyyy',
    ar: 'dd MMM yyyy',
    pt: 'dd MMM yyyy',
    ja: 'dd MMM yyyy',
    sv: 'dd. MMM yyyy',
    da: 'dd. MMM yyyy',
    th: 'dd. MMM yyyy',
  },
  'full-datetime': {
    en: 'dd MMM yyyy, h:mm aaa',
    es: 'dd MMM yyyy, HH:mm',
    de: 'dd MMM yyyy, HH:mm',
    no: 'dd. MMM yyyy, HH.mm',
    fr: 'dd MMM yyyy, HH:mm',
    it: 'dd MMM yyyy, HH:mm',
    ar: 'dd MMM yyyy, HH:mm',
    pt: 'dd MMM yyyy, HH:mm',
    ja: 'dd MMM yyyy, HH:mm',
    sv: 'dd. MMM yyyy, HH.mm',
    da: 'dd. MMM yyyy, HH.mm',
    th: 'dd. MMM yyyy, HH.mm',
  },
}

export const useFormatDate = () => {
  const locale = useCurrentLocale()

  const formatDate = useCallback(
    (date: Date | string, dateFormat: DateFormat) => {
      date = typeof date === 'string' ? new Date(date) : date

      return format(date, dateFormats[dateFormat][locale], {
        locale: dateLocales[locale],
      })
    },
    [locale]
  )

  return formatDate
}

export const addFrequencyToDate = (date: Date, frequency: LeagueFrequency) => {
  const newDate = new Date(date)
  switch (frequency) {
    case LeagueFrequency.daily:
      newDate.setDate(date.getDate() + 1)
      break
    case LeagueFrequency.weekly:
      newDate.setDate(date.getDate() + 7)
      break
    case LeagueFrequency.monthly:
      newDate.setDate(date.getDate() + 30) // Extend the logic further if necessary
      break
  }
  return newDate
}
