/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { DefaultError } from '../../components/DefaultError'
import { Loader } from '../../components/Loader'
import { TextNew } from '../../components/TextNew'
import QuizAd from '../../components/campaign/QuizAdHeader'
import { useExistingQuizSession } from '../../components/clientSideQuiz/ClientSideQuiz'
import { useQuizStatisticsQuery } from '../../components/clientSideQuiz/queries'
import { Flex } from '../../components/layout/Flex'
import { Layout } from '../../components/layout/Layout'
import { Page } from '../../components/leagueQuiz/Page'
import { useAuthContext } from '../../lib/AuthContext'
import { useCurrentLocale } from '../../lib/i18n/locale'
import { useT } from '../../lib/i18n/useT'
import {
  useActiveCampaignForLeague,
  useQuizById,
} from '../../lib/sanity/sanity'
import { margin, Spacer } from '../../styles/margin'
import { colors } from '../../styles/theme'
import { calculateTimeRemaining, timeRemainingToString } from '../Admin/Utils'
import { LeagueQuizContextProvider } from '../LeagueQuiz/LeagueQuizContext'
import { useViewerPredictionQuizInstance } from '../LeagueQuiz/queries'
import FinishedQuiz from './FinishedQuiz'
import { PredictionQuiz } from './PredictionQuiz'
import { FixtureHeader } from './components/FixtureHeader'

const Content = ({ leagueQuizId }: { leagueQuizId: string }) => {
  const t = useT()

  const { authUser } = useAuthContext()
  const [leagueId, setLeagueId] = useState<string>('')
  const currentLocale = useCurrentLocale()

  const { data: quiz, loading: currentQuizLoading } = useQuizById(
    leagueQuizId,
    currentLocale
  )

  const { data: campaign, loading: campaignLoading } =
    useActiveCampaignForLeague(leagueId)

  const session = useExistingQuizSession(quiz?.id)

  const [finishedQuizSession, setFinishedQuizSession] = useState<boolean>(false)

  const { data: instanceData, loading: instanceLoading } =
    useViewerPredictionQuizInstance({
      quizId: leagueQuizId,
      skip: authUser === null,
    })

  useEffect(() => {
    if (session) {
      console.log('session')
    } else if (instanceData?.viewerLeagueQuizInstance) {
      setFinishedQuizSession(true)
    }
  }, [session, instanceData])

  useEffect(() => {
    if (quiz) {
      setLeagueId(quiz.league.id)
    }
  }, [quiz])

  const statsQuery = useQuizStatisticsQuery(leagueQuizId)

  const stats = statsQuery.data?.quizStatistics

  const now = new Date()

  const timeToResults = calculateTimeRemaining(
    now,
    new Date(quiz?.availableTo ?? '')
  )

  if (currentQuizLoading || campaignLoading || instanceLoading) {
    return (
      <Flex horizontal="center" css={margin.top('huge')}>
        <Loader variant="green" />
      </Flex>
    )
  }

  if (!quiz) {
    console.error('No quiz found')
    return (
      <Page>
        <DefaultError
          css={margin.top('huge')}
          sentryErrorMessage={`LeagueQuizPage | quizId: ${leagueQuizId}}`}
        />
      </Page>
    )
  }

  if (quiz && !finishedQuizSession) {
    return (
      <Flex column grow>
        <QuizAd
          campaign={campaign}
          isEmbed={false}
          leagueId={quiz.league.id}
          quizId={leagueQuizId}
        />

        <PredictionQuiz
          quiz={quiz}
          campaign={campaign}
          isEmbed={false}
          quizStatistics={stats}
        />
      </Flex>
    )
  }

  if (!instanceData?.viewerLeagueQuizInstance) {
    console.error('No instance found')

    return (
      <Flex column grow>
        <QuizAd
          campaign={campaign}
          isEmbed={false}
          leagueId={quiz.league.id}
          quizId={leagueQuizId}
        />
        <Flex column gap="small" css={margin.top('large')}>
          <Flex horizontal="center">
            <DefaultError
              css={margin.top('huge')}
              sentryErrorMessage={`LeagueQuizPage | quizId: ${leagueQuizId}}, instanceId: ${instanceData?.viewerLeagueQuizInstance} | No instance found`}
            />
          </Flex>
        </Flex>
      </Flex>
    )
  }

  return (
    <Page showFcQuizLogo logoColor={colors.grey200}>
      <Flex column grow>
        {now < new Date(quiz?.availableTo || '') && (
          <Flex
            horizontal={'flex-end'}
            vertical={'flex-end'}
            css={css`
              gap: 4px;
            `}
          >
            <TextNew
              condensed
              strong
              italic
              color={colors.black}
              size={20}
              css={css`
                padding-bottom: 1px;
              `}
            >
              {t(`Deadline`)}
            </TextNew>
            <TextNew italic strong condensed color={colors.black} size={25}>
              {timeRemainingToString(timeToResults, t)}
            </TextNew>
          </Flex>
        )}

        {quiz.fixtures && quiz.fixtures.length && (
          <FixtureHeader fixture={quiz.fixtures[0]} />
        )}

        <Spacer height={'small'} />

        <TextNew
          italic
          strong
          condensed
          color={colors.black}
          size={35}
          textAlign={'center'}
        >
          {t(`Your Predictions`)}
        </TextNew>

        <Spacer height={'small'} />
        <FinishedQuiz
          playAnimation={false}
          quizId={quiz.id}
          isEmbed={false}
          leagueSlug={quiz.league.slug || quiz.league.id}
          hasCampaign={!!campaign}
          endDateTime={quiz.availableTo}
          completedQuestions={
            instanceData?.viewerLeagueQuizInstance.completedQuestions
          }
          title={quiz.title}
        />
      </Flex>
    </Page>
  )
}
export const PredictionQuizPage = () => {
  const leagueQuizId = useParams<{ leagueQuizId: string }>().leagueQuizId!

  return (
    <LeagueQuizContextProvider>
      <Layout hideNavbar hideHeaderPadding backgroundColor={colors.grey100}>
        <Content leagueQuizId={leagueQuizId} />
      </Layout>
    </LeagueQuizContextProvider>
  )
}
