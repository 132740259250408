/** @jsxImportSource @emotion/react */

import { css, keyframes } from '@emotion/react'
import { FC, useState } from 'react'
import { fadeIn, fcCurve, pulseCurve } from '../../styles/animations'
import { buttonReset } from '../../styles/styles'
import { colors } from '../../styles/theme'
import { User } from '../../views/Profile/queries'
import { Text } from '../../components/Text'
import { Flex } from '../../components/layout/Flex'

type Props = {
  onClick: () => void
  selected?: boolean
  correct?: boolean | null
  className?: string
  disabled?: boolean
  percentageAnswered?: number
  showGlobalStats?: boolean
  challengeUser?: User
  confettiParticleCount?: number
}

export const PredictionAlternativeButton: FC<Props> = ({
  onClick,
  selected,
  children,
  className,
  disabled,
  percentageAnswered,
  showGlobalStats = true,
}) => {
  const [localSelected, setLocalSelected] = useState<boolean | undefined>(
    undefined
  )

  selected = selected ?? localSelected

  const showGlobalStatsLocal =
    showGlobalStats && percentageAnswered !== undefined
  const showLoadingAnimation = selected && disabled

  const getButtonBackgroundColor = () => {
    if ((percentageAnswered as number) > 50) {
      return colors.grey200
    }

    if (selected && (percentageAnswered as number) <= 20) {
      return colors.grey200
    }

    if (selected && (percentageAnswered as number) > 20) {
      return colors.grey200
    }

    return colors.white
  }

  const getButtonBorderColor = () => {
    if (showLoadingAnimation) {
      return colors.black
    }

    if (selected && (percentageAnswered as number) <= 20) {
      return colors.grey200
    }

    if (selected && (percentageAnswered as number) > 20) {
      return colors.grey200
    }

    return colors.black
  }

  const getButtonBorder = () => {
    if (!selected) {
      return 'none'
    }

    if (selected && (percentageAnswered as number) > 50) {
      return 'none'
    }

    return `2px solid ${getButtonBorderColor()}`
  }

  const grow = keyframes`
  from {
    width: 0%;
  }
  to {
    width: ${percentageAnswered !== undefined ? percentageAnswered : 0}%;
  }
  `
  const pulse = keyframes`
  0% {

    opacity: 1;
  }
  
  50% {

    opacity: 0.4;
  }
  
  100% {

    opacity: 0.9;
  }
  
  `

  const buttonColorAnimation = keyframes`
  from {
    background-color: ${colors.white};
  }
  to {
    background-color: ${getButtonBackgroundColor()};
  }
  `

  return (
    <button
      onClick={() => {
        onClick()
        setLocalSelected(true)
      }}
      css={[
        buttonReset,
        css`
          position: relative;
          margin-bottom: 8px;
          border: ${getButtonBorder()};
          min-height: 48px;
          z-index: 0;
          border-radius: 8px;
          overflow: hidden;
          background-color: ${colors.white};
          ${showLoadingAnimation
            ? css`
                animation: ${pulse} 1000ms ${pulseCurve} infinite;
              `
            : css`
                animation: ${buttonColorAnimation} 350ms ease-in-out;
                animation-delay: ${selected ? '0ms' : '600ms'};
                animation-fill-mode: forwards;
              `}
        `,
      ]}
      className={className}
      disabled={disabled || selected !== undefined}
    >
      {showGlobalStatsLocal && (
        <div
          css={css`
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            background-color: ${colors.blackOp16};
            z-index: 1;
            border-radius: ${selected ? '6px 0 0 6px' : '8px 0 0 8px'};
            animation: ${grow} 600ms ${fcCurve};
            animation-delay: 1200ms;
            animation-fill-mode: forwards;
          `}
        />
      )}

      <Flex
        vertical="center"
        horizontal="space-between"
        css={[
          css`
            position: relative;
            padding: 0px 16px;
            z-index: 20;
          `,
        ]}
      >
        <Text
          className="alternative-text"
          css={css`
            line-height: 18px;
            color: ${colors.black};
          `}
        >
          {children}
        </Text>
        <div
          css={css`
            width: 68px;
            min-width: 68px;
            margin-left: 8px;
          `}
        >
          <Flex vertical="center" horizontal="flex-end" gap="small">
            {showGlobalStatsLocal && (
              <Text
                css={css`
                  margin-bottom: 2px;
                  opacity: 0;
                  animation: ${fadeIn} 600ms ease-out;
                  animation-delay: 1200ms;
                  animation-fill-mode: forwards;
                  text-align: right;
                `}
              >{`${percentageAnswered?.toFixed(0)}%`}</Text>
            )}
            {selected && (
              <Flex
                vertical={'center'}
                css={css`
                  height: 100%;
                  opacity: 0;
                  animation: ${fadeIn} 600ms ease-out;
                  animation-delay: ${selected ? '0ms' : '600ms'};
                  animation-fill-mode: forwards;
                `}
              >
                {(percentageAnswered as number) > 50 && (
                  <div
                    css={css`
                      flex-shrink: 0;

                      width: 12px;
                    `}
                  >
                    ⭐️
                  </div>
                )}

                {(percentageAnswered as number) <= 20 && (
                  <div
                    css={css`
                      flex-shrink: 0;
                      width: 12px;
                    `}
                  >
                    🔥
                  </div>
                )}

                {selected &&
                  (percentageAnswered as number) > 20 &&
                  (percentageAnswered as number) < 50 && (
                    <div
                      css={css`
                        flex-shrink: 0;
                        width: 12px;
                      `}
                    >
                      🧐
                    </div>
                  )}
              </Flex>
            )}
          </Flex>
        </div>
      </Flex>
    </button>
  )
}
