/** @jsxImportSource @emotion/react */
import { useApolloClient } from '@apollo/client'
import { css } from '@emotion/react'
import { capitalize, findLastIndex, sortBy } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { BiShare } from 'react-icons/bi'
import { FaShareSquare } from 'react-icons/fa'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Button, PrimaryButton, SecondaryButton } from '../../components/Button'
import { Chip } from '../../components/Chip'
import { ClubGridElement } from '../../components/ClubGridElement'
import { DefaultError } from '../../components/DefaultError'
import { EditLeagueToggle } from '../../components/EditLeagueToggle'
import { Heading } from '../../components/Heading'
import { HeadingNew } from '../../components/HeadingNew'
import { Loader } from '../../components/Loader'
import { ClubLogo, FramedClubLogo } from '../../components/Picture'
import { ScrollBoxFade } from '../../components/ScrollBoxFade'
import { SplashImage } from '../../components/SplashImage'
import { Text } from '../../components/Text'
import { TextNew } from '../../components/TextNew'
import { ProfilePageIcon } from '../../components/icons'
import { Flex } from '../../components/layout/Flex'
import { Layout } from '../../components/layout/Layout'
import { PageContent } from '../../components/layout/PageContent'
import { LoginModalInner } from '../../components/modal/LoginModalInner'
import { Modal, ModalTrigger } from '../../components/modal/Modal'
import { isDevelopment } from '../../config/config'
import { useAuthContext, useViewerQuery } from '../../lib/AuthContext'
import { AnalyticsEventType, trackEvent } from '../../lib/analytics'
import { apolloClient } from '../../lib/apollo/apollo'
import { TFunction, useT } from '../../lib/i18n/useT'
import { LeagueQuizStatus } from '../../lib/types'
import { margin, Spacer } from '../../styles/margin'
import { buttonReset } from '../../styles/styles'
import { boxShadows, colors, hexOpacity } from '../../styles/theme'
import { useFormatDate } from '../../utils/date'
import { getIconAndTitle } from '../../utils/helperFunctions'
import { useJoinClubMutation } from '../Clubs/queries'
import { LeagueItem } from '../Home/LeagueItem'
import { LeagueItemV2 } from '../Home/LeagueItemV2'
import { LeaguesContainer } from '../Home/LeaguesContainer'
import { BadgeBanner } from '../Home/SpecialQuiz'
import { HomePageLeague } from '../Home/queries'
import {
  userClubMembershipsQuery,
  useUserClubMembershipsQuery,
} from '../Profile/queries'
import { EditLeagueModal } from './Edit/EditLeagueModal'
import { EditLiveQuizzesModal } from './EditLiveQuizzesModal'
import { FollowTheTeams } from './FollowTheTeams'
import { JoinTheTournament } from './JoinTheTournament'
import { LeaderboardV2 } from './Leaderboard/LeaderboardV2'
import WaitingForResults from './Leaderboard/WaitingForResults'
import { LeagueBackground } from './LeagueBackground'
import { LeagueQuizItem } from './LeagueQuizItem'
import { LeagueQuizItemV2 } from './LeagueQuizItemV2'
import { LeagueQuizSelector } from './LeagueQuizSelector'
import { MatchLeagueTopContent } from './MatchLeagueTopContent'
import { TotalScoresItem } from './TotalScoreItem'
import { TotalScoresItemV2 } from './TotalScoreItemV2'
import { TournamentTopContent } from './TournamentTopContent'
import { TournamentTopContentV2 } from './TournamentTopContentV2'
import {
  League,
  LeagueFrequency,
  LeagueQuizMinimal,
  RewardFrequency,
  useAllCompletedLeagueQuizzesQuery,
  useLeagueAllLiveQuizzesQuery,
  useLeaguePageQuery,
  useSubscribeToLeague,
  useUnsubscribeFromLeague,
} from './queries'

export type ViewerLeagueRole = 'viewer' | 'owner' | 'translater' | 'fcQuizAdmin'

export function leagueFrequencyToString(
  t: TFunction,
  frequency: LeagueFrequency
): string {
  switch (frequency) {
    case LeagueFrequency.daily:
      return t('Daily')
    case LeagueFrequency.weekly:
      return t('Weekly')
    case LeagueFrequency.monthly:
      return t('Monthly')
  }
}

export function rewardFrequencyToString(
  t: TFunction,
  frequency: RewardFrequency
): string {
  switch (frequency) {
    case RewardFrequency.once:
      return t('Once')
    case RewardFrequency.weekly:
      return t('Weekly')
    case RewardFrequency.monthly:
      return t('Monthly')
    case RewardFrequency.yearly:
      return t('Yearly')
  }
}
export const LEAGUE_QUIZ_ITEM_WIDTH = 234

export const leagueQuizItemStyle = css`
  flex-shrink: 0;
  padding: 12px;
  border-radius: 2px;
  width: ${LEAGUE_QUIZ_ITEM_WIDTH}px;
  min-height: 100%;
  background-color: white;
`

export const leagueQuizItemStyleV2 = css`
  padding: 12px;
  border-radius: 15px;
  min-width: 220px;
  max-width: 220px;
  min-height: 100%;
  background-color: white;
  box-shadow: ${boxShadows.leagueQuizItem};
`

const LoadAllCompletedQuizzesButton = ({
  loading,
  onLoadAll,
}: {
  loading: boolean
  onLoadAll: () => void
}) => {
  const t = useT()

  return (
    <Flex
      column
      css={[
        leagueQuizItemStyle,
        css`
          background-color: transparent;
        `,
      ]}
      vertical="center"
      horizontal="flex-end"
    >
      <div>
        <PrimaryButton
          size="small"
          onClick={() => {
            onLoadAll()
          }}
          loading={loading}
        >
          {t('See all')}
        </PrimaryButton>
      </div>
    </Flex>
  )
}

const MakeQuizButton = () => {
  const leagueSlug = useParams<{ slug: string }>().slug!
  const navigate = useNavigate()
  return (
    <Flex
      css={css`
        height: 100%;
        width: 100%;
        max-height: 210px;
        max-width: 210px;
      `}
      horizontal="center"
      vertical="center"
      column
      gap="small"
      onClick={() => {
        navigate(`/create/${leagueSlug}`)
      }}
    >
      <Flex
        css={css`
          height: 50%;
          width: 50%;
          background-color: ${colors.green300};
          border-radius: 10px;
        `}
        horizontal="center"
        vertical="center"
      >
        <img
          src="/plus.svg"
          css={css`
            filter: invert(1);
            width: 80%;
          `}
        ></img>
      </Flex>
      <Text color={colors.grey100}>Add quiz</Text>
    </Flex>
  )
}

const Content = ({
  league,
  quizId: initalSelectedQuizId,
  v2Design,
  editMode,
}: {
  league: League
  quizId?: string
  v2Design: boolean
  editMode: boolean
}) => {
  const [fetchAllCompletedLeagueQuizzes, allCompletedLeagueQuizzes] =
    useAllCompletedLeagueQuizzesQuery()
  const { authUser } = useAuthContext()
  const authenticated = Boolean(authUser)
  const viewerQuery = useViewerQuery({ skip: !authenticated })

  const [editModeEnabled, setEditModeEnabled] = useState(editMode)

  const shouldUseV2Design = v2Design && !editModeEnabled

  const viewerIsLeagueOwner = league.viewerIsOwner
  const viewerIsTranslater = viewerQuery.data?.viewer?.isTranslater
  const viewerIsAdmin = viewerQuery.data?.viewer?.isAdmin

  const hasEditPrivileges = league.viewerCanEdit === true

  const viewerLeagueRole: ViewerLeagueRole = viewerIsAdmin
    ? 'fcQuizAdmin'
    : viewerIsTranslater
    ? 'translater'
    : viewerIsLeagueOwner
    ? 'owner'
    : 'viewer'

  const liveQuizzesQuery = useLeagueAllLiveQuizzesQuery(
    league.slug,
    !hasEditPrivileges // dont fetch live quizes if we dont have edit privileges
  )

  const liveQuizzes = liveQuizzesQuery.data?.league?.liveQuizzes ?? []

  const allCompletedQuizzes = allCompletedLeagueQuizzes.data?.league.quizzes
  const sortedQuizzes = useMemo(() => {
    const quizzes = [
      ...(allCompletedQuizzes ?? league.lastCompletedQuizzes),
      ...league.activeQuizzes,
    ]
    const sortFilteredQuizes = sortBy(
      quizzes.filter((quiz) =>
        hasEditPrivileges || league.match
          ? true
          : quiz.status !== LeagueQuizStatus.upcoming
      ),
      (quiz) => quiz.availableFrom
    )
    return sortFilteredQuizes
  }, [
    allCompletedQuizzes,
    league.activeQuizzes,
    league.lastCompletedQuizzes,
    hasEditPrivileges,
  ])

  const showLoadAllCompletedQuizzesButton =
    !allCompletedQuizzes && league.lastCompletedQuizzes.length === 3

  const viewer = viewerQuery.data?.viewer
  const promptClubJoin =
    viewer && league.showClubsLeaderboard && league.promotedClubs
  const query = useUserClubMembershipsQuery(viewer?.slug ?? '', !promptClubJoin)
  const [showClubJoinModal, setShowClubJoinModal] = useState(false)
  useEffect(() => {
    if (promptClubJoin && query.data && !query.loading) {
      const myClubs = query.data
      const promotedClubs = league.promotedClubs!
      const alreadyInClub = promotedClubs.find((promotedClub) =>
        myClubs.find((myClub) => myClub.id == promotedClub.id)
      )
      if (alreadyInClub) {
        return
      }
      const hasClosedInThisLeague =
        window.localStorage.getItem(`hideClubPrompt|${league.id}`) === 'true'
      if (hasClosedInThisLeague) {
        return
      }
      setShowClubJoinModal(true)
    }
  }, [query.data])

  useEffect(() => {
    if (allCompletedQuizzes) {
      setSelectedIndex(0)
    }
  }, [allCompletedQuizzes])

  const items = useMemo(() => {
    const items: Array<{
      type: string
      quiz: LeagueQuizMinimal | undefined
    }> = [
      ...sortedQuizzes.map((quiz) => ({
        type: 'quiz',
        quiz: quiz,
      })),
    ]

    if (!league.match) {
      items.push({
        type: 'total',
        quiz: undefined,
      })
    }

    if (showLoadAllCompletedQuizzesButton) {
      items.unshift({
        type: 'loadallbutton',
        quiz: undefined,
      })
    }

    if (hasEditPrivileges && editModeEnabled) {
      items.push({
        type: 'makequiz',
        quiz: undefined,
      })
    }

    return items
  }, [
    sortedQuizzes,
    showLoadAllCompletedQuizzesButton,
    editModeEnabled,
    hasEditPrivileges,
  ])

  const [selectedIndex, setSelectedIndex] = useState(() => {
    let overrideIndex: number | undefined = undefined

    // If a quizId is provided, select that quiz
    if (initalSelectedQuizId) {
      const quizIndex = items.findIndex(
        (item) =>
          item.quiz?.id === initalSelectedQuizId ||
          item.quiz?.slug === initalSelectedQuizId
      )
      overrideIndex = quizIndex !== -1 ? quizIndex : undefined

      if (initalSelectedQuizId === 'total') {
        overrideIndex = items.length - 1
      }
    }

    if (overrideIndex) {
      return overrideIndex
    }

    // If there is an active quiz, select that
    const initialIndex = findLastIndex(
      items,
      (item) =>
        item.quiz?.viewerCanStartQuiz ||
        Boolean(item.quiz?.viewerData.activeQuizInstance)
    )

    return initialIndex !== -1 ? initialIndex : items.length - 1
  })

  // Happens if exiting admin tools
  useEffect(() => {
    if (
      selectedIndex >= sortedQuizzes.length &&
      initalSelectedQuizId != 'total'
    ) {
      setSelectedIndex(sortedQuizzes.length)
    }
  }, [sortedQuizzes])

  const selectedLeagueQuizId = items[selectedIndex]?.quiz?.slug
  const isPredictionQuiz = items[selectedIndex]?.quiz?.quizType === 'prediction'

  const t = useT()
  const [subscribeToLeague] = useSubscribeToLeague()
  const [unsubscribeFromLeague] = useUnsubscribeFromLeague()
  const client = useApolloClient()
  const [copied, setCopied] = useState(false)

  const shareLeague = async () => {
    trackEvent(AnalyticsEventType.ShareLeagueClicked)

    const leagueLink = `${window.location.origin}/series/${league.slug}`

    const shareText = `⚽️ fcQuiz – ${league.title} ⚽️ \n\n${leagueLink}`

    if (window.navigator.share) {
      window.navigator.share({ text: shareText }).catch(() => {
        // ignore error
      })
    } else {
      navigator.clipboard.writeText(leagueLink)
      setCopied(true)
      setTimeout(() => {
        setCopied(false)
      }, 3000)
    }
  }

  const formatDate = useFormatDate()

  const availableFromTo =
    league.availableTo &&
    `${formatDate(new Date(league.availableFrom), 'full-date')} - ${formatDate(
      new Date(league.availableTo),
      'full-date'
    )}`

  const [selectedClubIds, setSelectedClubIds] = useState<string[]>([])
  const [joinClubMutation] = useJoinClubMutation()

  const [linkedLeagues, setLinkedLeagues] = useState<HomePageLeague[]>([])

  useEffect(() => {
    if (!league || !league.linkedLeagues) return

    const filteredLeagues = league.linkedLeagues.filter((linkedLeague) => {
      if (!linkedLeague || !linkedLeague.quizzes) return false

      return linkedLeague.quizzes.some(
        (quiz) => quiz && quiz.viewerCanStartQuiz
      )
    })

    setLinkedLeagues(filteredLeagues)
  }, [league])

  const goToTotal = () => {
    setSelectedIndex(items.length - 1)
  }

  const { title } = getIconAndTitle(league.title)

  const badge = league.badges?.[0]

  const FollowButton = () => {
    if (league.viewerIsSubscribed) {
      return (
        <ModalTrigger
          button={({ openModal }) => (
            <SecondaryButton variant="white" onClick={openModal}>
              {t('Following')}
            </SecondaryButton>
          )}
          modal={({ closeModal }) => (
            <Modal
              heading={t('Stop following')}
              closeModal={closeModal}
              maxWidth={500}
            >
              <Flex column>
                <Text>
                  {t(
                    'If you stop following this series, you will not receive notifications about new quizzes.'
                  )}
                </Text>
                <Text css={margin.top('small')}>
                  {t('Are you sure you want to stop following?')}
                </Text>

                <Flex
                  horizontal="flex-end"
                  vertical="center"
                  css={margin.top('large')}
                  gap="medium"
                >
                  <button css={buttonReset} onClick={closeModal}>
                    <Text underline>{t('Cancel')}</Text>
                  </button>
                  <PrimaryButton
                    onClick={() => {
                      unsubscribeFromLeague({
                        variables: { leagueId: league.id },
                      }).then(closeModal)
                    }}
                  >
                    {t('Yes, stop following')}
                  </PrimaryButton>
                </Flex>
              </Flex>
            </Modal>
          )}
        />
      )
    }

    return (
      <ModalTrigger
        button={({ openModal }) => (
          <PrimaryButton
            variant="white"
            onClick={() => {
              if (!authUser || authUser?.isAnonymous) {
                openModal()
                return
              }

              subscribeToLeague({
                variables: { leagueId: league.id },
              }).then(() => client.refetchQueries({ include: 'active' }))
            }}
          >
            {t('Follow')}
          </PrimaryButton>
        )}
        modal={({ closeModal }) => <LoginModalInner closeModal={closeModal} />}
      />
    )
  }

  const FollowButtonV2 = () => {
    if (league.viewerIsSubscribed) {
      return (
        <ModalTrigger
          button={({ openModal }) => (
            <SecondaryButton
              variant="white"
              onClick={openModal}
              css={css`
                border-radius: 40px;
                height: 40px;
                padding: 0 20px;
                min-width: 93px;
              `}
            >
              {t('Following')}
            </SecondaryButton>
          )}
          modal={({ closeModal }) => (
            <Modal
              heading={t('Stop following')}
              closeModal={closeModal}
              maxWidth={500}
            >
              <Flex column>
                <Text>
                  {t(
                    'If you stop following this series, you will not receive notifications about new quizzes.'
                  )}
                </Text>
                <Text css={margin.top('small')}>
                  {t('Are you sure you want to stop following?')}
                </Text>

                <Flex
                  horizontal="flex-end"
                  vertical="center"
                  css={margin.top('large')}
                  gap="medium"
                >
                  <button css={buttonReset} onClick={closeModal}>
                    <Text underline>{t('Cancel')}</Text>
                  </button>
                  <PrimaryButton
                    onClick={() => {
                      unsubscribeFromLeague({
                        variables: { leagueId: league.id },
                      }).then(closeModal)
                    }}
                  >
                    {t('Yes, stop following')}
                  </PrimaryButton>
                </Flex>
              </Flex>
            </Modal>
          )}
        />
      )
    }

    return (
      <ModalTrigger
        button={({ openModal }) => (
          <PrimaryButton
            variant="white"
            css={css`
              border-radius: 40px;
              height: 40px;
              padding: 0 20px;
              min-width: 93px;
            `}
            onClick={() => {
              if (!authUser || authUser?.isAnonymous) {
                openModal()
                return
              }

              subscribeToLeague({
                variables: { leagueId: league.id },
              }).then(() => client.refetchQueries({ include: 'active' }))
            }}
          >
            <TextNew
              weight={700}
              size={16}
              css={css`
                color: ${colors.black};
              `}
            >
              {t('Follow')}
            </TextNew>
          </PrimaryButton>
        )}
        modal={({ closeModal }) => <LoginModalInner closeModal={closeModal} />}
      />
    )
  }

  const selectedQuiz = items[selectedIndex]

  return (
    <Flex column grow>
      {showClubJoinModal && (
        <Modal
          closeModal={() => {
            if (
              confirm(t(`Are you sure you don't want to support any clubs?`))
            ) {
              setShowClubJoinModal(false)
              window.localStorage.setItem(`hideClubPrompt|${league.id}`, 'true')
            }
          }}
          css={css`
            #modalcontent {
              background: #f3f3f3 !important;
            }
          `}
          heading="Join club"
          hideHeading
        >
          <Flex
            css={css`
              max-height: 70vh;
              padding: 0px 10px;
            `}
            column
            gap="small"
          >
            <Flex vertical="center" gap="small">
              <div
                css={css`
                  background-image: url(${league.leagueCardImageUrl});
                  height: 24px;
                  width: 24px;
                  border-radius: 999px;
                  background-size: cover;
                  background-position: center center;
                  background-repeat: no-repeat;
                `}
              />
              <Text
                css={css`
                  font-weight: 300;
                `}
                color={colors.grey400}
                size="tiny"
              >
                {league.title
                  .split(' ')
                  .map((it) => capitalize(it))
                  .join(' ')}
              </Text>
            </Flex>
            <Heading italic level={1} looksLikeLevel={4}>
              {t(`Join a club`)}
            </Heading>
            <Spacer height={8} />
            <ScrollBoxFade maxHeight="50vh">
              <Flex
                column
                gap="small"
                css={css`
                  overflow-y: auto;
                `}
              >
                {league.promotedClubs?.map((it) => (
                  <Button
                    key={it.id}
                    resetStyling
                    onClick={() => {
                      if (selectedClubIds.includes(it.id)) {
                        setSelectedClubIds(
                          selectedClubIds.filter((clubId) => clubId !== it.id)
                        )
                      } else {
                        setSelectedClubIds([...selectedClubIds, it.id])
                      }
                    }}
                  >
                    <Flex
                      css={css`
                        border-radius: 7px;
                        border: 1px solid #d5d5d5;

                        padding: 10px 20px;

                        background-color: ${selectedClubIds.includes(it.id)
                          ? 'black'
                          : 'white'};
                      `}
                      vertical="center"
                      gap="small"
                      horizontal="space-between"
                    >
                      <Flex vertical="center" gap="small">
                        <ClubLogo
                          size={40}
                          url={it.logoUrl}
                          shape={it.logoShape}
                        />
                        <Text
                          size={'large'}
                          css={css`
                            font-weight: 300;
                          `}
                          color={
                            selectedClubIds.includes(it.id) ? 'white' : 'black'
                          }
                        >
                          {it.name}
                        </Text>
                      </Flex>
                      {selectedClubIds.includes(it.id) && (
                        <img src={'/Checkmark.svg'} />
                      )}
                      {!selectedClubIds.includes(it.id) && (
                        <img src={'/Plus3.svg'} />
                      )}
                    </Flex>
                  </Button>
                ))}
                <SecondaryButton
                  onClick={() => {
                    if (
                      confirm(
                        t(`Are you sure you don't want to support any clubs?`)
                      )
                    ) {
                      setShowClubJoinModal(false)
                      window.localStorage.setItem(
                        `hideClubPrompt|${league.id}`,
                        'true'
                      )
                    }
                  }}
                  variant="black"
                  css={css`
                    height: 50px;
                  `}
                >
                  {t(`I support another club`)}
                </SecondaryButton>
              </Flex>
            </ScrollBoxFade>
            <Spacer height={8} />
            <Button
              variant="lightGreen"
              css={css`
                padding: 20px;
              `}
              disabled={selectedClubIds.length === 0}
              onClick={async () => {
                setShowClubJoinModal(false)
                for (const id of selectedClubIds) {
                  await joinClubMutation({
                    variables: { input: { clubId: id! } },
                  }).then(() => {
                    trackEvent(AnalyticsEventType.ClubJoined, {
                      clubId: id,
                      type: 'series-page-modal',
                    })
                  })
                }
                apolloClient.refetchQueries({
                  include: [userClubMembershipsQuery],
                })
              }}
            >
              <Text size={'large'}>{t(`Join`)}</Text>
            </Button>
          </Flex>
        </Modal>
      )}
      <PageContent>
        {hasEditPrivileges && (
          <Flex column horizontal="flex-end" gap="medium">
            <EditLeagueToggle
              editEnabled={editModeEnabled}
              setEditEnabled={setEditModeEnabled}
            />

            {editModeEnabled && liveQuizzes.length > 0 && (
              <ModalTrigger
                button={({ openModal }) => (
                  <SecondaryButton onClick={openModal} noWrap variant="white">
                    <TextNew color={colors.white}>{t('Live quizzes')}</TextNew>
                  </SecondaryButton>
                )}
                modal={({ closeModal }) => (
                  <EditLiveQuizzesModal
                    leagueId={league.id}
                    closeModal={closeModal}
                    quizzes={liveQuizzes}
                  />
                )}
              />
            )}

            {editModeEnabled && league.ownerClub && (
              <ModalTrigger
                button={({ openModal }) => (
                  <PrimaryButton variant="white" onClick={openModal} noWrap>
                    {t(`Edit series information`)}
                  </PrimaryButton>
                )}
                modal={({ closeModal }) => (
                  <EditLeagueModal
                    closeModal={() => {
                      closeModal()
                      setEditModeEnabled(false)
                    }}
                    league={league}
                  />
                )}
              />
            )}
          </Flex>
        )}
      </PageContent>
      {!shouldUseV2Design && (
        <>
          {league.isTournament ? (
            shouldUseV2Design ? (
              <TournamentTopContentV2 league={league} />
            ) : (
              <TournamentTopContent league={league} />
            )
          ) : league.match ? (
            <MatchLeagueTopContent league={league} />
          ) : (
            <PageContent>
              <Flex column>
                <div
                  css={css`
                    margin-bottom: 16px;
                  `}
                >
                  <FollowButton />
                </div>
                <Text size="small" color="white">
                  {league.availableTo && availableFromTo}
                </Text>
                <Heading level={2} looksLikeLevel={3} color="white">
                  {league.title}
                </Heading>

                {league.frequency && (
                  <Flex gap="small" css={margin.top('small')}>
                    <Chip
                      label={leagueFrequencyToString(t, league.frequency)}
                      backgroundColor="black"
                      color="white"
                    />
                    {league.isRewarded && isDevelopment && (
                      <ModalTrigger
                        button={({ openModal }) => (
                          <Chip
                            label={t('Rewarded')}
                            backgroundColor="black"
                            color="white"
                            clickable={true}
                            onClick={openModal}
                          />
                        )}
                        modal={({ closeModal }) => (
                          <Modal
                            heading={t('Rewards') + ': ' + league.title}
                            closeModal={closeModal}
                            maxWidth={500}
                          >
                            {league.reward && (
                              <Flex column>
                                <Text>{league.reward.rewardDescription}</Text>
                                {league.reward.rewardFrequency != 'once' && (
                                  <Text css={margin.top('medium')}>
                                    {league.reward.numberOfWinners > 1
                                      ? t(
                                          '{{numberOfWinners}} winners are selected every {{rewardWinnersInterval}}',
                                          {
                                            numberOfWinners:
                                              league.reward.numberOfWinners,
                                            rewardWinnersInterval:
                                              rewardFrequencyToString(
                                                t,
                                                league.reward.rewardFrequency
                                              ).toLowerCase(),
                                          }
                                        )
                                      : t(
                                          'A winner is selected every {{rewardWinnersInterval}}',
                                          {
                                            rewardWinnersInterval:
                                              rewardFrequencyToString(
                                                t,
                                                league.reward.rewardFrequency
                                              ).toLowerCase(),
                                          }
                                        )}
                                  </Text>
                                )}
                              </Flex>
                            )}
                          </Modal>
                        )}
                      />
                    )}
                  </Flex>
                )}

                {league.description && (
                  <Text color="white" size="small" css={margin.vertical()}>
                    {league.description}
                  </Text>
                )}

                <Flex css={[margin.vertical()]} gap="medium">
                  <SecondaryButton variant="white" onClick={shareLeague}>
                    <Flex vertical="center" gap="medium">
                      <FaShareSquare color="white" size={20} />
                      <span
                        css={css`
                          position: relative;
                          top: 2px;
                          color: ${colors.white};
                        `}
                      >
                        {copied ? t('Copied!') : t('Share')}
                      </span>
                    </Flex>
                  </SecondaryButton>
                </Flex>
              </Flex>
            </PageContent>
          )}
        </>
      )}

      {shouldUseV2Design && (
        <Flex
          column
          horizontal="center"
          css={css`
            background-color: ${hexOpacity(colors.black, 0.6)};
            border-radius: 6px;
            width: 390px;
            margin: 40px auto;
          `}
        >
          <Spacer height={40} />
          <HeadingNew
            level={1}
            color={colors.white}
            textAlign="center"
            weight={800}
            size={40}
            css={css`
              padding: 0 100px;
              text-transform: uppercase;
            `}
          >
            {title}
          </HeadingNew>
          <Flex horizontal="center" vertical="center" gap={'small'}>
            <ProfilePageIcon color={colors.white} height={18} />
            <TextNew color={colors.white} weight={700} size={18} italic>
              {t('{{count}} player', {
                count: league.participantCount,
              })}
            </TextNew>
          </Flex>
          <Spacer height={'large'} />
          <Flex horizontal="center" vertical="center" gap={'medium'}>
            <FollowButtonV2 />
            <PrimaryButton
              variant="white"
              onClick={shareLeague}
              css={css`
                border-radius: 40px;
                height: 40px;
                padding: 0 20px;
                min-width: 93px;
              `}
            >
              <Flex vertical="center" horizontal="center" gap="small">
                <TextNew
                  weight={700}
                  size={16}
                  css={css`
                    color: ${colors.black};
                  `}
                >
                  {copied ? t('Copied!') : t('Share')}
                </TextNew>
                <BiShare
                  color={colors.black}
                  size={20}
                  css={css`
                    position: relative;
                    top: 1px;
                    transform: scaleX(-1);
                  `}
                />
              </Flex>
            </PrimaryButton>
          </Flex>
          <Spacer height={'large'} />

          {badge && (
            <BadgeBanner
              badge={badge}
              numQuizzesPlayed={league.quizzesPlayed}
              earnedBadge={league.viewerEarnedBadges.find(
                (earnedBadge) => earnedBadge.id === badge.id
              )}
            />
          )}
          <Spacer height={'large'} />
        </Flex>
      )}

      {shouldUseV2Design && league.description && (
        <PageContent horizontal={'center'} css={margin.bottom('large')}>
          <TextNew
            textAlign="center"
            condensed
            italic
            spacingEm={0}
            css={css`
              font-size: 40px;
              text-overflow: ellipsis;
              max-width: 100%;
            `}
            color={'white'}
          >
            {league.description}
          </TextNew>
        </PageContent>
      )}

      <LeagueQuizSelector
        items={items.map((item) => {
          if (item.type === 'quiz') {
            if (!item.quiz) {
              return null
            }

            if (shouldUseV2Design && !editModeEnabled) {
              return (
                <LeagueQuizItemV2
                  key={item.quiz.id}
                  quiz={item.quiz}
                  leagueId={league.id}
                  leagueTitle={title}
                />
              )
            }

            return (
              <LeagueQuizItem
                key={item.quiz.id}
                quiz={item.quiz}
                leagueId={league.id}
                editModeEnabled={editModeEnabled}
                viewerLeagueRole={viewerLeagueRole}
              />
            )
          } else if (item.type === 'total') {
            if (shouldUseV2Design) {
              return <TotalScoresItemV2 key="league" league={league} />
            }

            return <TotalScoresItem key="league" league={league} />
          } else if (item.type === 'loadallbutton') {
            return (
              <LoadAllCompletedQuizzesButton
                key="loadall"
                onLoadAll={() => {
                  fetchAllCompletedLeagueQuizzes({
                    variables: { slug: league.slug },
                  })
                }}
                loading={allCompletedLeagueQuizzes.loading}
              />
            )
          } else if (item.type == 'makequiz') {
            return <MakeQuizButton />
          }
        })}
        selectedIndex={selectedIndex}
        onSelectIndex={setSelectedIndex}
        itemWidth={shouldUseV2Design ? 220 : LEAGUE_QUIZ_ITEM_WIDTH}
        itemSpacing={0}
        css={css`
          margin-bottom: 32px;
        `}
      />

      {league.ownerClub && (
        <PageContent
          horizontal="center"
          css={[margin.vertical('large')]}
          gap={'small'}
        >
          <TextNew
            textAlign="center"
            condensed
            italic
            spacingEm={0}
            css={css`
              font-size: 40px;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: 100%;
            `}
            color={'white'}
          >
            {t(`Created by`)}
          </TextNew>
          <ClubGridElement club={league.ownerClub} />
        </PageContent>
      )}

      {linkedLeagues.length > 0 && (
        <Flex
          column
          css={[
            margin.bottom('huge'),
            css`
              align-items: ${league.isTournament ? 'center' : 'flex-start'};
              text-align: center;
            `,
          ]}
        >
          <PageContent>
            {shouldUseV2Design ? (
              <TextNew
                textAlign="center"
                condensed
                italic
                spacingEm={0}
                css={css`
                  font-size: 40px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  max-width: 100%;
                `}
                color={'white'}
              >
                {t('Related quizzes')}
              </TextNew>
            ) : (
              <Text
                color="white"
                size="small"
                css={css`
                  text-transform: uppercase;
                `}
              >
                {t('Related quizzes')}
              </Text>
            )}
          </PageContent>
          <Flex
            css={css`
              overflow-x: scroll;
              max-width: ${innerWidth}px;

              &::-webkit-scrollbar {
                display: none;
              }
              -ms-overflow-style: none; /* IE and Edge */
              scrollbar-width: none; /* Firefox */
            `}
          >
            <LeaguesContainer>
              {linkedLeagues.map((league) => (
                <div
                  key={league.id}
                  css={css`
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                  `}
                >
                  {shouldUseV2Design ? (
                    <LeagueItemV2 key={league.id} league={league} />
                  ) : (
                    <LeagueItem key={league.id} league={league} />
                  )}
                </div>
              ))}
            </LeaguesContainer>
          </Flex>
        </Flex>
      )}

      {league.isTournament &&
        !!league.leagueCollections?.length &&
        league.leagueCollections.map((collection) => (
          <Flex column css={[margin.bottom('huge')]}>
            <PageContent>
              <Text
                color="white"
                size="small"
                css={css`
                  text-transform: uppercase;
                `}
              >
                {collection.title}
              </Text>
            </PageContent>
            <LeaguesContainer>
              {collection.leagues.map((league) => (
                <LeagueItem
                  key={league.id}
                  league={league}
                  display={collection.display}
                />
              ))}
            </LeaguesContainer>
          </Flex>
        ))}

      {league.isTournament &&
        !!league.promotedClubs?.length &&
        !shouldUseV2Design && (
          <Flex column css={[margin.bottom('huge')]}>
            <Spacer height="large" />
            <PageContent>
              <Text
                color="white"
                size="small"
                css={css`
                  text-transform: uppercase;
                `}
              >
                {t('Popular clubs')}
              </Text>
            </PageContent>

            <LeaguesContainer>
              {league.promotedClubs.map((club) => {
                return (
                  <Link
                    to={`/clubs/${club.id}`}
                    key={club.id}
                    css={css`
                      text-decoration: none;
                    `}
                  >
                    <Flex
                      column
                      horizontal="center"
                      vertical="space-between"
                      css={css`
                        background-color: #10253e;
                        min-width: 240px;
                        max-width: 240px;
                        padding: 16px;
                        padding-bottom: 20px;
                        border-radius: 8px;
                        height: 100%;
                      `}
                    >
                      <Flex column horizontal="center">
                        <Text color="white" size="tiny">
                          {t('{{count}} member', { count: club.membersCount })}
                        </Text>

                        <Text
                          color="white"
                          wide
                          css={css`
                            text-transform: uppercase;
                            text-align: center;
                            text-decoration: none;
                            font-size: 21px;
                            line-height: 25px;
                            font-weight: 800;
                            ${margin.vertical('small', 'medium')};
                          `}
                        >
                          {club.name}
                        </Text>
                      </Flex>

                      {club.logoUrl && club.logoShape && (
                        <FramedClubLogo
                          size={50}
                          url={club.logoUrl}
                          shape={club.logoShape}
                        />
                      )}
                    </Flex>
                  </Link>
                )
              })}
            </LeaguesContainer>
          </Flex>
        )}

      {league.match ? (
        <>
          <FollowTheTeams league={league} css={margin.bottom('large')} />
          {league.slug === 'the-madrid-derby' && <JoinTheTournament />}
        </>
      ) : (
        <PageContent>
          {(selectedQuiz?.quiz?.isMissingAnswers ?? false) &&
          isPredictionQuiz ? (
            <WaitingForResults
              availableTo={selectedQuiz?.quiz?.availableTo ?? ''}
              viewerHasCompletedQuiz={
                selectedQuiz?.quiz?.viewerData.hasCompletedQuiz ?? false
              }
              instanceId={
                selectedQuiz?.quiz?.viewerData.result?.leagueQuizInstanceId
              }
            />
          ) : (
            <LeaderboardV2
              league={league}
              selectedLeagueQuizId={selectedLeagueQuizId}
              goToTotal={goToTotal}
            />
          )}
        </PageContent>
      )}
    </Flex>
  )
}

const LoadContent = ({
  leagueSlug,
  quizId,
  editMode = false,
}: {
  leagueSlug: string
  quizId?: string
  editMode?: boolean
}) => {
  const query = useLeaguePageQuery(leagueSlug)

  useEffect(() => {
    if (query.data?.league) {
      document.title = `fcQuiz | ${query.data.league.title}`

      // replace id with slug if needed
      if (query.data.league.slug) {
        if (window.location.pathname.includes(query.data.league.id)) {
          window.history.replaceState(
            {},
            '',
            `/series/${query.data.league.slug}${window.location.search}`
          )
        }
      }
    }
  }, [query.loading, query.data?.league])

  if (query.loading && !query.data) {
    return (
      <Flex horizontal="center" css={margin.top('huge')}>
        <Loader variant="yellow" />
      </Flex>
    )
  }

  if (!query.data?.league || query.error) {
    const reason = !query.data?.league
      ? 'no league'
      : query.error?.message
      ? query.error.message
      : 'unknown'

    return (
      <DefaultError
        css={margin.top('huge')}
        sentryErrorMessage={`LeaguePage | leagueSlug: ${leagueSlug}, ${reason}`}
      />
    )
  }

  const league: League = query.data.league

  const v2DesignLeagues = ['euro2024', 'premier-league', 'ai-league-2']

  const shouldUseV2Design =
    v2DesignLeagues.includes(league.slug) || league.ownerClub !== undefined

  return (
    <Flex
      column
      grow
      css={css`
        height: fit-content;
        overflow-x: hidden;
      `}
    >
      <LeagueBackground
        imageUrl={query.data.league.imageUrl}
        v2Design={shouldUseV2Design}
      />
      <Content
        league={league}
        quizId={quizId}
        v2Design={shouldUseV2Design}
        editMode={editMode}
      />
    </Flex>
  )
}

export const LeaguePage = () => {
  const leagueSlug = useParams<{ slug: string }>().slug!
  const quizId = useParams<{ quizId: string }>().quizId

  const searchParams = new URLSearchParams(window.location.search)
  const editMode = searchParams.get('editMode') === 'true'

  return (
    <Layout withHeader backgroundColor={'black'}>
      <SplashImage prefetch />
      <LoadContent
        leagueSlug={leagueSlug}
        quizId={quizId}
        editMode={editMode}
      />
    </Layout>
  )
}
