/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FaClock } from 'react-icons/fa6'
import { Flex } from '../../../components/layout/Flex'
import { TextNew } from '../../../components/TextNew'
import { useT } from '../../../lib/i18n/useT'
import { Spacer } from '../../../styles/margin'
import { colors } from '../../../styles/theme'
import { useNow } from '../../../utils/useNow'
import {
  calculateTimeRemaining,
  timeRemainingToString,
} from '../../Admin/Utils'
import { useLeagueQuizInstanceQuery } from '../../LeagueQuiz/queries'

export default function WaitingForResults({
  availableTo,
  viewerHasCompletedQuiz,
  instanceId,
}: {
  availableTo: string
  viewerHasCompletedQuiz: boolean
  instanceId?: string
}) {
  const t = useT()

  const now = useNow({ updateFrequencyInMs: 1000 })

  const hasExpired = new Date(availableTo) < now

  const timeToResults = calculateTimeRemaining(now, new Date(availableTo))

  return (
    <Flex
      column
      horizontal={'center'}
      vertical={'center'}
      css={css`
        width: 100%;
        padding: 20px 0;
      `}
    >
      {viewerHasCompletedQuiz ? (
        <>
          <TextNew
            color={colors.grey100}
            size="massive"
            strong
            condensed
            spacingEm={0}
          >
            {t(`We have received your predictions!`)}
          </TextNew>
          <Spacer height={'medium'} />

          {instanceId && <ViewerPredictions instanceId={instanceId} />}

          <Spacer height={'large'} />

          <Flex
            column
            horizontal={'center'}
            gap={'small'}
            css={css`
              width: 100%;
              background-color: ${colors.yellow200};
              padding: 20px;
              border-radius: 8px;
              border: 1px solid ${colors.grey300};
              max-width: 600px;
            `}
          >
            <FaClock color={colors.black} size={28} />
            <TextNew
              color={colors.black}
              size="large"
              strong
              condensed
              spacingEm={0}
              textAlign={'center'}
            >
              {hasExpired
                ? t(`You'll find out how you did soon!`)
                : t(`You'll find out how you did in about:`)}
            </TextNew>
            {!hasExpired && (
              <TextNew
                color={colors.black}
                size="huge"
                strong
                condensed
                spacingEm={0}
                italic
              >
                {timeRemainingToString(timeToResults, t)}
              </TextNew>
            )}
          </Flex>
        </>
      ) : !hasExpired ? (
        <>
          <TextNew
            color={colors.white}
            size="massive"
            strong
            condensed
            spacingEm={0}
            textAlign={'center'}
          >
            {t(`Waiting for your predictions!`)}
          </TextNew>

          <Spacer height={'medium'} />

          <Flex
            column
            horizontal={'center'}
            gap={'small'}
            css={css`
              width: 50%;
              background-color: ${colors.yellow200};
              padding: 20px;
              border-radius: 8px;
              border: 1px solid ${colors.grey300};
            `}
          >
            <FaClock color={colors.black} size={28} />
            <TextNew
              color={colors.black}
              size="large"
              strong
              condensed
              spacingEm={0}
              textAlign={'center'}
            >
              You have
            </TextNew>
            <TextNew
              color={colors.black}
              size="huge"
              strong
              condensed
              spacingEm={0}
              italic
              textAlign={'center'}
            >
              {timeRemainingToString(timeToResults, t)}
            </TextNew>

            <TextNew
              color={colors.black}
              size="large"
              strong
              condensed
              spacingEm={0}
              textAlign={'center'}
            >
              {t(`to complete your predictions`)}
            </TextNew>
          </Flex>
        </>
      ) : (
        <>
          <TextNew
            color={colors.white}
            size="massive"
            strong
            condensed
            spacingEm={0}
          >
            {t(`Time's up!`)}
          </TextNew>
          <Spacer height={'medium'} />
          <TextNew
            color={colors.white}
            size="large"
            strong
            condensed
            spacingEm={0}
          >
            {t(`You can no longer complete your predictions`)}
          </TextNew>
        </>
      )}
    </Flex>
  )
}

const ViewerPredictions = ({ instanceId }: { instanceId: string }) => {
  const { data, loading } = useLeagueQuizInstanceQuery(instanceId)
  const t = useT()

  if (loading) return <div>Loading...</div>

  const instance = data?.leagueQuizInstance
  const completedQuestions = instance?.completedQuestions

  return (
    <Flex
      column
      gap={'small'}
      css={css`
        width: 100%;
      `}
    >
      <Flex gap="huge" horizontal={'space-between'}>
        <Flex
          css={css`
            width: 100%;
          `}
        >
          <TextNew
            size="medium"
            color={colors.white}
            strong
            condensed
            spacingEm={0}
          >
            {t(`Question`)}
          </TextNew>
        </Flex>

        <Flex
          css={css`
            width: 60%;
            padding: 0 0 0 2px;
          `}
        >
          <TextNew
            size="medium"
            color={colors.white}
            strong
            condensed
            spacingEm={0}
          >
            {t(`Your Prediction`)}
          </TextNew>
        </Flex>
      </Flex>

      {completedQuestions?.map((question) => {
        if ('text' in question) {
          const selectedAlternative = question.alternatives.find(
            (alternative) => alternative.selectedAlternative
          )
          return (
            <Flex key={question.id} column gap="small">
              {/* Labels for Question and Prediction */}

              <Flex gap="huge" horizontal={'space-between'}>
                {/* Question Text */}
                <Flex
                  css={css`
                    background-color: ${colors.blue200};
                    padding: 10px;
                    border-radius: 8px;
                    max-width: 100%;
                    width: 100%;
                  `}
                >
                  <TextNew size="medium" strong condensed spacingEm={0}>
                    {question.text}
                  </TextNew>
                </Flex>

                {/* Selected Alternative or 'Not Answered' */}
                {selectedAlternative ? (
                  <Flex
                    key={selectedAlternative.id}
                    horizontal={'center'}
                    vertical={'center'}
                    gap="small"
                    css={css`
                      background-color: ${selectedAlternative.selectedAlternative
                        ? colors.white
                        : colors.grey300};
                      padding: 10px;
                      border-radius: 8px;
                      width: 60%;
                    `}
                  >
                    <TextNew size="medium" strong condensed spacingEm={0}>
                      {selectedAlternative.text}
                    </TextNew>
                  </Flex>
                ) : (
                  <TextNew size="medium" strong condensed spacingEm={0}>
                    {t(`Not answered`)}
                  </TextNew>
                )}
              </Flex>
            </Flex>
          )
        }
      })}
    </Flex>
  )
}
