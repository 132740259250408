/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react'
import { useEffect, useState } from 'react'
import ConfettiExplosion from 'react-confetti-explosion'
import { Flex } from '../../../components/layout/Flex'
import { TextNew } from '../../../components/TextNew'
import { useViewerQuery } from '../../../lib/AuthContext'
import {
  fcCurve,
  growAndFade,
  pulseShrinkAnimation,
} from '../../../styles/animations'
import { confettiColors } from '../../../styles/styles'
import { colors } from '../../../styles/theme'
import { UserAvatar } from '../../Avatar/UserAvatar'
import { CompletedQuestion } from '../../LeagueQuiz/queries'

export const fadeInHalf = keyframes`
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
`

interface QuizResultsProps {
  title: string
  questions: CompletedQuestion[]
  setPlayAnimation: (value: boolean) => void
  playAnimation: boolean
  delay?: number
}

const PredictionResults = ({
  title,
  questions,
  setPlayAnimation,
  playAnimation,
  delay = 1500,
}: QuizResultsProps) => {
  const [revealedQuestions, setRevealedQuestions] = useState<number[]>([0])
  const [phase, setPhase] = useState(1)
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
  const viewerQuery = useViewerQuery()
  const avatarData = viewerQuery?.data?.viewer?.avatarData

  useEffect(() => {
    if (
      revealedQuestions.length <= questions.length &&
      (playAnimation || true)
    ) {
      const timer = setTimeout(() => {
        if (phase === 1) {
          setPhase(2) // Phase 2: Highlight user's guess
        } else if (phase === 2) {
          setPhase(3) // Phase 3: Reveal the correct answer
        } else if (phase === 3) {
          if (currentQuestionIndex === questions.length - 1) {
            setPlayAnimation(false)
          } else {
            setRevealedQuestions((prev) => [...prev, currentQuestionIndex + 1])
            setPhase(1)
            setCurrentQuestionIndex((prevIndex) => prevIndex + 1)
          }
        }
      }, delay)

      return () => clearTimeout(timer)
    } else {
      setRevealedQuestions(
        Array.from({ length: questions.length }, (_, i) => i)
      )
      setCurrentQuestionIndex(questions.length)
    }
  }, [
    phase,
    currentQuestionIndex,
    revealedQuestions.length,
    delay,
    setPlayAnimation,
    questions.length,
  ])

  const allQuestionsRevealed = revealedQuestions.length === questions.length

  const currentPoints =
    revealedQuestions.filter((index) =>
      questions[index].alternatives.find(
        (alt) => alt.selectedAlternative && alt.isCorrectAlternative
      )
    ).length * 10

  console.log({ currentPoints })
  return (
    <Flex
      column
      gap="medium"
      horizontal="center"
      vertical="center"
      css={css`
        width: 100%;
      `}
    >
      <TextNew
        size="massive"
        color={colors.black}
        strong
        condensed
        italic
        textAlign={'center'}
      >
        {title}
      </TextNew>
      {questions.map((question, index) => {
        const playerGuess = question.alternatives.find(
          (alt) => alt.selectedAlternative
        )

        const correctAnswer = question.alternatives.find(
          (alt) => alt.isCorrectAlternative
        )

        const isCurrentQuestion = index === currentQuestionIndex

        return (
          <Flex
            key={question.id}
            column
            gap="large"
            css={[
              css`
                width: 100%;
                opacity: ${!revealedQuestions.includes(index) ? 0.5 : 1};
                animation: ${revealedQuestions.includes(index) && fadeInHalf}
                  650ms cubic-bezier(0.4, 0, 0.15, 1);
              `,
              isCurrentQuestion &&
                !allQuestionsRevealed &&
                css`
                  animation: ${pulseShrinkAnimation} ${delay}ms ${fcCurve};
                `,
            ]}
          >
            <TextNew size="large" strong condensed textAlign={'center'}>
              {question.text}
            </TextNew>

            {question.imageUrl && (
              <img
                src={question.imageUrl}
                alt="Question related image"
                css={css`
                  max-width: 100%;
                  border-radius: 8px;
                  margin-bottom: 10px;
                `}
              />
            )}

            <Flex
              gap="small"
              horizontal={'space-between'}
              css={css`
                width: 100%;
              `}
            >
              {[...question.alternatives]
                .sort((a, b) => {
                  const indexA = a.index !== undefined ? a.index : Infinity
                  const indexB = b.index !== undefined ? b.index : Infinity
                  return indexA - indexB
                })
                .map((alternative) => {
                  const isPlayerGuess = alternative.id === playerGuess?.id
                  const isCorrectAnswer = alternative.id === correctAnswer?.id

                  let backgroundColor = colors.white
                  let borderColor = colors.grey200

                  if (index > currentQuestionIndex) {
                    backgroundColor = colors.white
                    borderColor = colors.grey200
                  } else if (
                    phase === 2 &&
                    isPlayerGuess &&
                    currentQuestionIndex === index
                  ) {
                    backgroundColor = colors.blue200
                    borderColor = colors.blue500
                  } else if (phase === 3 || currentQuestionIndex > index) {
                    if (isCorrectAnswer && isPlayerGuess) {
                      backgroundColor = colors.green300
                      borderColor = colors.green400
                    } else if (isCorrectAnswer) {
                      backgroundColor = colors.green100
                      borderColor = colors.green400
                    } else if (isPlayerGuess) {
                      backgroundColor = colors.red200
                      borderColor = colors.red500
                    }
                  }

                  const showAvatar =
                    index <= currentQuestionIndex &&
                    isPlayerGuess &&
                    (currentQuestionIndex > index || phase !== 1)

                  const showPoints =
                    index <= currentQuestionIndex &&
                    (currentQuestionIndex > index || phase === 3) &&
                    isCorrectAnswer &&
                    isPlayerGuess

                  return (
                    <Flex
                      column
                      horizontal={'center'}
                      vertical={'flex-start'}
                      css={css`
                        width: 100%;
                        height: 65px;
                      `}
                    >
                      {isCorrectAnswer &&
                        phase === 3 &&
                        currentQuestionIndex === index &&
                        isPlayerGuess && (
                          <ConfettiExplosion
                            colors={confettiColors}
                            particleCount={currentPoints * 2}
                          />
                        )}
                      <Flex
                        key={alternative.id}
                        vertical={'center'}
                        horizontal={'center'}
                        css={css`
                          position: relative;
                          background-color: ${backgroundColor};
                          padding: 10px;
                          border-radius: 8px;
                          width: 100%;
                          text-align: center;
                          border: 2px solid ${borderColor};
                          transition: background-color 0.3s ease-in-out,
                            border 0.3s ease-in-out;
                        `}
                      >
                        {showAvatar && (
                          <Flex
                            horizontal={'center'}
                            vertical={'center'}
                            css={css`
                              position: absolute;
                              width: 30px;
                              height: 30px;
                              z-index: 0;
                              top: -20px;
                              background-color: ${backgroundColor};
                              border-radius: 50%;
                              border: 2px solid ${borderColor};
                              animation: ${currentQuestionIndex === index &&
                                growAndFade}
                                0.5s ease-in-out;
                              padding: 5px;
                              overflow: hidden;
                            `}
                          >
                            <UserAvatar avatarData={avatarData} height={30} />
                          </Flex>
                        )}
                        <TextNew size="medium" strong condensed spacingEm={0}>
                          {alternative.text}
                        </TextNew>
                      </Flex>
                      {showPoints && (
                        <TextNew
                          size={18}
                          color={colors.green400}
                          strong
                          condensed
                          css={css`
                            margin-top: 8px;
                            animation: ${currentQuestionIndex === index &&
                              growAndFade}
                              0.5s ease-in-out;
                          `}
                        >
                          +10 🎉
                        </TextNew>
                      )}
                    </Flex>
                  )
                })}
            </Flex>
          </Flex>
        )
      })}
    </Flex>
  )
}

export default PredictionResults
