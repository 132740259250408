import {
  createBrowserRouter,
  Navigate,
  RouteObject,
  useParams,
} from 'react-router-dom'
import { ChallengePage } from './components/challenge/Challenge'
import { Layout } from './components/layout/Layout'
import { AnalyticsEventType, useTrackEvent } from './lib/analytics'
import { useAuthContext, useViewerQuery } from './lib/AuthContext'
import { locales } from './lib/i18n/locale'
import { colors } from './styles/theme'
import { AccountHandlingPage } from './views/AccountHandling/AccountHandling'
import { AdminAdvertisersPage } from './views/Admin/AdminAdvertisersPage'
import { AdminAnalyticsPage } from './views/Admin/AdminAnalyticsPage'
import { AdminChallengeLeaguePage } from './views/Admin/AdminChallengeLeaguePage'
import { AdminClubsPage } from './views/Admin/AdminClubsPage'
import { AdminClubSubPage } from './views/Admin/AdminClubSubPage'
import { AdminDeleteUsersPage } from './views/Admin/AdminDeleteUsersPage'
import { AdminLiveQuizPage } from './views/Admin/AdminLiveQuizPage'
import { AdminLoadTestPage } from './views/Admin/AdminLoadTestPage'
import { AdminPredictionQuiz } from './views/Admin/AdminPredictionQuizPage'
import { AdminQuestionsPage } from './views/Admin/AdminQuestionsPage'
import { AdminQuizPage } from './views/Admin/AdminQuizPage'
import { AdminEmailPage } from './views/Admin/EmailPage'
import { AdminLeaguePage } from './views/Admin/LeagueManagementPage'
import { AdminPushPage } from './views/Admin/PushPage'
import { AdminUsersPage } from './views/Admin/UsersPage'
import { AdTestPage } from './views/AdTest/AdTestPage'
import { AdvertiserDashboardPage } from './views/AdvertiserDashboard/AdvertiserDashboardPage'
import { AvatarPage } from './views/Avatar/AvatarPage'
import { ChallengeGroupPage } from './views/Challenge/ChallengeGroupPage'
import { ChallengeLeaguePage } from './views/Challenge/ChallengeLeaguePage'
import { ClubDisplayPage } from './views/Challenge/DisplayPage/ClubDisplayPage'
import { LeaguesPage } from './views/Challenge/LeaguesPage'
import { MyChallengesPage } from './views/Challenge/MyChallengesPage'
import { ChallengeLeagueSettingsPage } from './views/Challenge/SettingsPage/ChallengeLeagueSettingsPage'
import ChallengeMatchPage from './views/ChallengeMatch/ChallengeMatchPage'
import { ClubProfilePageV2 } from './views/Clubs/ClubProfilePage/ClubProfilePageV2'
import { ClubsPage } from './views/Clubs/ClubsPage'
import { EditClubPageV2 } from './views/Clubs/EditClub/EditClubPageV2'
import { DisplayLiveQuizPage } from './views/DisplayLiveQuiz/DisplayLiveQuizPage'
import { EditProfilePage } from './views/EditProfile/EditProfilePage'
import ClubLeagueSubLandingPageV2 from './views/Embed/ClubLeagueSubLandingPageV2'
import ClubLeagueSubQuizEmbedPage from './views/Embed/ClubLeagueSubscriptionQuizEmbedPage'
import LeagueEmbedLandingPage from './views/Embed/LeagueEmbedPage'
import LeagueQuizEmbedPage from './views/Embed/LeagueQuizEmbedPage'
import { EmbedErrorPage } from './views/Embed/SubPages'
import { FeedPage } from './views/Feed/FeedPage'
import { HomePage } from './views/Home/HomePage'
import { InvitationPage } from './views/Invitation/Invitation'
import { LeaderboardsPage } from './views/Leaderboards/LeaderboardsPage'
import { CreateQuizPage } from './views/League/Create/CreateQuizPage'
import { EditQuizPage } from './views/League/Edit/EditQuizPage'
import { LeaguePage } from './views/League/LeaguePage'
import LeagueQuizPage from './views/LeagueQuiz/LeagueQuizPage'
import { QuizLandingPage } from './views/LeagueQuiz/QuizLandingPage'
import { Library } from './views/Library/Library'
import { LiveQuizHostPage } from './views/LiveQuiz/LiveQuizHostPage'
import NotFound from './views/NotFound/NotFoundPage'
import { PlayerJoinLiveQuizPage } from './views/PlayLiveQuiz/PlayerJoinLiveQuizPage'
import PlayLiveQuizPage from './views/PlayLiveQuiz/PlayLiveQuizPage'
import { PredictionQuizPage } from './views/PredictionQuiz/PredictionQuizPage'
import { ProfilePage } from './views/Profile/ProfilePage'
import { UserNotificationsPage } from './views/Profile/UserNotificationsPage'
import { SearchPage } from './views/Search/Search'
import { SignupPage } from './views/SignupPage'
import { StorePage } from './views/Store/StorePage'
import { PrivacyPage } from './views/Terms/PrivacyPage'
import { TermsPage } from './views/Terms/TermsPage'
import { TrainingPage } from './views/Training/TrainingPage'
import { TrainingQuizPage } from './views/TrainingQuiz/TrainingQuizPage'
import { TranslateQuizPage } from './views/Translate/League/TranslateQuizPage'
import { PredictionsPage } from './views/PredictionQuiz/Page/PredictionsPage'
import { AdminTimelessPage } from './views/Admin/AdminTimelessPage'
import { ContestsPage } from './views/Contests/ContestsPage'

const ProfileRedirectPage = () => {
  const { loading, authUser } = useAuthContext()
  const viewerQuery = useViewerQuery({ skip: !authUser })

  return (
    <Layout backgroundColor={colors.green600}>
      {loading || viewerQuery.loading ? null : !viewerQuery.data?.viewer ||
        authUser?.isAnonymous ? (
        <Navigate to="/" />
      ) : (
        <Navigate to={`/profile/${viewerQuery.data.viewer.slug}`} replace />
      )}
    </Layout>
  )
}

const QrCodeRedirectPage = () => {
  const code = useParams<{ code: string }>().code
  useTrackEvent(AnalyticsEventType.QRCode, { id: code })

  return (
    <Layout backgroundColor={colors.yellow400}>
      <Navigate to="/" replace />
    </Layout>
  )
}

const withLocaleBasePaths = (routes: RouteObject[]) => {
  const allRoutes: RouteObject[] = [...routes]

  for (const locale of locales) {
    allRoutes.push(
      ...routes.map((route) => ({
        ...route,
        path: route.path ? `/${locale}${route.path}` : undefined,
      }))
    )
  }

  return allRoutes
}

export const router = createBrowserRouter([
  ...withLocaleBasePaths([
    { path: '/', element: <HomePage /> },

    { path: '/feed', element: <FeedPage /> },

    { path: '/library', element: <Library /> },

    { path: '/search', element: <SearchPage /> },
    { path: '/library/search', element: <SearchPage /> },

    { path: '/admin', element: <Navigate to="/admin/users" replace /> },
    { path: '/admin/users', element: <AdminUsersPage /> },
    { path: '/admin/users/delete', element: <AdminDeleteUsersPage /> },
    { path: '/admin/email', element: <AdminEmailPage /> },
    { path: '/admin/push', element: <AdminPushPage /> },
    { path: '/admin/clubs', element: <AdminClubsPage /> },
    { path: '/admin/leagues', element: <AdminLeaguePage /> },
    { path: '/admin/club-sub', element: <AdminClubSubPage /> },
    { path: '/admin/analytics', element: <AdminAnalyticsPage /> },
    { path: '/admin/live-quiz', element: <AdminLiveQuizPage /> },
    { path: '/admin/live-quiz', element: <AdminLiveQuizPage /> },
    { path: '/admin/challenge-leagues', element: <AdminChallengeLeaguePage /> },
    { path: '/admin/advertisers', element: <AdminAdvertisersPage /> },
    { path: '/admin/loadtest', element: <AdminLoadTestPage /> },
    { path: '/admin/timeless', element: <AdminTimelessPage /> },
    {
      path: '/admin/prediction-quiz',
      element: <AdminPredictionQuiz />,
    },
    { path: '/admin/quiz', element: <AdminQuizPage /> },
    { path: '/admin/questions', element: <AdminQuestionsPage /> },

    { path: '/clubs', element: <ClubsPage /> },
    {
      path: '/clubs/:slug',
      element: <ClubProfilePageV2 />,
    },
    {
      path: '/clubs/:slug/display',
      element: <ClubDisplayPage />,
    },
    { path: '/clubs2/:slug', element: <ClubProfilePageV2 /> },
    {
      path: '/clubs/:slug/edit',
      element: <EditClubPageV2 />,
    },

    { path: '/profile', element: <ProfileRedirectPage /> },
    { path: '/profile/:userSlug', element: <ProfilePage /> },
    {
      path: '/profile/notifications',
      element: <UserNotificationsPage />,
    },
    { path: '/profile/avatar', element: <AvatarPage /> },
    { path: '/profile/edit', element: <EditProfilePage /> },
    { path: '/account', element: <AccountHandlingPage /> },

    { path: '/series/:slug', element: <LeaguePage /> },
    { path: '/league/:slug/:quizId', element: <LeaguePage /> }, // Legacy: Keep once
    { path: '/series/:slug/:quizId', element: <LeaguePage /> },
    { path: '/league-quiz/:leagueQuizId', element: <LeagueQuizPage /> }, // Legacy: Keep once
    { path: '/play-quiz/:leagueQuizId', element: <LeagueQuizPage /> },
    {
      path: '/play-quiz/prediction/:leagueQuizId',
      element: <PredictionQuizPage />,
    },
    { path: '/quiz/:slug', element: <QuizLandingPage /> },

    { path: '/create/:slug', element: <CreateQuizPage /> },
    { path: '/edit/:slug/:quizId', element: <EditQuizPage /> },
    { path: '/translate/:slug/:quizId', element: <TranslateQuizPage /> },
    { path: '/challenge/:quizInstanceId', element: <ChallengePage /> },
    { path: '/my-challenges', element: <MyChallengesPage /> },
    {
      path: '/challenge-match/:challengeMatchId',
      element: <ChallengeMatchPage />,
    },
    {
      path: '/challenge-group/:groupId',
      element: <ChallengeGroupPage />,
    },
    {
      path: '/league/:challengeLeagueId',
      element: <ChallengeLeaguePage />,
    },

    {
      path: '/challenge-league/:challengeLeagueId',
      element: <ChallengeLeaguePage />,
    },
    {
      path: '/league/:slug/settings',
      element: <ChallengeLeagueSettingsPage />,
    },

    {
      path: '/leagues',
      element: <LeaguesPage />,
    },
    {
      path: 'predictions',
      element: <PredictionsPage />,
    },

    { path: '/invitation/:invitationId', element: <InvitationPage /> },

    { path: '/training', element: <TrainingPage /> },
    { path: '/training-quiz/:trainingQuizId', element: <TrainingQuizPage /> },

    {
      path: '/embed/club-league/:clubLeagueSubscriptionId',
      element: <ClubLeagueSubLandingPageV2 />,
    },
    {
      path: '/embed/club-league/:clubLeagueSubscriptionId/:quizId',
      element: <ClubLeagueSubLandingPageV2 />,
    },
    {
      path: '/embed/league/:leagueId/:quizId',
      element: <LeagueEmbedLandingPage />,
    },
    {
      path: '/embed/quiz/:quizId/:leagueId',
      element: <LeagueQuizEmbedPage />,
    },
    {
      path: '/embed/club-league-quiz/:clubLeagueSubscriptionId/:id',
      element: <ClubLeagueSubQuizEmbedPage />,
    },
    {
      path: '/embed-error',
      element: <EmbedErrorPage />,
    },

    { path: 'live-host/:instanceId', element: <LiveQuizHostPage /> },
    { path: 'live-display/:code', element: <DisplayLiveQuizPage /> },
    { path: 'join', element: <PlayerJoinLiveQuizPage /> },
    { path: 'join/:inviteCode', element: <PlayerJoinLiveQuizPage /> },
    { path: 'live-quiz/:instanceId', element: <PlayLiveQuizPage /> },

    { path: '/privacy', element: <PrivacyPage /> },
    { path: '/terms', element: <TermsPage /> },
    { path: '/contests', element: <ContestsPage /> },

    { path: '/qr', element: <QrCodeRedirectPage /> },
    { path: '/qr/:code', element: <QrCodeRedirectPage /> },

    { path: '/ad-test', element: <AdTestPage /> },

    { path: '/store', element: <StorePage /> },
    { path: '/leaderboards', element: <LeaderboardsPage /> },

    { path: '/advertiser/:advertiserId', element: <AdvertiserDashboardPage /> },

    { path: '/signup', element: <SignupPage /> },
  ]),

  { path: '*', element: <NotFound /> },
])
