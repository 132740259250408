/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { Heading } from '../../components/Heading'
import { SelectInput } from '../../components/SelectInput'
import { Text } from '../../components/Text'
import { Flex } from '../../components/layout/Flex'
import { Modal } from '../../components/modal/Modal'
import { useT } from '../../lib/i18n/useT'
import { colors } from '../../styles/theme'
import { QuizType, useFinishContext } from './GeneratePage'
import { isProduction } from '../../config/config'

const options = [
  {
    src: '/plus.png',
    heading: 'Blank',
    text: 'Do it manually',
    template: 'blank',
  },
  {
    src: '/ai-assist.png',
    heading: 'AI Assisted',
    text: 'From text or url',
    template: 'ai',
  },
  {
    src: '/career-path.png',
    heading: 'Career Path',
    text: 'Create a career path question',
    template: 'career-path',
  },
  {
    src: '/prediction.png',
    heading: 'Prediction',
    text: 'Create a prediction question',
    template: 'prediction',
  },
]

export const PickTemplateModal = (props: {
  leagueSlug: any
  setTemplate: any
  onClose: any
  quizType: QuizType
}) => {
  const { language, setLanguage, isLiveQuiz } = useFinishContext()
  const t = useT(`${language}`)

  return (
    <Modal
      heading={t(`Create questions`)}
      styling={css`
        margin-top: 10%;
        padding: 0;
        button {
          display: none;
        }
        position: relative;
      `}
      closeModal={props.onClose()}
    >
      {!props.leagueSlug && (
        <Flex
          css={css`
            position: absolute;
            top: 30px;
            right: 15px;
          `}
        >
          <SelectInput value={language!} onValue={setLanguage}>
            <option value="en">{'🇬🇧'}</option>
            <option value="no">{'🇳🇴'}</option>
          </SelectInput>
        </Flex>
      )}
      <Flex>
        <Flex
          css={css`
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-items: center;
            align-items: center;
            width: 100%;
            border-radius: 10px;
            padding: 24px;
            min-height: 300px;

            background-color: ${colors.grey100};
            overflow: hidden;
            @media (max-width: 600px) {
              flex-direction: column;
            }
          `}
          horizontal="center"
          vertical="center"
          gap="huge"
        >
          {options
            .filter(
              // If quizType is career-path, only show career-path template.
              // If quizType is text or the environment is production, don't show career-path template.
              // If quizType is combination, show all.
              // If the quiz contains a prediction question, only show prediction templates.
              (it) => {
                if (isLiveQuiz) {
                  return (
                    it.template !== 'career-path' &&
                    it.template !== 'prediction'
                  )
                }

                // Disable prediction in PROD
                if (it.template === 'prediction' && isProduction) {
                  return false
                }

                if (props.quizType === QuizType.COMBINATION) {
                  return true
                }

                if (props.quizType === QuizType.CAREERPATH) {
                  // For career-path quizzes, only allow career-path template
                  return it.template === 'career-path'
                } else if (props.quizType === QuizType.TEXT) {
                  // For text quizzes, exclude career-path template
                  return (
                    it.template !== 'career-path' &&
                    it.template !== 'prediction'
                  )
                } else if (props.quizType === QuizType.PREDICTION) {
                  // If the quiz contains a prediction question, only allow prediction templates
                  return it.template === 'prediction'
                } else {
                  // For combination or other types, allow all templates
                  return true
                }
              }
            )
            .map((it) => (
              <Flex
                key={it.template}
                css={css`
                  padding: 40px 20px;
                  min-width: 200px;
                  background-color: #ffffff;
                  border-radius: 10px;
                  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
                  transition: 0.33s;
                  &:hover {
                    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
                    cursor: pointer;
                  }
                `}
                horizontal="center"
                vertical="center"
                column
                onClick={() => {
                  props.setTemplate(it.template)
                  props.onClose(false)
                }}
              >
                <img
                  css={css`
                    width: 50px;
                    aspect-ratio: 1;
                    background-color: ${colors.grey100};
                    border-radius: 5px;
                    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
                    padding: 10px;
                    margin-bottom: 20px;
                  `}
                  src={it.src}
                ></img>
                <Heading level={5}>{it.heading}</Heading>
                <Text
                  css={css`
                    font-size: 12px;
                  `}
                >
                  {it.text}
                </Text>
              </Flex>
            ))}
        </Flex>
      </Flex>
    </Modal>
  )
}
