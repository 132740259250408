export const storeItems: {
  title: string
  part:
    | 'Hair'
    | 'Eyes'
    | 'Mouth'
    | 'Brows'
    | 'Chest'
    | 'Shoulders'
    | 'Arms'
    | 'Sleeves'
    | 'Collar'
  id: string
  cost: number
  useBorder: boolean // Zoom into the svg
}[] = [
  { title: 'Ponytail', part: 'Hair', id: 'HairU', cost: 10, useBorder: false },
  {
    title: 'Ponytail Headband',
    part: 'Hair',
    id: 'HairV',
    cost: 10,
    useBorder: false,
  },
  {
    title: 'Braids Side',
    part: 'Hair',
    id: 'HairW',
    cost: 10,
    useBorder: false,
  },
  {
    title: 'Braids Back',
    part: 'Hair',
    id: 'Hair5',
    cost: 10,
    useBorder: false,
  },
  {
    title: 'Middle Part',
    part: 'Hair',
    id: 'Hair4',
    cost: 10,
    useBorder: false,
  },
  {
    title: 'Middle Part Headband',
    part: 'Hair',
    id: 'HairX',
    cost: 10,
    useBorder: false,
  },
  {
    title: 'Headband Long',
    part: 'Hair',
    id: 'HairY',
    cost: 10,
    useBorder: false,
  },
  {
    title: 'Headband Long Curly',
    part: 'Hair',
    id: 'HairZ',
    cost: 10,
    useBorder: false,
  },
  {
    title: 'Short Hair Top',
    part: 'Hair',
    id: 'Hair1',
    cost: 10,
    useBorder: false,
  },
  {
    title: 'Short Hair Front',
    part: 'Hair',
    id: 'Hair2',
    cost: 10,
    useBorder: false,
  },
  {
    title: 'Short Hair Sidecut',
    part: 'Hair',
    id: 'Hair3',
    cost: 10,
    useBorder: false,
  },
  {
    title: 'Santa Hat',
    part: 'Hair',
    id: 'Hair7',
    cost: 10,
    useBorder: false,
  },
  {
    title: 'TIL Shirt',
    part: 'Chest',
    id: 'ChestTIL',
    cost: 100,
    useBorder: true,
  },
]
