/** @jsxImportSource @emotion/react */

import { useEffect, useState } from 'react'
import { shuffle } from 'shuffle-seed'
import { useAuthContext } from '../../lib/AuthContext'
import {
  SanityLeagueQuiz,
  SanityLeagueQuizCampaign,
  SanityQuestion,
} from '../../lib/sanity/types'
import {
  QuizState,
  useLeagueQuizContext,
} from '../../views/LeagueQuiz/LeagueQuizContext'

import {
  QuizStatistics,
  useSubmitQuizAnswersMutation,
} from '../../components/clientSideQuiz/queries'
import { ActiveQuestionScreen } from './ActiveQuestionScreen'
import { QuizStartScreen } from './QuizStartScreen'
import FinishedQuiz from './FinishedQuiz'
import { QuizSplash } from './QuizSplash'
import { LastCompletedQuestionScreen } from './LastCompletedQuestionScreen'
import { colors } from '../../styles/theme'
import { TextNew } from '../../components/TextNew'
import { useT } from '../../lib/i18n/useT'
import { Spacer } from '../../styles/margin'
import { PageContent } from '../../components/layout/PageContent'
import { FixtureHeader } from './components/FixtureHeader'

export type QuestionAnswer = {
  questionId: string
  alternativeId: string | null
  startedAt: Date
  answeredAt: Date
  correct: boolean
  score: number
  timeBonus: number
}

const existingSessionKey = (quizId: string) => `quiz-session-${quizId}`

type ExistingQuizSession = {
  quizId: string
  answers: QuestionAnswer[]
}

const getExistingQuizSession = (
  quizId: string
): ExistingQuizSession | undefined => {
  const existingSession = localStorage.getItem(existingSessionKey(quizId))
  if (!existingSession) {
    return undefined
  }

  return JSON.parse(existingSession)
}

const setExistingQuizSession = (quizId: string, answers: QuestionAnswer[]) => {
  localStorage.setItem(
    existingSessionKey(quizId),
    JSON.stringify({ quizId, answers })
  )
}

const clearExistingQuizSession = (quizId: string) => {
  localStorage.removeItem(existingSessionKey(quizId))
}

export const useExistingQuizSession = (quizId?: string) => {
  const [existingSession, setExistingSession] = useState<
    ExistingQuizSession | undefined
  >(undefined)

  useEffect(() => {
    if (!quizId) {
      return
    }

    const existing = getExistingQuizSession(quizId)

    if (!existing) {
      return
    }

    setExistingSession(existing)
  }, [quizId])

  return existingSession
}

const getCompletedQuestionsFromSession = (
  questons: SanityQuestion[],
  session?: ExistingQuizSession
): SanityQuestion[] => {
  if (!session) {
    return []
  }

  const completedQuestions = questons.filter((q) =>
    session.answers.some((a) => a.questionId === q.id)
  )

  return completedQuestions
}

export const PredictionQuiz = ({
  quiz,
  campaign,
  isEmbed = false,
  clubLeagueSubscriptionId,
  quizStatistics,
}: {
  quiz: SanityLeagueQuiz
  campaign?: SanityLeagueQuizCampaign
  isEmbed?: boolean
  clubLeagueSubscriptionId?: string
  quizStatistics?: QuizStatistics
}) => {
  const t = useT()
  const { authUser } = useAuthContext()
  const session = useExistingQuizSession(quiz.id)
  const questions = quiz.randomizeQuestionOrder
    ? shuffle(quiz.questions, authUser?.uid)
    : quiz.questions

  const { quizState, setQuizState, setIsExpandedAdActive, setInstanceId } =
    useLeagueQuizContext()

  const [submitQuizAnwersMutation] = useSubmitQuizAnswersMutation()

  const [activeQuestion, setActiveQuestion] = useState<
    SanityQuestion | undefined
  >()

  const [completedQuestions, setCompletedQuestions] = useState<
    SanityQuestion[]
  >([])

  const moreThanOneFixture = quiz.fixtures ? quiz.fixtures.length > 1 : false

  useEffect(() => {
    if (session) {
      setQuizState(QuizState.ACTIVE_QUIZ)
      setCompletedQuestions(
        getCompletedQuestionsFromSession(questions, session)
      )

      setAnswers(session.answers)

      if (session.answers.length === questions.length) {
        setFinished(true)
      }
    }
  }, [session])

  const [answers, setAnswers] = useState<QuestionAnswer[]>(
    session?.answers || []
  )

  const unansweredQuestions: SanityQuestion[] = questions.filter(
    (question) => !completedQuestions.map((q) => q.id).includes(question.id)
  )

  const [finished, setFinished] = useState<boolean>(false)

  useEffect(() => {
    if (!finished) {
      return
    }

    async function submitAnswers() {
      if (!authUser) {
        return
      }

      const res = await submitQuizAnwersMutation({
        variables: {
          input: {
            quizId: quiz.id,
            leagueId: quiz.league.id,
            answers,
            quizType: quiz.quizType,
          },
        },
      })

      const instanceId = res.data?.submitQuizAnswers.id

      // If the insertion is successful, clear the existing quiz session from local storage
      if (instanceId) {
        setInstanceId(instanceId)
        clearExistingQuizSession(quiz.id)
      }
    }

    submitAnswers()
    //clearExistingQuizSession(quiz.id)
  }, [finished])

  const lastCompletedQuestion =
    completedQuestions[completedQuestions.length - 1]

  const allQuestionsAnswered = completedQuestions.length === quiz.questionsCount

  const answerQuestion = async (answer: QuestionAnswer) => {
    if (!activeQuestion) {
      return
    }

    //350ms delay for animation
    await new Promise((resolve) => setTimeout(resolve, 350))

    setAnswers([...answers, answer])

    // set active question as completed
    setCompletedQuestions([...completedQuestions, activeQuestion!])

    // reset active question
    setActiveQuestion(undefined)

    setExistingQuizSession(quiz.id, [...answers, answer])
  }

  const nextQuestion = () => {
    if (allQuestionsAnswered || unansweredQuestions.length === 0) {
      return
    }

    setActiveQuestion(unansweredQuestions[0])
  }

  const triggerCalculation = () => {
    setIsExpandedAdActive(true)
    setFinished(true)
  }

  if (quizState === QuizState.START_SCREEN) {
    return (
      <QuizStartScreen
        quizTitle={quiz.title}
        embedPromotionalText={quiz.embedPromotionalText}
        campaign={campaign}
        isEmbed={isEmbed}
      />
    )
  }

  if (finished) {
    return (
      <PageContent>
        <Spacer height={'small'} />
        <TextNew
          italic
          strong
          condensed
          color={colors.black}
          size={30}
          textAlign={'center'}
        >
          {t(`Your Predictions`)}
        </TextNew>
        <FinishedQuiz
          clubLeagueSubscriptionId={clubLeagueSubscriptionId}
          playAnimation={false}
          quizId={quiz.id}
          isEmbed={isEmbed}
          leagueSlug={quiz.league.slug || quiz.league.id}
          hasCampaign={!!campaign}
          endDateTime={quiz.availableTo}
          title={quiz.title}
          completedQuestions={completedQuestions.map((q) => ({
            id: q.id,
            text: q.text,
            alternatives: q.alternatives.map((a) => {
              return {
                id: a.id,
                text: a.text,
                isCorrectAlternative: false,
                selectedAlternative:
                  answers.find((answer) => answer.questionId === q.id)
                    ?.alternativeId === a.id,
                numberOfAnswers: 0,
              }
            }),
            secondsLeft: 0,
            imageUrl: '',
          }))}
        />
      </PageContent>
    )
  }

  if (!activeQuestion && !lastCompletedQuestion) {
    return (
      <QuizSplash nextQuestion={nextQuestion} nextQuestionLoading={false} />
    )
  }

  if (activeQuestion) {
    const fixture = moreThanOneFixture
      ? quiz.fixtures?.find((f) => f.questionId === activeQuestion.id)
      : quiz.fixtures?.[0]
    return (
      <>
        {fixture && (
          <FixtureHeader
            fixture={fixture}
            hasSanityCampaign={campaign !== undefined}
          />
        )}
        <ActiveQuestionScreen
          activeQuestion={activeQuestion}
          numCompletedQuestions={completedQuestions.length}
          questionsCount={quiz.questionsCount}
          isEmbed={isEmbed}
          answerQuestion={answerQuestion}
        />
      </>
    )
  }

  if (lastCompletedQuestion) {
    const fixture = moreThanOneFixture
      ? quiz.fixtures?.find((f) => f.questionId === lastCompletedQuestion.id)
      : quiz.fixtures?.[0]
    return (
      <>
        {fixture && (
          <FixtureHeader
            fixture={fixture}
            hasSanityCampaign={campaign !== undefined}
          />
        )}
        <LastCompletedQuestionScreen
          lastCompletedQuestion={lastCompletedQuestion}
          allQuestionsAnswered={allQuestionsAnswered}
          triggerCalculation={triggerCalculation}
          nextQuestion={nextQuestion}
          isEmbed={isEmbed}
          clubLeagueSubscriptionId={clubLeagueSubscriptionId}
          quizId={quiz.id}
          leagueId={quiz.league.id}
          questionsCount={quiz.questionsCount}
          currentQuestionIndex={completedQuestions.length}
          answer={answers.find(
            (a) => a.questionId === lastCompletedQuestion.id
          )}
          quizStatistics={quizStatistics}
        />
      </>
    )
  }

  return null
}
