/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PrimaryButton, SecondaryButton } from '../../components/Button'
import { Flex } from '../../components/layout/Flex'
import { Page } from '../../components/leagueQuiz/Page'
import { QuizScoreComponent } from '../../components/leagueQuiz/QuizScoreComponent'
import { Text } from '../../components/Text'
import {
  AD_CAMPAIGN_DISPLAY_TIME_MS,
  AD_CAMPAIGN_HEADER_COLLAPSE_WHEN_TIME_LEFT_MS,
  CHALLENGE_MODE_MAX_W,
} from '../../constants'
import { useViewerQuery } from '../../lib/AuthContext'
import { useT } from '../../lib/i18n/useT'
import { fadeIn, growAndFade } from '../../styles/animations'
import { Spacer, margin } from '../../styles/margin'
import { colors } from '../../styles/theme'
import { useInterval } from '../../utils/useInterval'
import { titleFromQuiz } from '../../utils/util'
import { ChallengeScoreboard } from '../Challenge/components/ChallengeScoreboard'
import { ChallengeLeagueQuizInstance } from '../ChallengeMatch/queries'
import { EnablePushNotificationsBanner } from '../Home/EnablePushNotificationsBanner'
import { EnableNotifications } from '../LeagueQuiz/EnableNotifications'
import { useLeagueQuizContext } from '../LeagueQuiz/LeagueQuizContext'
import { GenerateQuizButton } from '../../components/GenerateQuizButton'

export const ChallengeFinishedQuiz = ({
  instance,
  playAnimation = false,
}: {
  instance: ChallengeLeagueQuizInstance
  playAnimation?: boolean
}) => {
  const t = useT()
  const initialRemaining = !playAnimation ? 0 : AD_CAMPAIGN_DISPLAY_TIME_MS

  const [remaining, setRemaining] = useState(initialRemaining)
  const [timerRunning] = useState(playAnimation)
  const {
    setIsExpandedAdActive,
    isExpandedAdActive,
    videoAdActive,
    hasSanityCampaign,
  } = useLeagueQuizContext()
  const { data: viewerData } = useViewerQuery()

  const navigate = useNavigate()

  useInterval(
    () => {
      setRemaining((remaining) => remaining - 10) // decrease by 10 every 10 milliseconds
    },
    remaining === 0 || !timerRunning ? null : 10 // run every 10 milliseconds
  )

  useEffect(() => {
    if (playAnimation) {
      setIsExpandedAdActive(true)
    }
  }, [])

  useEffect(() => {
    if (remaining <= AD_CAMPAIGN_HEADER_COLLAPSE_WHEN_TIME_LEFT_MS) {
      setIsExpandedAdActive(false)
    }
  }, [remaining])

  const textColor = colors.black

  const challengeGroupId = instance.challengeMatchData?.challengeGroupId
  const challengeGroupIsLeague = instance.challengeMatchData?.isChallengeLeague

  const viewerIsAnonymous = viewerData?.viewer?.email === null

  return (
    <Page showFcQuizLogo logoColor={colors.grey200} dense>
      <Flex
        column
        horizontal={'center'}
        vertical={'space-between'}
        css={css`
          height: 100%;
          padding-top: 16px;
          padding-bottom: 16px;
          margin: 0 auto;
          width: 100%;
          max-width: ${CHALLENGE_MODE_MAX_W}px;
        `}
      >
        <Flex
          column
          horizontal={'center'}
          css={css`
            width: 100%;
          `}
        >
          {(!isExpandedAdActive || !hasSanityCampaign) && (
            <Text color={colors.grey400} size={'small'}>
              {titleFromQuiz(instance.leagueQuiz, t)}
            </Text>
          )}
          <QuizScoreComponent
            questionsCount={instance.leagueQuiz.questionsCount}
            result={instance.result}
            remaining={remaining}
            totalCalculationTime={initialRemaining}
            playAnimation={playAnimation}
          />
        </Flex>
        {!videoAdActive && remaining <= 2000 && (
          <Flex
            column
            vertical={'center'}
            css={[
              margin.top('large'),
              css`
                opacity: 0;
                animation: ${playAnimation ? growAndFade : fadeIn} 350ms
                  cubic-bezier(0.4, 0, 0.15, 1);
                animation-delay: 600ms;
                animation-fill-mode: forwards;
                width: 100%;
              `,
            ]}
          >
            {viewerData?.viewer &&
              instance.challengeUser &&
              instance.challengeUserResult && (
                <>
                  <Spacer height={8} />
                  <ChallengeScoreboard
                    results={[
                      {
                        userId: viewerData.viewer.id,
                        displayName: viewerData.viewer.displayName,
                        avatarData: viewerData.viewer.avatarData,
                        result: instance.result,
                      },
                      {
                        userId: instance.challengeUser.id,
                        displayName: instance.challengeUser.displayName,
                        avatarData: instance.challengeUser.avatarData,
                        result: instance.challengeUserResult,
                      },
                    ]}
                  />
                </>
              )}

            <GenerateQuizButton
              css={css`
                margin-top: 24px;
              `}
            />

            {!challengeGroupId && (
              <PrimaryButton
                onClick={() => {
                  navigate(
                    `/series/${instance.leagueQuiz.league.id}/${instance.leagueQuiz.id}`,
                    {
                      replace: true,
                    }
                  )
                }}
                css={[
                  margin.top('large'),
                  css`
                    height: 50px;
                  `,
                ]}
              >
                {t('To the series page')}
              </PrimaryButton>
            )}

            {challengeGroupId && (
              <>
                <PrimaryButton
                  onClick={() => {
                    if (challengeGroupIsLeague) {
                      navigate(`/challenge-league/${challengeGroupId}`, {
                        replace: true,
                      })
                    } else {
                      navigate(`/challenge-group/${challengeGroupId}`, {
                        replace: true,
                      })
                    }
                  }}
                  css={[
                    margin.top('large'),
                    css`
                      height: 50px;
                    `,
                  ]}
                >
                  {t(`View complete leaderboard`)}
                </PrimaryButton>
                <Spacer height={16} />
                <RedirectToChallengeComponent
                  challengeGroupId={challengeGroupId}
                  playAnimation={playAnimation}
                  challengeGroupIsLeague={challengeGroupIsLeague}
                />
              </>
            )}

            <EnableNotifications
              textColor={textColor}
              viewerIsAnonymous={viewerIsAnonymous}
            />
            <EnablePushNotificationsBanner css={margin.top('large')} />
          </Flex>
        )}
      </Flex>
    </Page>
  )
}

const RedirectToChallengeComponent = ({
  challengeGroupId,
  playAnimation = false,
  challengeGroupIsLeague = false,
}: {
  challengeGroupId: string
  playAnimation?: boolean
  challengeGroupIsLeague?: boolean
}) => {
  const [redirectCancelled, setRedirectCancelled] = useState(!playAnimation)
  const redirectInSeconds = 6
  const [remaining, setRemaining] = useState(redirectInSeconds)

  const navigate = useNavigate()
  const t = useT()

  useEffect(() => {
    return () => {
      setRedirectCancelled(true)
    }
  }, [])

  useInterval(
    () => {
      setRemaining((remaining) => remaining - 1) // decrease by 1 every second
    },
    remaining === 0 || redirectCancelled ? null : 1000 // run every second
  )

  useEffect(() => {
    if (remaining <= 0) {
      if (challengeGroupIsLeague) {
        navigate(`/challenge-league/${challengeGroupId}`, {
          replace: true,
        })
      } else {
        navigate(`/challenge-group/${challengeGroupId}`, {
          replace: true,
        })
      }
    }
  }, [remaining])

  if (!playAnimation || redirectCancelled) {
    return null
  }

  return (
    <Flex vertical="center" horizontal="space-between">
      <Text color={colors.grey400}>
        {t(`Redirecting to leaderboard in {{remaining}} seconds...`, {
          remaining: remaining.toString(),
        })}
      </Text>
      <SecondaryButton onClick={() => setRedirectCancelled(true)}>
        Cancel
      </SecondaryButton>
    </Flex>
  )
}
