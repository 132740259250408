import { gql, useMutation } from '@apollo/client'
import i18n from 'i18next'
import { useCallback } from 'react'
import { initReactI18next, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useAuthContext } from '../AuthContext'
import deCommon from './locales/de/common.json'
import enCommon from './locales/en/common.json'
import esCommon from './locales/es/common.json'
import frCommon from './locales/fr/common.json'
import itCommon from './locales/it/common.json'
import noCommon from './locales/no/common.json'
import arCommon from './locales/ar/common.json'
import ptCommon from './locales/pt/common.json'
import jaCommon from './locales/ja/common.json'
import svCommon from './locales/sv/common.json'
import daCommon from './locales/da/common.json'
import thCommon from './locales/th/common.json'

// Remember to add simpleanalytics ignore for embed in web/public/index.html
export enum Locale {
  no = 'no',
  en = 'en',
  es = 'es',
  de = 'de',
  fr = 'fr',
  it = 'it',
  ar = 'ar',
  pt = 'pt',
  ja = 'ja',
  sv = 'sv',
  da = 'da',
  th = 'th',
}

export const localeToFlag = {
  en: '🇬🇧',
  no: '🇳🇴',
  es: '🇪🇸',
  de: '🇩🇪',
  fr: '🇫🇷',
  it: '🇮🇹',
  ar: '🇪🇬',
  pt: '🇧🇷',
  ja: '🇯🇵',
  sv: '🇸🇪',
  da: '🇩🇰',
  th: '🇹🇭',
}

export const langShortToLang = {
  en: 'English',
  no: 'Norwegian',
  es: 'Spanish',
  de: 'German',
  fr: 'French',
  it: 'Italian',
  ar: 'Arabic',
  pt: 'Portuguese',
  ja: 'Japanese',
  sv: 'Swedish',
  da: 'Danish',
  th: 'Thai',
}

export type LocaleString = {
  en?: string
  no?: string
  es?: string
  de?: string
  fr?: string
  it?: string
  ar?: string
  pt?: string
  ja?: string
  sv?: string
  da?: string
  th?: string
}

export const rtlLocales = [Locale.ar]

const setHtmlLang = (locale: Locale) => {
  document.documentElement.lang = locale
  document.dir = rtlLocales.includes(locale) ? 'rtl' : 'ltr'
}

export const locales = [
  Locale.en,
  Locale.no,
  Locale.es,
  Locale.de,
  Locale.fr,
  Locale.it,
  Locale.ar,
  Locale.pt,
  Locale.ja,
  Locale.sv,
  Locale.da,
  Locale.th,
]

const getLocaleFromStorage = (): Locale | undefined => {
  try {
    const locale = localStorage.getItem('locale')
    return locale ? (locale as Locale) : undefined
  } catch (e) {
    return undefined
  }
}
const setLocaleInStorage = (locale: Locale) => {
  try {
    localStorage.setItem('locale', locale)
  } catch (e) {}
}
try {
  const locale = window.location.pathname.split('/')[1]
  if (locale && locales.includes(locale as Locale)) {
    setLocaleInStorage(locale as Locale)
  }
} catch (e) {}

const initialLocale = getLocaleFromStorage()
if (initialLocale) {
  setHtmlLang(initialLocale)
}

const getBrowserLocale = () => {
  const locale = navigator.language.split('-')[0]

  return locales.includes(locale as Locale) ? locale : Locale.en
}

const browserLocale = getBrowserLocale()

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: enCommon },
    no: { translation: noCommon },
    es: { translation: esCommon },
    de: { translation: deCommon },
    fr: { translation: frCommon },
    it: { translation: itCommon },
    ar: { translation: arCommon },
    pt: { translation: ptCommon },
    ja: { translation: jaCommon },
    sv: { translation: svCommon },
    da: { translation: daCommon },
    th: { translation: thCommon },
  },
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
  lng: initialLocale ?? browserLocale,
  supportedLngs: locales,
  fallbackLng: Locale.en,
})

export const getCurrentLocale = (): Locale => {
  return locales.includes(i18n.language as Locale)
    ? (i18n.language as Locale)
    : Locale.en
}

export const useCurrentLocale = (): Locale => {
  const { i18n } = useTranslation()
  return (i18n.language as Locale | undefined) ?? Locale.en
}

export const setCurrentLocale = (locale: Locale) => {
  i18n.changeLanguage(locale)
  setHtmlLang(locale)
  setLocaleInStorage(locale)
}

export const useSetLocale = () => {
  const { authUser } = useAuthContext()
  const navigate = useNavigate()
  const authenticated = Boolean(authUser)

  const [setLocaleMutation] = useMutation<{}, { locale: Locale }>(gql`
    mutation setLocale($locale: Locale!) {
      setLocale(locale: $locale) {
        id
        locale
      }
    }
  `)

  return useCallback(
    async (locale: Locale, goToIndexPage?: boolean) => {
      setCurrentLocale(locale)

      if (authenticated) {
        await setLocaleMutation({ variables: { locale } })
      }

      if (goToIndexPage) {
        navigate(`/${locale}`)
      }
    },
    [setLocaleMutation, navigate, authenticated]
  )
}

const localesPrioritized = () => {
  const priority: { [key: string]: number } = {
    ...Object.fromEntries(locales.map((it: Locale) => [it, 1])),
    en: 4,
    no: 3,
    es: 2,
  }
  const localesSorted = [...locales]
  localesSorted.sort((a: Locale, b: Locale) => priority[b] - priority[a])
  return localesSorted
}

const isEmpty = (text: string | undefined | null) => {
  return text === '' || text === undefined || text === null
}

export const getStringFromLocaleString = (
  localeString: LocaleString,
  locale: Locale | undefined,
  localesFallback = localesPrioritized()
) => {
  try {
    const fallBack = localesFallback
      .map((it: Locale) => localeString[it])
      .find((it) => !isEmpty(it))
    if (!locale || isEmpty(localeString[locale])) {
      return fallBack
    } else {
      return localeString[locale]! //Safe since isEmpty check
    }
  } catch (e) {
    return undefined
  }
}
