import { gql, useMutation, useQuery } from '@apollo/client'
import { css, keyframes } from '@emotion/react'
import { useEffect, useState } from 'react'
import { BiRefresh } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import { AnalyticsEventType, trackEvent } from '../lib/analytics'
import { useAuthContext } from '../lib/AuthContext'
import { useT } from '../lib/i18n/useT'
import theme, { colors } from '../styles/theme'
import { useStartLeagueQuizMutation } from '../views/LeagueQuiz/queries'
import { IconButton, PrimaryButton } from './Button'
import { Flex } from './layout/Flex'
import { Modal, ModalTrigger } from './modal/Modal'
import { TextNew } from './TextNew'

const useIsEmbed = () => {
  const [isEmbed, setIsEmbed] = useState(false)

  useEffect(() => {
    setIsEmbed(window.location.pathname.includes('embed'))
  }, [window.location.pathname])

  return isEmbed
}

type TimelessQuizInput = {
  generate?: boolean
  categoryId?: string
}

const timelessQuizMutation = gql`
  mutation timelessQuiz($input: TimelessQuizInput!) {
    timelessQuiz(input: $input) {
      id
      league {
        id
        slug
      }
    }
  }
`

const useTimelessQuizMutation = () => {
  return useMutation<
    { timelessQuiz: { id: string; league?: { id: string; slug: string } } },
    { input: TimelessQuizInput }
  >(timelessQuizMutation)
}

export type CategoryWithQuestionCount = {
  id: string
  title: string
  questionsCount: number
}

const timelessCategoriesQuery = gql`
  query timelessCategories($limit: Int) {
    timelessCategories(limit: $limit) {
      id
      title
      questionsCount
    }
  }
`

const useTimelessCategoriesQuery = (limit: number) => {
  return useQuery<{ timelessCategories: CategoryWithQuestionCount[] }>(
    timelessCategoriesQuery,
    { variables: { limit }, fetchPolicy: 'cache-and-network' }
  )
}

/** @jsxImportSource @emotion/react */
export const GenerateQuizButton = ({
  className,
  backgroundColor = colors.yellow400,
  textColor = colors.black,
  showBetaChip = true,
  textSize = 24,
}: {
  className?: string
  backgroundColor?: string
  textColor?: string
  showBetaChip?: boolean
  textSize?: number
}) => {
  const t = useT()

  const isEmbed = useIsEmbed()

  if (isEmbed) {
    return null
  }

  return (
    <Flex
      column
      horizontal="center"
      gap={'tiny'}
      className={className}
      css={css`
        position: relative;
        width: fit-content;
        margin: 0 auto;
      `}
    >
      {showBetaChip && (
        <div
          css={css`
            position: absolute;
            top: -12px;
            left: -20px;
            background-color: ${textColor};
            padding: 4px 24px;
            font-size: 12px;
            font-weight: 500;
            border-radius: 16px;
            z-index: 1;
            box-shadow: ${theme.boxShadows.default};
          `}
        >
          <TextNew color={backgroundColor} condensed italic size={16}>
            BETA
          </TextNew>
        </div>
      )}
      <ModalTrigger
        button={({ openModal }) => (
          <IconButton
            rounded
            backgroundColor={backgroundColor}
            textColor={textColor}
            onClick={openModal}
            noWrap
            text={
              <TextNew
                italic
                condensed
                color={textColor}
                weight={600}
                size={textSize}
                css={css`
                  margin-left: ${showBetaChip ? 16 : 0}px;
                `}
              >
                {t('Play a generated quiz')}
              </TextNew>
            }
            icon={
              <TextNew color={textColor} size={28}>
                {'🤖'}
              </TextNew>
            }
            css={css`
              box-shadow: ${theme.boxShadows.default};
            `}
          />
        )}
        modal={({ closeModal }) => (
          <GenerateQuizModal closeModal={closeModal} />
        )}
      />
    </Flex>
  )
}

const GenerateQuizModal = ({ closeModal }: { closeModal: () => void }) => {
  const t = useT()

  const categoriesQuery = useTimelessCategoriesQuery(3)

  const categoriesLoading = categoriesQuery.loading

  const [selectedCategory, setSelectedCategory] = useState<string | undefined>(
    undefined
  )

  const [refreshButtonDisabled, setRefreshButtonDisabled] = useState(false)

  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (refreshButtonDisabled) {
        setRefreshButtonDisabled(false)
      }
    }, 1500)

    return () => clearTimeout(timeOut)
  }, [refreshButtonDisabled])

  const { authUser, signInAsAnonymous } = useAuthContext()
  const [mutation, { loading: mutateLoading }] = useTimelessQuizMutation()
  const [startLeagueQuizMutation, { loading: startLoading }] =
    useStartLeagueQuizMutation()

  const loading = mutateLoading || startLoading || categoriesLoading

  const loadingTexts = [
    t('Kicking off the quiz...'),
    t('Dribbling through questions...'),
    t('Scoring some knowledge points...'),
    t('Warming up the pitch...'),
    t('Defending the answers...'),
    t('Preparing the lineup...'),
    t('Strategizing the next move...'),
    t('Checking for offside...'),
    t('Checking for fouls...'),
    t('Calling mom...'),
    t('Arguing with the referee...'),
    t('Faking an injury...'),
  ]

  const [loadingText, setLoadingText] = useState(t('Loading...'))

  const navigate = useNavigate()

  useEffect(() => {
    // update loadingText every 3 seconsds
    const interval = setInterval(() => {
      setLoadingText(
        loadingTexts[Math.floor(Math.random() * loadingTexts.length)]
      )
    }, 3000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  const spinAnimation = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  `

  const textColor = colors.black

  const categories = categoriesQuery.data?.timelessCategories || []

  return (
    <Modal closeModal={closeModal}>
      <Flex column gap={'large'}>
        <TextNew color={textColor} italic condensed size={32} weight={600}>
          {t(`Pick a category to generate from:`)}
        </TextNew>
        <Flex wrap gap={'small'}>
          <>
            {categories.map((category) => {
              const isSelected = selectedCategory === category.id

              return (
                <PrimaryButton
                  noWrap
                  rounded
                  key={category.id}
                  css={css`
                    background-color: ${isSelected
                      ? colors.yellow400
                      : colors.yellow100};
                  `}
                  onClick={() => {
                    if (isSelected) {
                      setSelectedCategory(undefined)
                      return
                    }

                    setSelectedCategory(category.id)
                  }}
                >
                  <TextNew color={textColor} size={16} css={css``}>
                    {category.title}
                  </TextNew>
                </PrimaryButton>
              )
            })}
            <IconButton
              disabled={refreshButtonDisabled}
              loading={categoriesLoading}
              text={
                <TextNew color={colors.white} size={16} weight={400}>
                  {t('Refresh')}
                </TextNew>
              }
              icon={<BiRefresh size={28} />}
              backgroundColor={
                refreshButtonDisabled ? colors.grey200 : colors.black
              }
              noWrap
              rounded
              onClick={() => {
                setRefreshButtonDisabled(true)
                setSelectedCategory(undefined)

                categoriesQuery.refetch()
              }}
              css={css`
                width: fit-content;
              `}
            />
          </>
        </Flex>

        <IconButton
          rounded
          backgroundColor={colors.yellow400}
          textColor={textColor}
          noWrap
          text={
            <TextNew
              italic
              condensed
              color={textColor}
              weight={600}
              size={24}
              css={css`
                margin-left: 16px;
              `}
            >
              {loading ? loadingText : t('Play a generated quiz')}
            </TextNew>
          }
          icon={
            <TextNew
              color={textColor}
              size={28}
              css={[
                loading &&
                  css`
                    animation: ${spinAnimation} 1s infinite linear;
                  `,
              ]}
            >
              {loading ? '⚽️' : '🤖'}
            </TextNew>
          }
          css={css`
            box-shadow: ${theme.boxShadows.default};
          `}
          onClick={async () => {
            if (!authUser) {
              await signInAsAnonymous()
            }

            const res = await mutation({
              variables: {
                input: { generate: true, categoryId: selectedCategory },
              },
            })

            if (!res.data) {
              return
            }

            const quiz = res.data.timelessQuiz

            if (!quiz) {
              return
            }

            startLeagueQuizMutation({
              variables: { input: { leagueQuizId: quiz.id } },
            }).then((result) => {
              const quizInstanceId = result.data?.startLeagueQuiz?.id
              if (quizInstanceId) {
                trackEvent(AnalyticsEventType.StartQuiz, {
                  quizId: quiz.id,
                  leagueId: quiz.league?.id,
                  isEmbed: false,
                  isGenerated: true,
                  categoryId: selectedCategory,
                })
                navigate(`/play-quiz/${quizInstanceId}`)
              }
            })
          }}
        />
      </Flex>
    </Modal>
  )
}
