/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import Lottie from 'lottie-react'
import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  AD_CAMPAIGN_DISPLAY_TIME_MS,
  AD_CAMPAIGN_HEADER_COLLAPSE_WHEN_TIME_LEFT_MS,
} from '../../constants'
import { useT } from '../../lib/i18n/useT'
import { fadeIn, growAndFade } from '../../styles/animations'
import { Spacer, margin } from '../../styles/margin'
import { colors } from '../../styles/theme'
import { useInterval } from '../../utils/useInterval'
import { titleFromQuiz } from '../../utils/util'

import { EnablePushNotificationsBanner } from '../../views/Home/EnablePushNotificationsBanner'
import { Badge } from '../../views/League/queries'
import { EnableNotifications } from '../../views/LeagueQuiz/EnableNotifications'
import { useLeagueQuizContext } from '../../views/LeagueQuiz/LeagueQuizContext'
import {
  CareerPathQuizInstance,
  LeagueQuizInstance,
  useLeagueInstanceViewerRankingQuery,
} from '../../views/LeagueQuiz/queries'
import { PrimaryButton } from '../Button'
import { Heading } from '../Heading'
import { Text } from '../Text'
import { BadgeModalContent } from '../badges/BadgeModalContent'
import { ChallengeButton } from '../challenge/ChallengeButton'
import { Flex } from '../layout/Flex'
import { Modal, ModalTrigger } from '../modal/Modal'
import { Page } from './Page'
import { PlayMoreQuizzes } from './PlayMoreQuizzes'
import { QuizScoreComponent } from './QuizScoreComponent'
import confetti from './assets/lottie/confetti.json'
import { GenerateQuizButton } from '../GenerateQuizButton'

export const FinishedQuizV2: FC<{
  leagueQuizInstance: LeagueQuizInstance | CareerPathQuizInstance
  playAnimation?: boolean
  newBadges: Badge[]
}> = ({ leagueQuizInstance, playAnimation = false, newBadges }) => {
  const t = useT()
  const initialRemaining = !playAnimation ? 0 : AD_CAMPAIGN_DISPLAY_TIME_MS

  const [showBadgeModal, setShowBadgeModal] = useState(false)
  const [playConfetti, setPlayConfetti] = useState(false)

  const [remaining, setRemaining] = useState(initialRemaining)
  const [timerRunning] = useState(playAnimation)
  const {
    setIsExpandedAdActive,
    isExpandedAdActive,
    videoAdActive,
    hasSanityCampaign,
    clubId,
    isGenerated,
  } = useLeagueQuizContext()

  const viewerRankQuery = useLeagueInstanceViewerRankingQuery(
    leagueQuizInstance.id
  )

  const navigate = useNavigate()

  useInterval(
    () => {
      setRemaining((remaining) => remaining - 100) // decrease by 10 every 10 milliseconds
    },
    remaining <= 0 || !timerRunning ? null : 100 // run every 10 milliseconds
  )

  useEffect(() => {
    if (playAnimation) {
      setIsExpandedAdActive(true)
    }
  }, [])

  useEffect(() => {
    if (remaining <= AD_CAMPAIGN_HEADER_COLLAPSE_WHEN_TIME_LEFT_MS) {
      setIsExpandedAdActive(false)
    }
  }, [remaining])

  useEffect(() => {
    if (newBadges.length > 0 && remaining <= 0 && !videoAdActive) {
      setPlayConfetti(true)
      setShowBadgeModal(true)
    }
  }, [newBadges, remaining, videoAdActive])

  useEffect(() => {
    if (playConfetti) {
      setTimeout(() => {
        setPlayConfetti(false)
      }, 5000)
    }
  }, [playConfetti])

  const textColor = colors.black

  const GetCtaButton = () => {
    if (isGenerated) {
      return (
        <PrimaryButton
          onClick={() => {
            navigate(`/`, {
              replace: true,
            })
          }}
          css={[
            margin.top('large'),
            css`
              height: 50px;
            `,
          ]}
        >
          {t('Go to the home page')}
        </PrimaryButton>
      )
    }

    if (clubId) {
      return (
        <PrimaryButton
          onClick={() => {
            navigate(`/clubs/${clubId}`, {
              replace: true,
            })
          }}
          css={[
            margin.top('large'),
            css`
              height: 50px;
            `,
          ]}
        >
          {t('To the club page')}
        </PrimaryButton>
      )
    }

    return (
      <PrimaryButton
        onClick={() => {
          navigate(`/series/${leagueQuizInstance.leagueQuiz.league.id}`, {
            replace: true,
          })
        }}
        css={[
          margin.top('large'),
          css`
            height: 50px;
          `,
        ]}
      >
        {t('To the series page')}
      </PrimaryButton>
    )
  }

  return (
    <Page showFcQuizLogo logoColor={colors.grey200}>
      <Flex
        column
        horizontal={'center'}
        vertical={'space-between'}
        css={css`
          height: 100%;
          padding-top: 16px;
          padding-bottom: 16px;
          margin: 0 auto;
          width: 100%;
        `}
      >
        {showBadgeModal && (
          <ModalTrigger
            forceIsOpen={showBadgeModal}
            button={() => (
              <div
                css={css`
                  position: absolute;
                  width: 0;
                  height: 0;
                `}
              />
            )}
            modal={({ closeModal }) => (
              <Modal
                heading={
                  newBadges.length > 1
                    ? t('New badges unlocked!')
                    : t('New badge unlocked!')
                }
                closeModal={closeModal}
                maxWidth={500}
                css={css`
                  text-align: center;
                `}
                transparent
              >
                <BadgeModalContent badges={newBadges} onClose={closeModal} />
              </Modal>
            )}
          />
        )}
        <Flex
          column
          horizontal={'center'}
          gap={'medium'}
          css={css`
            width: 100%;
          `}
        >
          {(!isExpandedAdActive || !hasSanityCampaign) && (
            <Text color={colors.grey400} size={'small'}>
              {titleFromQuiz(leagueQuizInstance.leagueQuiz, t)}
            </Text>
          )}

          <QuizScoreComponent
            questionsCount={leagueQuizInstance.leagueQuiz.questionsCount}
            result={leagueQuizInstance.result}
            remaining={remaining}
            totalCalculationTime={initialRemaining}
            playAnimation={playAnimation}
          />
        </Flex>
        {!videoAdActive && remaining <= 2000 && (
          <Flex
            column
            vertical={'center'}
            css={[
              margin.top('large'),
              css`
                opacity: 0;
                animation: ${playAnimation ? growAndFade : fadeIn} 350ms
                  cubic-bezier(0.4, 0, 0.15, 1);
                animation-delay: 600ms;
                animation-fill-mode: forwards;
                width: 100%;
              `,
            ]}
          >
            {viewerRankQuery.data?.leagueQuizInstance
              ?.viewerLeaderboardEntry && (
              <>
                <ViewerLeaderboardEntryComponent
                  entry={
                    viewerRankQuery.data?.leagueQuizInstance
                      ?.viewerLeaderboardEntry
                  }
                  participantCount={
                    leagueQuizInstance.leaderboardParticipantCount
                  }
                />
                <Spacer height={16} />
              </>
            )}
            {leagueQuizInstance.quizType === 'text' && (
              <>
                <GenerateQuizButton
                  css={css`
                    margin-bottom: 24px;
                  `}
                />
                <ChallengeButton instanceId={leagueQuizInstance.id} />
              </>
            )}
            <Spacer height={32} />
            <Heading level={5} color={textColor}>
              {t('Do you want to play more?')}
            </Heading>
            <Spacer height={16} />
            <PlayMoreQuizzes />

            <Spacer height={24} />
            <GetCtaButton />
            <EnableNotifications textColor={textColor} />
            <EnablePushNotificationsBanner css={margin.top('large')} />
          </Flex>
        )}
        {playConfetti && (
          <Lottie
            animationData={confetti}
            loop={false}
            style={{
              position: 'absolute',
              top: 0,
              width: '100%',
              height: '100%',
              zIndex: 1000000000,
              // allow clicks to go through
              pointerEvents: 'none',
            }}
          />
        )}
      </Flex>
    </Page>
  )
}

const ViewerLeaderboardEntryComponent = ({
  entry,
  participantCount = 1,
}: {
  entry: { rank: number }

  participantCount: number | null
}) => {
  const t = useT()

  if (participantCount === null) {
    participantCount = 1
  }

  const isFirstPlace = entry.rank === 1

  const textColor = isFirstPlace ? colors.yellow500 : colors.black

  return (
    <Flex column horizontal="center">
      <Text
        color={textColor}
        textAlign="center"
        size={isFirstPlace ? 'massive' : 'huge'}
        extraCondensed
        strong
      >
        {t('{{rank}}. place', {
          rank: entry.rank,
        })}
      </Text>
      <Text color={colors.grey400} textAlign="center" size={'small'} condensed>
        {t('{{count}} player_other', {
          count: participantCount,
        })}
      </Text>
    </Flex>
  )
}
