/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useState } from 'react'
import { FaArrowRight } from 'react-icons/fa'
import { useSearchParams } from 'react-router-dom'
import { Flex } from '../../../components/layout/Flex'
import { TextNew } from '../../../components/TextNew'
import { useT } from '../../../lib/i18n/useT'
import { Spacer } from '../../../styles/margin'
import { colors } from '../../../styles/theme'
import { ChallengeNavItem } from '../../Challenge/ChallengeGroupPage'
import { League } from '../queries'
import { LeagueLeaderboardV2 } from './LeagueLeaderBoardV2'
import { LeagueQuizLeaderBoardV2 } from './LeagueQuizLeaderBoardV2'

export const LeaderboardV2 = ({
  league,
  selectedLeagueQuizId,
  goToTotal,
}: {
  league: League
  selectedLeagueQuizId?: string
  goToTotal: () => void
}) => {
  const t = useT()
  const [searchParams] = useSearchParams()
  const clubId = searchParams.get('club') ?? undefined

  const [selectedTab, setSelectedTab] = useState<'users' | 'clubs' | 'friends'>(
    league.hasSubscribedClubs && league.showClubsLeaderboard ? 'clubs' : 'users'
  )

  console.log('selectedLeagueQuizId', selectedLeagueQuizId)

  const showClubsTab = league.showClubsLeaderboard

  return (
    <Flex column>
      <Flex
        vertical="center"
        horizontal="space-between"
        css={css`
          height: 48px;
        `}
      >
        <Flex gap="small" css={css``}>
          {showClubsTab && (
            <ChallengeNavItem
              title={t('Clubs')}
              onClick={() => setSelectedTab('clubs')}
              selected={selectedTab === 'clubs'}
              textColor="white"
            />
          )}
          <ChallengeNavItem
            title={t(`Players`)}
            onClick={() => setSelectedTab('users')}
            selected={selectedTab === 'users'}
            textColor="white"
          />
          <ChallengeNavItem
            title={t('Friends')}
            onClick={() => setSelectedTab('friends')}
            selected={selectedTab === 'friends'}
            textColor="white"
          />
        </Flex>

        <Flex vertical={'center'} gap={'tiny'}>
          {/* Selected quiz title */}
          <TextNew
            condensed
            strong
            italic
            color={colors.white}
            textAlign="center"
            css={css`
              font-size: 21px;
              line-height: 24px;
              font-style: italic;
              padding-bottom: 2px;
            `}
            onClick={goToTotal}
          >
            {t('Total for the series')}
          </TextNew>
          {selectedLeagueQuizId && <FaArrowRight color={colors.white} />}
        </Flex>
      </Flex>

      <Spacer height="small" />

      {selectedLeagueQuizId ? (
        <LeagueQuizLeaderBoardV2
          leagueQuizId={selectedLeagueQuizId}
          clubId={clubId}
          selectedTab={selectedTab}
        />
      ) : (
        <LeagueLeaderboardV2
          league={league}
          clubId={clubId}
          selectedTab={selectedTab}
        />
      )}

      <Spacer height="huge" />
    </Flex>
  )
}
