/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useEffect, useState } from 'react'
import { SecondaryButton } from '../../../components/Button'
import { Heading } from '../../../components/Heading'
import { Flex } from '../../../components/layout/Flex'
import { Modal } from '../../../components/modal/Modal'
import { SelectInput } from '../../../components/SelectInput'
import { TextNew } from '../../../components/TextNew'
import { rootWebAppUrl } from '../../../config/config'
import { getCurrentLocale, Locale } from '../../../lib/i18n/locale'
import { useT } from '../../../lib/i18n/useT'
import { margin } from '../../../styles/margin'
import theme, { colors } from '../../../styles/theme'
import { useLeaguePageQuery } from '../../League/queries'

export const EmbedCodeModal = ({
  clubLeagueSubscriptionId,
  leagueId,
  closeModal,
  quizId,
  clubLocale,
}: {
  clubLeagueSubscriptionId?: string
  leagueId?: string
  quizId?: string
  closeModal: () => void
  clubLocale?: Locale
}) => {
  const t = useT()
  const language = clubLocale ?? getCurrentLocale()
  const [locale, onLocale] = useState<Locale>(language ?? 'no')

  const [selectedQuiz, setSelectedQuiz] = useState<string | undefined>()

  const leagueQuery = useLeaguePageQuery(leagueId ?? '', !leagueId)

  const leagueQuizzes = leagueQuery.data?.league?.activeQuizzes ?? []

  const selectedQuizTitle = selectedQuiz
    ? leagueQuizzes.find((q) => q.id === selectedQuiz)?.title
    : undefined

  const getEmbedPageLink = () => {
    if (clubLeagueSubscriptionId) {
      return `${rootWebAppUrl()}/${locale}/embed/club-league/${clubLeagueSubscriptionId}${
        selectedQuiz ? `/${selectedQuiz}` : ''
      }`
    }
    if (leagueId) {
      return `${rootWebAppUrl()}/${locale}/embed/league/${leagueId}/${
        quizId ?? 'current'
      }`
    }
    return `${rootWebAppUrl()}/${locale}/embed/quiz/${quizId}/${leagueId}`
  }

  const embedCode = `
  <iframe 
    src="${getEmbedPageLink()}"
    width="100%"
    height="550"
    frameBorder="0">
  </iframe>`

  const [copied, setCopied] = useState(false)
  useEffect(() => {
    const timeout = setTimeout(() => setCopied(false), 3000)
    return () => clearTimeout(timeout)
  }, [copied])

  return (
    <Modal
      heading={'Embed'}
      closeModal={closeModal}
      styling={css`
        background-color: ${theme.colors.green200};
        text-align: center;
      `}
      textColor={theme.colors.black}
    >
      <Flex
        column
        css={css`
          text-align: center;
          max-width: 590px;
          margin: 0 auto;
        `}
      >
        <Flex css={margin.vertical()}>
          <LanguagePicker locale={locale} onLocale={onLocale} />
        </Flex>
        <Flex
          column
          horizontal="flex-start"
          gap={'medium'}
          css={css`
            margin: 16px 0;
            border: 1px solid ${colors.black};
            padding: 16px;
            border-radius: 8px;
          `}
        >
          <TextNew textAlign="left" weight={600} size="large">
            {t(`Choose quiz`)}
          </TextNew>
          <SelectInput
            value={selectedQuiz}
            onValue={(value) => {
              const uuidV4Regex =
                /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i

              const validUuid = uuidV4Regex.test(value)

              if (!validUuid) {
                setSelectedQuiz(undefined)
                return
              }

              setSelectedQuiz(value)
            }}
          >
            <option value={undefined}>{t(`Newest quiz`)}</option>
            {[...leagueQuizzes].reverse().map((quiz) => (
              <option key={quiz.id} value={quiz.id}>
                {quiz.title}
              </option>
            ))}
          </SelectInput>

          <TextNew textAlign="left" size="small" italic>
            {selectedQuiz
              ? t(`Will always show: {{name}}`, {
                  name: selectedQuizTitle,
                })
              : t(`Will always show the newest quiz`)}
          </TextNew>
        </Flex>

        <Flex vertical="center" horizontal="space-between">
          <Heading level={5} css={margin.vertical()}>
            {t('Code')}
          </Heading>

          <Flex horizontal="center" gap="small">
            {copied && <TextNew size="small">{t('Copied!')}</TextNew>}
            <SecondaryButton
              noWrap
              size="small"
              onClick={() => {
                navigator.clipboard.writeText(embedCode)
                setCopied(true)
              }}
            >
              {t('Copy embed code')}
            </SecondaryButton>
          </Flex>
        </Flex>
        <pre
          css={css`
            background: ${colors.grey100};
            padding: 16px;
            font-family: monospace;
            border-radius: 2px;
            font-size: 14px;
            overflow: auto;
            text-align: left;
          `}
        >
          {embedCode}
        </pre>
        <Flex
          column
          vertical="center"
          horizontal="space-between"
          css={margin.top('huge')}
        >
          <Heading
            level={5}
            css={[
              margin.vertical(),
              css`
                text-align: left;
              `,
            ]}
          >
            {t('Preview')}
          </Heading>
          <iframe
            src={getEmbedPageLink()}
            width="100%"
            height="550"
            frameBorder="0"
          />
        </Flex>
      </Flex>
    </Modal>
  )
}

export const LanguagePicker = ({
  locale,
  onLocale,
  className,
}: {
  locale: Locale
  onLocale: (locale: Locale) => void
  className?: string
}) => {
  const t = useT()
  return (
    <SelectInput
      label=""
      value={locale}
      onValue={(value) => onLocale(value as Locale)}
      textColor={'black'}
      className={className}
    >
      {!locale && <option value={undefined}>{t('Choose language...')}</option>}
      <option value={Locale.no}>🇳🇴 Norsk</option>
      <option value={Locale.en}>🇬🇧 English</option>
      <option value={Locale.es}>🇪🇸 Español</option>
      <option value={Locale.de}>🇩🇪 Deutsch</option>
      <option value={Locale.fr}>🇫🇷 Francese</option>
      <option value={Locale.it}>🇮🇹 Italiano</option>
      <option value={Locale.pt}>Português</option>
      <option value={Locale.ar}>عربي</option>
      <option value={Locale.ja}>🇯🇵 日本語</option>
      <option value={Locale.sv}>🇸🇪 Svenska</option>
      <option value={Locale.da}>🇩🇰 Dansk</option>
      <option value={Locale.th}>🇹🇭 Thai</option>
    </SelectInput>
  )
}
