import { CareerPathTeam, Fixture } from '../queries'
import { Question, QuizType } from '../GeneratePage'
import { Alternative } from './PredicitonQuestionForm'
import { v4 as uuidv4 } from 'uuid'

// type StatType =
//   | 'result'
//   | 'goals'
//   | 'shotsOnGoal'
//   | 'shotsOffGoal'
//   | 'shotsInsideBox'
//   | 'shotsOutsideBox'
//   | 'totalShots'
//   | 'blockedShots'
//   | 'fouls'
//   | 'cornerKicks'
//   | 'offsides'
//   | 'ballPossession'
//   | 'yellowCards'
//   | 'redCards'
//   | 'goalkeeperSaves'
//   | 'totalPasses'
//   | 'passesAccurate'
//   | 'passesPercentage'

type StatType =
  | 'result'
  | 'halftimeResult'
  | 'firstGoal'
  | 'fouls'
  | 'cornerKicks'
  | 'ballPossession'
  | 'redCard'
  | 'penalty'
  | 'firstYellowCard'
  | 'twoPointFiveGoals'
  | 'bothTeamsScore'

export function generatePredictionQuestions(
  fixture: Fixture,
  leagueSlug: string
) {
  const questions: Question[] = []
  const homeTeam = fixture.homeTeam
  const awayTeam = fixture.awayTeam

  let questionIndex = 0 // To assign unique question indexes

  // Get 8 random stat types
  const selectedStatTypes = ['result' as StatType, getRandomStatTypes()].flat()

  for (const statType of selectedStatTypes) {
    if (statQuestionTemplates[statType]) {
      const { template, alternatives } = statQuestionTemplates[statType]

      // Create question text and alternatives
      const questionText = template(0) // Passing a dummy value as `value` is not used in this context
      const questionAlternatives = alternatives(homeTeam, awayTeam)

      // Create a Question object
      const question: Question = {
        id: uuidv4(),
        question: questionText,
        alternatives: questionAlternatives,
        timeless: false,
        aiGenerated: false,
        referenceUrl: '',
        questionIndex: questionIndex++,
        questionLimit: 8,
        madeByUser: true,
        leagueSlug: leagueSlug,
        questionType: QuizType.PREDICTION,
      }

      questions.push(question)
    }
  }

  return questions
}

function getRandomStatTypes(): StatType[] {
  const allStatTypes: StatType[] = Object.keys(statQuestionTemplates).filter(
    (statType) => statType !== 'result'
  ) as StatType[]

  const selectedStatTypes: Set<StatType> = new Set()

  while (selectedStatTypes.size < 7) {
    const randomIndex = Math.floor(Math.random() * allStatTypes.length)
    selectedStatTypes.add(allStatTypes[randomIndex])
  }

  return Array.from(selectedStatTypes)
}

const statQuestionTemplates: Record<
  StatType,
  {
    template: (value: number) => string
    alternatives: (
      homeTeam: CareerPathTeam,
      awayTeam: CareerPathTeam
    ) => Alternative[]
  }
> = {
  result: {
    template: () => `Which team will win the match?`,
    alternatives: (homeTeam, awayTeam) => [
      { text: homeTeam.name, reference: homeTeam.id, index: 1 },
      { text: 'Draw', index: 2 },
      { text: awayTeam.name, reference: awayTeam.id, index: 3 },
    ],
  },

  halftimeResult: {
    template: () => `Which team will win the first half?`,
    alternatives: (homeTeam, awayTeam) => [
      { text: homeTeam.name, reference: homeTeam.id, index: 1 },
      { text: 'Draw', index: 2 },
      { text: awayTeam.name, reference: awayTeam.id, index: 3 },
    ],
  },
  firstGoal: {
    template: () => `Which team will score the first goal?`,
    alternatives: (homeTeam, awayTeam) => [
      { text: homeTeam.name, reference: homeTeam.id, index: 1 },
      { text: awayTeam.name, reference: awayTeam.id, index: 2 },
    ],
  },
  fouls: {
    template: () => `Which team will commit more fouls?`,
    alternatives: (homeTeam, awayTeam) => [
      { text: homeTeam.name, reference: homeTeam.id, index: 1 },
      { text: awayTeam.name, reference: awayTeam.id, index: 2 },
    ],
  },
  cornerKicks: {
    template: () => `Which team will have more corner kicks?`,
    alternatives: (homeTeam, awayTeam) => [
      { text: homeTeam.name, reference: homeTeam.id, index: 1 },
      { text: awayTeam.name, reference: awayTeam.id, index: 2 },
    ],
  },
  ballPossession: {
    template: () => `Which team will have a higher ball possession?`,
    alternatives: (homeTeam, awayTeam) => [
      { text: homeTeam.name, reference: homeTeam.id, index: 1 },
      { text: awayTeam.name, reference: awayTeam.id, index: 2 },
    ],
  },
  redCard: {
    template: () => `Will there be any red cards issued in this game?`,
    alternatives: () => [
      { text: 'Yes', index: 1 },
      { text: 'No', index: 2 },
    ],
  },
  penalty: {
    template: () => `Will there be any penalties in this game?`,
    alternatives: () => [
      { text: 'Yes', index: 1 },
      { text: 'No', index: 2 },
    ],
  },
  firstYellowCard: {
    template: () => `Which team will receive the first yellow card?`,
    alternatives: (homeTeam, awayTeam) => [
      { text: homeTeam.name, reference: homeTeam.id, index: 1 },
      { text: awayTeam.name, reference: awayTeam.id, index: 2 },
      { text: 'No yellow cards issued', index: 3 },
    ],
  },
  twoPointFiveGoals: {
    template: () => `Will there be more than 2.5 goals in this game?`,
    alternatives: () => [
      { text: 'Yes', index: 1 },
      { text: 'No', index: 2 },
    ],
  },
  bothTeamsScore: {
    template: () => `Will both teams score in this game?`,
    alternatives: () => [
      { text: 'Yes', index: 1 },
      { text: 'No', index: 2 },
    ],
  },
}

/*
 * This function generates prediction questions for multiple fixtures.
 * All the questions are Who will win the match? type questions.
 */
export function getQuestionsForMultipleFixtures(fixtures: Fixture[]) {
  const questions: Question[] = []

  for (const fixture of fixtures) {
    const homeTeam = fixture.homeTeam
    const awayTeam = fixture.awayTeam

    const question: Question = {
      id: uuidv4(),
      question: `Who will win the match between ${homeTeam.name} and ${awayTeam.name}?`,
      alternatives: [
        { text: homeTeam.name, reference: homeTeam.id, index: 1 },
        { text: 'Draw', index: 2 },
        { text: awayTeam.name, reference: awayTeam.id, index: 3 },
      ],
      timeless: false,
      aiGenerated: false,
      referenceUrl: '',
      questionIndex: 0,
      questionLimit: 8,
      madeByUser: true,
      leagueSlug: '',
      questionType: QuizType.PREDICTION,
    }

    questions.push(question)
  }

  return questions
}

// goals: {
//   template: () =>
//     `Will there be more than a certain number of goals scored in this match?`,
//   alternatives: () => [
//     { text: 'Yes', index: 1 },
//     { text: 'No', index: 2 },
//   ],
// },
// shotsOnGoal: {
//   template: () =>
//     `Which  team will have the most shots on goal in this match?`,
//   alternatives: () => [
//     { text: 'Over', index: 1 },
//     { text: 'Under', index: 2 },
//   ],
// },
// shotsOffGoal: {
//   template: () =>
//     `Will the shots off goal be above or below a certain number?`,
//   alternatives: () => [
//     { text: 'Over', index: 1 },
//     { text: 'Under', index: 2 },
//   ],
// },
// shotsInsideBox: {
//   template: () => `How many shots will be taken inside the box?`,
//   alternatives: () => [
//     { text: 'Over', index: 1 },
//     { text: 'Under', index: 2 },
//   ],
// },
// shotsOutsideBox: {
//   template: () => `How many shots will be taken outside the box?`,
//   alternatives: () => [
//     { text: 'Over', index: 1 },
//     { text: 'Under', index: 2 },
//   ],
// },
// totalShots: {
//   template: () => `What will be the total number of shots in this match?`,
//   alternatives: () => [
//     { text: 'Over', index: 1 },
//     { text: 'Under', index: 2 },
//   ],
// },
// blockedShots: {
//   template: () => `How many shots will be blocked in this game?`,
//   alternatives: () => [
//     { text: 'Over', index: 1 },
//     { text: 'Under', index: 2 },
//   ],
// },
// yellowCards: {
//   template: () =>
//     `Will there be more than a certain number of yellow cards issued?`,
//   alternatives: () => [
//     { text: 'Yes', index: 1 },
//     { text: 'No', index: 2 },
//   ],
// },
// redCards: {
//   template: () => `Will there be any red cards issued in this game?`,
//   alternatives: () => [
//     { text: 'Yes', index: 1 },
//     { text: 'No', index: 2 },
//   ],
// },
// goalkeeperSaves: {
//   template: () =>
//     `Will the number of goalkeeper saves be above or below a certain number?`,
//   alternatives: () => [
//     { text: 'Over', index: 1 },
//     { text: 'Under', index: 2 },
//   ],
// },
// totalPasses: {
//   template: () => `Will the total number of passes exceed a specific number?`,
//   alternatives: () => [
//     { text: 'Over', index: 1 },
//     { text: 'Under', index: 2 },
//   ],
// },
// passesAccurate: {
//   template: () =>
//     `Will the number of accurate passes exceed a certain number?`,
//   alternatives: () => [
//     { text: 'Over', index: 1 },
//     { text: 'Under', index: 2 },
//   ],
// },
// passesPercentage: {
//   template: () =>
//     `Will the pass accuracy percentage be above or below a certain threshold?`,
//   alternatives: () => [
//     { text: 'Over', index: 1 },
//     { text: 'Under', index: 2 },
//   ],
// },
