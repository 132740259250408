/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PrimaryButton, SecondaryButton } from '../../components/Button'
import { AnalyticsEventType, useTrackEvent } from '../../lib/analytics'
import { useT } from '../../lib/i18n/useT'
import { fadeIn, growAndFade } from '../../styles/animations'
import { Spacer } from '../../styles/margin'
import { colors } from '../../styles/theme'
import { useLeagueQuizContext } from '../LeagueQuiz/LeagueQuizContext'
import { CompletedQuestion, useLeagueQuizFinished } from '../LeagueQuiz/queries'

import { Flex } from '../../components/layout/Flex'
import { PageContent } from '../../components/layout/PageContent'
import { TopPlayers } from '../../components/leagueQuiz/TopPlayers'
import { TextNew } from '../../components/TextNew'
import PredictionResults from './components/PredictionResults'

interface Props {
  clubLeagueSubscriptionId?: string
  title: string
  playAnimation: boolean
  quizId: string
  isEmbed: boolean
  completedQuestions?: CompletedQuestion[]
  endDateTime?: string
  leagueSlug?: string
  hasCampaign?: boolean
}

const FinishedQuiz = (props: Props) => {
  const {
    clubLeagueSubscriptionId,
    playAnimation,
    quizId,
    title,
    isEmbed,
    completedQuestions: questions,
    leagueSlug,
    hasCampaign = false,
  } = props

  const t = useT()

  const leagueQuizFinished = useLeagueQuizFinished({ quizId, limit: 3 })

  const showResults =
    new Date(props.endDateTime ?? '') < new Date() &&
    questions &&
    questions.every((q) => q.alternatives.some((a) => a.isCorrectAlternative))

  const [playQuizResultsAnimation, setPlayQuizResultsAnimation] = useState(
    showResults ?? false
  )

  useTrackEvent(AnalyticsEventType.EmbedFinishedQuiz, {
    clubLeagueSubscriptionId,
    quizId,
  })

  const { setIsExpandedAdActive, videoAdActive } = useLeagueQuizContext()

  useEffect(() => {
    if (playAnimation && hasCampaign) {
      setIsExpandedAdActive(true)
    }
  }, [])

  useEffect(() => {
    if (hasCampaign) {
      setIsExpandedAdActive(false)
    }
  }, [hasCampaign])

  const navigate = useNavigate()

  const entries = leagueQuizFinished.data?.leagueQuiz?.leaderboard ?? []
  const friendEntries =
    leagueQuizFinished.data?.leagueQuiz?.friendsLeaderboard ?? []
  const hasFriendEntries = friendEntries.length > 0

  return (
    <Flex
      grow
      column
      horizontal="center"
      css={css`
        background-color: transparent;
        overflow-x: hidden;
        width: 100%;
      `}
    >
      <Flex
        column
        horizontal="center"
        grow
        css={css`
          width: 100%;
          flex-shrink: 0;
          margin: 0 auto;
        `}
      >
        {!showResults ? (
          <>
            <Spacer height={'large'} />
            <UserAnswers completedQuestions={props.completedQuestions} />
          </>
        ) : (
          questions && (
            <PredictionResults
              title={title}
              questions={questions}
              setPlayAnimation={setPlayQuizResultsAnimation}
              playAnimation={playQuizResultsAnimation}
            />
          )
        )}

        <Spacer height={'large'} />

        {playQuizResultsAnimation && (
          <Flex
            column
            horizontal="center"
            css={css`
              position: absolute;
              bottom: 0;
              margin-top: 20px;
            `}
          >
            <SecondaryButton
              onClick={() => {
                setPlayQuizResultsAnimation(false)
              }}
            >
              {t('Skip')}
            </SecondaryButton>
          </Flex>
        )}
        {!videoAdActive && !playQuizResultsAnimation && (
          <Flex
            column
            css={css`
              opacity: 0;
              animation: ${playAnimation ? growAndFade : fadeIn} 350ms
                cubic-bezier(0.4, 0, 0.15, 1);
              animation-delay: 600ms;
              animation-fill-mode: forwards;
              width: 100%;
            `}
          >
            <Spacer height={16} />
            {showResults && (
              <TopPlayers
                quizId={quizId}
                leagueSlug={leagueSlug}
                entries={hasFriendEntries ? friendEntries : entries}
              />
            )}

            {!isEmbed && (
              <>
                <Spacer height={'small'} />
                <PrimaryButton
                  onClick={() => {
                    navigate(`/series/${leagueSlug}`, {
                      replace: true,
                    })
                  }}
                >
                  {t('To the series page')}
                </PrimaryButton>
              </>
            )}
            <Spacer height={'large'} />
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}
export default FinishedQuiz

const UserAnswers = ({
  completedQuestions,
}: {
  completedQuestions?: CompletedQuestion[]
}) => {
  return (
    <PageContent
      column
      gap={'large'}
      css={css`
        width: 100%;
      `}
    >
      {completedQuestions?.map((question) => (
        <Flex
          key={question.id}
          column
          css={css`
            background-color: ${colors.grey100};
            border-radius: 8px;
          `}
        >
          <TextNew size={14} strong italic>
            {question.text}
          </TextNew>
          <Spacer height={'small'} />

          <Flex column gap={'small'}>
            {question.alternatives
              .filter((a) => a.selectedAlternative)
              .map((alternative) => (
                <Flex
                  key={alternative.id}
                  gap={'small'}
                  vertical={'center'}
                  css={css`
                    padding: 12px 16px;
                    background-color: ${colors.white};
                    border-radius: 8px;
                    border: 1px solid ${colors.grey300};
                  `}
                >
                  <TextNew
                    strong={alternative.selectedAlternative}
                    size={12}
                    color={
                      alternative.selectedAlternative
                        ? colors.black
                        : colors.grey400
                    }
                  >
                    {alternative.text}
                  </TextNew>
                </Flex>
              ))}
          </Flex>
        </Flex>
      ))}
    </PageContent>
  )
}
