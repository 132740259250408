/** @jsxImportSource @emotion/react */

import { useViewerQuery } from '../../../lib/AuthContext'
import { colors } from '../../../styles/theme'
import { Flex } from '../../../components/layout/Flex'
import { Header } from '../../../components/Header'
import { PageContent } from '../../../components/layout/PageContent'
import { Heading } from '../../../components/Heading'
import { Spacer } from '../../../styles/margin'
import { Layout } from '../../../components/layout/Layout'
import { useGetPredictionQuizInstancesByUserQuery } from '../queries'
import { PredictionQuizWaiting } from '../../Home/prediction/PromotedPredictionQuiz'

const Content = () => {
  const { data: viewerData } = useViewerQuery()
  const { data: userQuizzesData } = useGetPredictionQuizInstancesByUserQuery({
    userId: viewerData?.viewer?.id || '',
    skip: !viewerData?.viewer?.id,
  })

  return (
    <Flex column grow>
      <Header color="grey" />
      <PageContent>
        <Flex horizontal="space-between" vertical="center">
          <Heading level={1} looksLikeLevel={3} extraCondensed>
            {'Predictions'}
          </Heading>
        </Flex>
      </PageContent>

      {userQuizzesData &&
        userQuizzesData.getPredictionQuizzesByUser &&
        userQuizzesData.getPredictionQuizzesByUser
          .filter((quiz) => quiz.isMissingAnswers)
          .map((quiz) => <PredictionQuizWaiting quiz={quiz} />)}

      <Spacer height={80} />
    </Flex>
  )
}

export const PredictionsPage = () => {
  return (
    <Layout
      backgroundColor={colors.grey100}
      title={`fcQuiz | ${'Predictions'}`}
    >
      <Content />
    </Layout>
  )
}
