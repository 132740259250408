/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { Loader } from '../../components/Loader'
import { Flex } from '../../components/layout/Flex'
import { useAuthContext, useViewerQuery } from '../../lib/AuthContext'
import { Avatar, height, width } from './Avatar'
import { AvatarContextProvider, useAvatarContext } from './AvatarPage'
import { ReactComponent as UserAvatarAnonym } from './UserAvatarAnonym.svg'
import { makeAvatarParts } from './util'
import { Link } from 'react-router-dom'
import { colors, hexOpacity } from '../../styles/theme'
import { useEffect, useMemo, useState } from 'react'
import { makeBaseAvatar } from '../../common/avatarConfig'

const Content = (props: {
  avatarData: string | undefined
  width?: number
  height?: number

  clickable?: boolean
  className?: string
  showExport?: boolean
}) => {
  const { parts, setParts } = useAvatarContext()
  const className = props.className
  const [userId, setUserId] = useState<string | undefined>(undefined)
  const [userSlug, setUserSlug] = useState<string | undefined>(undefined)

  const isLoading = !parts || !userId

  const [isBase, setIsBase] = useState(false)

  useEffect(() => {
    const avatarData = props.avatarData
      ? JSON.parse(props.avatarData)
      : makeBaseAvatar()
    // userId / userSlug is not necessary for baseAvatar,
    // since they can use the same class without any problem
    setIsBase(avatarData.IsBase)
    setParts(makeAvatarParts(avatarData))
    setUserId(avatarData.userId)
    setUserSlug(avatarData.userSlug)
  }, [props.avatarData])

  // Some math to make it use the same width/height as the Avatar
  const aspectRatio = height / width
  const _width = props.width || (props.height ?? height) / aspectRatio
  const _height = _width * aspectRatio
  const FallBackContainer = (props: { children: any }) => (
    <Flex
      className={className}
      css={css`
        width: ${_width}px;
        height: ${_height}px;
      `}
      vertical="center"
      horizontal="center"
    >
      {props.children}
    </Flex>
  )

  if (isBase) {
    return (
      <FallBackContainer>
        <UserAvatarAnonym
          css={css`
            width: ${_width}px;
            height: ${_height}px;
            margin-bottom: -${_height / 6.5}px;
          `}
        />
      </FallBackContainer>
    )
  }

  if (isLoading) {
    return (
      <FallBackContainer>
        <Loader size={20} center />
      </FallBackContainer>
    )
  }

  const Content = () => (
    <Avatar
      width={props.width}
      height={props.height}
      BGColor={hexOpacity(colors.black, 0)}
      FGColor={colors.white}
      className={className}
      userId={userId!}
      showExport={props.showExport}
    />
  )

  if (props.clickable) {
    return (
      <Link
        className={className}
        css={css`
          display: flex;
        `}
        to={`/profile/${userSlug ?? userId}`}
        onClick={(e: any) => {
          e.stopPropagation()
        }}
      >
        <Content />
      </Link>
    )
  }

  return <Content />
}

export const ViewerAvatar = (props: {
  width?: number
  height?: number

  className?: string
}) => {
  const { authUser } = useAuthContext()
  const viewerQuery = useViewerQuery({ skip: !authUser })

  const avatarData = viewerQuery.data?.viewer?.avatarData
  return useMemo(
    () => (
      <AvatarContextProvider>
        <Content avatarData={avatarData} {...props} />
      </AvatarContextProvider>
    ),
    [avatarData, props]
  )
}

export const UserAvatar = (props: {
  avatarData: string | undefined
  width?: number
  height?: number

  clickable?: boolean
  className?: string
  showExport?: boolean
}) => {
  return (
    <AvatarContextProvider>
      <Content {...props} />
    </AvatarContextProvider>
  )
}
