/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react'
import { t } from 'i18next'
import { FC, useCallback, useEffect, useState } from 'react'
import { AVERAGE_MS_PER_WORD } from '../../constants'
import { useT } from '../../lib/i18n/useT'
import { expand, fadeIn } from '../../styles/animations'
import { margin, Spacer } from '../../styles/margin'
import theme, { colors } from '../../styles/theme'
import { useInterval } from '../../utils/useInterval'
import { useRefValue } from '../../utils/useRefValue'
import { useAnswerChallengeQuestionMutation } from '../../views/ChallengeMatch/queries'
import { useLeagueQuizContext } from '../../views/LeagueQuiz/LeagueQuizContext'
import {
  ActiveQuestion as ActiveQuestionType,
  LeagueQuiz as LeagueQuizType,
  useAlternativeReferenceQuery,
  useAnswerQuestionMutation,
  useSkipReadQuestionMutation,
} from '../../views/LeagueQuiz/queries'
import { Text } from '../Text'
import { ErrorMessage } from '../TextInput'
import { Flex } from '../layout/Flex'
import { PageContent } from '../layout/PageContent'
import { AlternativeButton } from './AlternativeButton'
import { QuizStatusComponent } from './QuizStatusComponent'

export const ReadQuestionScreen: FC<{
  leagueQuiz: LeagueQuizType
  numCompletedQuestions: number
  activeQuestion: ActiveQuestionType
  quizInstanceId: string
  colorTheme: 'red' | 'white' | 'green'
  isEmbed: boolean
}> = ({
  leagueQuiz,
  numCompletedQuestions,
  activeQuestion,
  quizInstanceId,
  colorTheme,
  isEmbed,
}) => {
  const initialTime = activeQuestion.timeToReadQuestionInMs
  const [remaining, setRemaining] = useState(initialTime)
  const [showSplash, setShowSplash] = useState(true)
  const [skipReadQuestionMutation] = useSkipReadQuestionMutation()
  const [skipped, setSkipped] = useState(false)

  const { campaignHeaderHeight } = useLeagueQuizContext()

  const isFirstQuestion = numCompletedQuestions === 0

  const skipReadQuestion = useCallback(
    () =>
      skipReadQuestionMutation({
        variables: {
          input: {
            leagueQuizInstanceId: quizInstanceId,
            questionId: activeQuestion.id,
          },
        },
      }),
    [skipReadQuestionMutation, quizInstanceId]
  )

  const skip = async () => {
    if (skipped) {
      return
    }

    setSkipped(true)

    const skipMutationRes = await skipReadQuestion()

    const skipEntryId = skipMutationRes.data?.skipReadQuestion?.id

    if (!skipEntryId) {
      console.error('Skipping question failed')
      return
    }

    setShowSplash(false)
  }

  useInterval(
    () => {
      setRemaining((remaining) => remaining - AVERAGE_MS_PER_WORD)
    },
    remaining <= 0 ? null : AVERAGE_MS_PER_WORD
  )

  useEffect(() => {
    if (remaining <= 0) {
      setShowSplash(false)
    }
  }, [remaining])

  const textColor =
    colorTheme === 'red' || colorTheme === 'green'
      ? theme.colors.white
      : theme.colors.black

  const progressColor = {
    red: theme.colors.red400,
    green: theme.colors.green500,
    white: theme.colors.green500,
  }

  if (showSplash) {
    return (
      <PageContent
        css={css`
          padding-top: ${isEmbed ? 0 : 24}px;
          height: calc(100vh - ${campaignHeaderHeight});
        `}
      >
        <Flex
          column
          grow
          css={css`
            width: 100%;
            height: 100%;
            cursor: pointer;
          `}
          onClick={skip}
        >
          <Flex
            column
            grow
            css={css`
              max-width: 560px;
              margin: 0 auto;
              width: 100%;
              height: 100%;
            `}
          >
            <div
              css={css`
                position: relative;
                height: 8px;
                background-color: ${progressColor[colorTheme]};
              `}
            >
              <div
                css={css`
                  position: absolute;
                  top: 0;
                  right: 0;
                  bottom: 0;
                  animation: ${expand} ${initialTime}ms linear;
                  animation-fill-mode: forwards;
                  background-color: ${colors.green300};
                  height: 8px;
                `}
              />
            </div>
            <Spacer height={isEmbed ? 0 : 'small'} />
            <QuizStatusComponent
              currentQuestion={numCompletedQuestions}
              totalQuestions={leagueQuiz.questionsCount}
            />
            <Spacer height={isEmbed ? 0 : 'small'} />
            <Text
              key={activeQuestion.text}
              color={textColor}
              css={margin.vertical(isEmbed ? 'small' : 'medium')}
              textAlign="center"
              size="medium"
            >
              {activeQuestion.text}
            </Text>

            <div
              css={css`
                margin-top: auto;
                margin-left: auto;
                margin-right: auto;
              `}
            >
              <Text
                size="small"
                textAlign="center"
                css={[
                  css`
                    color: ${textColor}80;
                    cursor: default;
                  `,
                  isFirstQuestion
                    ? css`
                        opacity: 1;
                      `
                    : css`
                        opacity: 0;
                        animation: ${fadeIn} 600ms ease-out;
                        animation-delay: 1000ms;
                        animation-fill-mode: forwards;
                      `,
                ]}
              >
                {t('Tap to skip')}
              </Text>
            </div>
          </Flex>
        </Flex>
      </PageContent>
    )
  }

  return (
    <ActiveQuestion
      {...{
        leagueQuiz,
        numCompletedQuestions,
        activeQuestion,
        quizInstanceId,
        colorTheme,
        isEmbed,
      }}
    />
  )
}

export const ActiveQuestion: FC<{
  leagueQuiz: LeagueQuizType
  numCompletedQuestions: number
  activeQuestion: ActiveQuestionType
  quizInstanceId: string
  colorTheme: 'red' | 'white' | 'green'
  isEmbed?: boolean
}> = ({
  leagueQuiz,
  numCompletedQuestions,
  activeQuestion,
  quizInstanceId,
  colorTheme,
  isEmbed,
}) => {
  const t = useT()

  const [questionAnswered, setQuestionAnswered] = useState(false)
  const [answerQuestionMutation, { loading: answerQuestionLoading }] =
    useAnswerQuestionMutation()

  const { campaignHeaderHeight } = useLeagueQuizContext()

  const [
    answerChallengeQuestionMutation,
    { loading: answerChallengeQuestionLoading, error: answerQuestionError },
  ] = useAnswerChallengeQuestionMutation()

  const answerQuestion = useCallback(
    async ({
      questionId,
      alternativeId,
    }: {
      questionId: string
      alternativeId: string | null
    }) => {
      // add 350 ms delay for animations, is compensated on server
      await new Promise((resolve) => setTimeout(resolve, 350))

      return answerQuestionMutation({
        variables: {
          input: {
            leagueQuizInstanceId: quizInstanceId,
            questionId,
            alternativeId,
          },
        },
      })
    },
    [answerQuestionMutation, answerChallengeQuestionMutation, quizInstanceId]
  )

  const initialSecondsLeft = activeQuestion.secondsLeft

  const [remaining, setRemaining] = useState(initialSecondsLeft)
  const [timerRunning, setTimerRunning] = useState(true)
  useInterval(
    () => {
      setRemaining((remaining) => remaining - 1)
    },
    remaining === 0 || !timerRunning ? null : 1000
  )

  const savedOnFinished = useRefValue(() => {
    if (
      activeQuestion &&
      !answerQuestionLoading &&
      !answerChallengeQuestionLoading
    ) {
      answerQuestion({
        questionId: activeQuestion.id,
        alternativeId: null,
      })
    }
  })
  useEffect(() => {
    if (remaining <= 0) {
      savedOnFinished.current()
    }
  }, [remaining, savedOnFinished])

  const shrink = keyframes`
    from {
      width: ${
        100 * (initialSecondsLeft / activeQuestion.maxTimeToAnswerInSeconds)
      }%;
    }
    to {
      width: 0%;
    }
`

  const textColor =
    colorTheme === 'red' || colorTheme === 'green'
      ? theme.colors.white
      : theme.colors.black

  const progressColorRemaining =
    remaining > 6
      ? theme.colors.green300
      : remaining > 3
      ? theme.colors.yellow400
      : theme.colors.red300

  const progressColor = {
    red: theme.colors.red400,
    green: theme.colors.green500,
    white: theme.colors.green500,
  }

  const error = answerQuestionError

  return (
    <PageContent
      css={css`
        padding-top: ${isEmbed ? 0 : 24}px;
        height: calc(100vh - ${campaignHeaderHeight});
      `}
    >
      <Flex
        column
        grow
        css={css`
          max-width: 560px;
          margin: 0 auto;
          width: 100%;
          height: 100%;
        `}
      >
        <div
          css={css`
            position: relative;
            height: 8px;
            background-color: ${progressColor[colorTheme]};
          `}
        >
          <div
            css={css`
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              animation: ${shrink} ${initialSecondsLeft}s linear;
              animation-play-state: ${timerRunning ? 'running' : 'paused'};
              background-color: ${progressColorRemaining};
              height: 8px;
            `}
          />
        </div>
        <Spacer height={isEmbed ? 0 : 'small'} />
        <QuizStatusComponent
          currentQuestion={numCompletedQuestions}
          totalQuestions={leagueQuiz.questionsCount}
          seconds={remaining}
        />
        <Spacer height={isEmbed ? 0 : 'small'} />
        <Text
          key={activeQuestion.text}
          color={textColor}
          css={margin.vertical(isEmbed ? 'small' : 'medium')}
          textAlign="center"
          size="medium"
        >
          {activeQuestion.text}
        </Text>

        {error && (
          <Flex
            css={css`
              margin-bottom: 10px;
            `}
            horizontal="center"
          >
            <ErrorMessage>
              {error.name.includes('INTERNET')
                ? t(`Please check your Internet connection and try again`)
                : t(`Something went wrong`)}
            </ErrorMessage>
          </Flex>
        )}

        <Flex column>
          {activeQuestion.alternatives.map((alternative) => {
            return (
              <AlternativeButton
                key={alternative.id}
                onClick={() => {
                  setQuestionAnswered(true)
                  answerQuestion({
                    questionId: activeQuestion.id,
                    alternativeId: alternative.id,
                  })
                  setTimerRunning(false)
                }}
                disabled={
                  answerQuestionLoading ||
                  answerChallengeQuestionLoading ||
                  questionAnswered
                }
              >
                {alternative.reference
                  ? renderReference(
                      alternative.reference,
                      alternative.text,
                      textColor
                    )
                  : alternative.text}
              </AlternativeButton>
            )
          })}
        </Flex>
      </Flex>
    </PageContent>
  )
}

export const renderReference = (
  reference: string,
  text: string,
  textColor: string
) => {
  // Get the reference object
  const { data, loading } = useAlternativeReferenceQuery(reference)

  return (
    <Flex vertical={'center'} gap={'small'}>
      {loading ? (
        <div
          css={css`
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background-color: ${colors.grey200};
          `}
        />
      ) : (
        <img
          src={data?.alternativeReferenceObject?.logoUrl}
          alt={`logo`}
          height={24}
        />
      )}
      <Text size="medium" color={textColor}>
        {text}
      </Text>
    </Flex>
  )
}
