/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { PrimaryButton, SecondaryButton } from '../../components/Button'
import { Heading } from '../../components/Heading'
import { Text } from '../../components/Text'
import { ErrorMessage } from '../../components/TextInput'
import { CareerPathEntry } from '../../components/careerPath/CareerPath'
import { Flex } from '../../components/layout/Flex'
import { Locale } from '../../lib/i18n/locale'
import { useT } from '../../lib/i18n/useT'
import { Category } from '../../lib/types'
import { Spacer } from '../../styles/margin'
import { colors } from '../../styles/theme'
import { CareerPathQuestionForm } from './CareerPathQuestionForm'
import { Question, QuizType, useFinishContext } from './GeneratePage'
import { QuestionForm } from './QuestionForm'
import { SelectCategories } from './SelectCategories'
import { CareerPathTeam, useStartGenerateQuestionsQuery } from './queries'
import { copyToClipboard, sortByIndex } from './util'
import {
  Alternative,
  PredictionQuestionForm,
} from './Prediction/PredicitonQuestionForm'

export type CareerPathQuestionPlayer = {
  playerName: string
  firstName: string
  lastName: string
  photoUrl: string
  nationality: string
  position: string
  team: CareerPathTeam
  careerPath: CareerPathEntry[]
}

export type CareerPathQuestion = {
  id: string
  careerPathPlayer: CareerPathQuestionPlayer
  timeless: boolean
  leagueSlug?: string
}

export const GenerateQuestion = (props: {
  text: string
  url: string
  questions: (Question | CareerPathQuestion)[]
  setQuestions: (value: (Question | CareerPathQuestion)[]) => void
  factIndex: number
  factIndexMax: number
  nextText: any
  finish: any
  useUserUI: boolean
  leagueSlug: string
  editQuestionIndex: number
  questionType: QuizType
  setQuestionType: (value: QuizType) => void
}) => {
  const { language } = useFinishContext()

  const t = useT(`${language}`)

  const { data, loading, error } = useStartGenerateQuestionsQuery(
    props.text,
    [language!],
    props.leagueSlug
  )

  useEffect(() => {
    const index = props.editQuestionIndex
    if (index !== -1) {
      setId(props.questions[index].id)
      if ((props.questions[index] as CareerPathQuestion).careerPathPlayer) {
        const careerPathQuestion = props.questions[index] as CareerPathQuestion
        setCareerPathQuestion(careerPathQuestion)
      } else {
        const question = props.questions[index] as Question
        setQuestion(question.question)
        setAlternatives(question.alternatives)
        setTimeless(question.timeless)
      }
    }
  }, [props.editQuestionIndex])

  useEffect(() => {
    if (error) {
      props.nextText()
    }
  }, [error])
  const orgQuestion =
    props.editQuestionIndex !== -1
      ? props.questions[props.editQuestionIndex]
      : null
  const [id, setId] = useState<string>(uuidv4())
  const [question, setQuestion] = useState<string>('')
  const [alternatives, setAlternatives] = useState<Alternative[]>([
    { text: '', reference: undefined, index: undefined },
    { text: '', reference: undefined, index: undefined },
    { text: '', reference: undefined, index: undefined },
    { text: '', reference: undefined, index: undefined },
  ])

  const [animationDone, setAnimationDone] = useState(false)
  const [selectedCategories, setSelectedCategories] = useState<Category[]>([])
  const [timeless, setTimeless] = useState<boolean | undefined>(
    orgQuestion?.timeless
  )

  const [careerPathQuestion, setCareerPathQuestion] = useState<
    CareerPathQuestion | undefined
  >(undefined)

  const [submitError, setSubmitError] = useState('')

  useEffect(() => {
    if (!language) return
    const status = data?.generateQuestions?.status
    if (status && status !== 'Success') {
      props.nextText()
    }
    const generatedQuestions = data?.generateQuestions?.content
    if (generatedQuestions) {
      setId(uuidv4()) // Is okay since AI is not allowed in edit
      const { question: _question, options, solutionIndex } = generatedQuestions
      setQuestion(_question)
      const _alternatives = sortByIndex(options, solutionIndex)
      setAlternatives(_alternatives)
    }
  }, [data])

  const addQuestion = () => {
    if (props.questionType === QuizType.CAREERPATH && careerPathQuestion) {
      const newCareerPathQuestion: CareerPathQuestion = {
        id: careerPathQuestion.id,
        careerPathPlayer: careerPathQuestion.careerPathPlayer,
        timeless: timeless || false,
        leagueSlug: props.leagueSlug,
      }
      if (props.editQuestionIndex !== -1) {
        props.setQuestions([
          ...props.questions.slice(0, props.editQuestionIndex),
          newCareerPathQuestion,
          ...props.questions.slice(props.editQuestionIndex + 1),
        ])
      } else {
        props.setQuestions([...props.questions, newCareerPathQuestion])
      }
      return
    }

    const questionSanity: Question = {
      id: id,
      question: question,
      alternatives: alternatives,
      timeless: timeless || false,
      aiGenerated: props.text !== '',
      referenceUrl: props.url,
      questionIndex: props.factIndex,
      questionLimit: props.factIndexMax,
      madeByUser: props.useUserUI,
      leagueSlug: props.leagueSlug,
      questionType: props.questionType,
    }
    if (props.editQuestionIndex !== -1) {
      props.setQuestions([
        ...props.questions.slice(0, props.editQuestionIndex),
        questionSanity,
        ...props.questions.slice(props.editQuestionIndex + 1),
      ])
    } else {
      props.setQuestions([...props.questions, questionSanity])
    }
    /*if (!props.leagueSlug) {
      addQuestionToSanity(
        questionSanity,
        language!, //TODO support multi language for generateQuestion
        selectedCategories,
        addQuestionMutation
      )
    }*/
  }

  useEffect(() => {
    if (props.text && loading) {
      return
    }
    const status = data?.generateQuestions?.status
    if (error || (status && status !== 'Success')) {
      return
    }
    setTimeout(function () {
      setAnimationDone(true)
    }, 250)
  }, [props.text, loading])

  // Set the careerPathQuestionPlayer when editQuestionIndex is updated
  useEffect(() => {
    if (props.editQuestionIndex !== -1) {
      // If the question is a career path question
      const careerPathQuestion = props.questions[
        props.editQuestionIndex
      ] as CareerPathQuestion
      if (careerPathQuestion.careerPathPlayer) {
        setCareerPathQuestion(careerPathQuestion)
      }
    }
  }, [props.editQuestionIndex])

  // When question is updated, set QuestionType
  useEffect(() => {
    if (props.editQuestionIndex !== -1) {
      const question = props.questions[props.editQuestionIndex] as Question
      if (question.questionType) {
        props.setQuestionType(question.questionType)
      }
    }
  }, [question])

  if (!language) {
    return (
      <Flex
        vertical="center"
        css={css`
          height: 400px;
        `}
        column
        horizontal="center"
        gap="huge"
      >
        <Heading level={5}>Error: No locale</Heading>
      </Flex>
    )
  }

  if (props.text && !animationDone) {
    //if () {
    return (
      <Flex
        vertical="center"
        css={css`
          height: 400px;
        `}
        column
        horizontal="center"
        gap="huge"
      >
        <Heading level={5}>{props.text}</Heading>
        <div
          css={css`
            width: 50px;
            height: 50px;
            border: 10px solid rgba(0, 0, 0, 0.1);
            border-top: 10px solid #3498db;
            border-left: 10px solid #3498db;
            border-radius: 50%;
            animation: spin 2s linear infinite;
            @keyframes spin {
              0% {
                transform: rotate(0deg);
              }
              100% {
                transform: rotate(360deg);
              }
            }
          `}
        ></div>
      </Flex>
    )
  }

  const isButtonDisabled = () => {
    if (timeless === undefined && props.questionType !== QuizType.PREDICTION) {
      return true
    }

    if (props.questionType === QuizType.CAREERPATH && !careerPathQuestion) {
      return true
    }

    if (
      props.questionType !== QuizType.CAREERPATH &&
      props.questionType !== QuizType.PREDICTION &&
      (question === '' ||
        alternatives[0].text === '' ||
        alternatives[1].text === '')
    ) {
      return true
    }

    return false
  }

  return (
    <Flex
      column
      gap="small"
      css={css`
        margin-top: -20px;
        height: 100%;
      `}
    >
      <Flex
        horizontal="space-between"
        vertical="center"
        css={css`
          margin-bottom: 20px;
        `}
      >
        <Heading
          level={5}
          css={css`
            width: 60px;
          `}
        >
          {props.editQuestionIndex !== -1
            ? `${props.editQuestionIndex + 1} / ${props.questions.length}`
            : `${props.questions.length + 1} / ${props.questions.length + 1}`}
        </Heading>

        {props.text && (
          <Flex
            className="citationBox"
            css={css`
              &:hover > * {
                visibility: visible;
                opacity: 1;
              }
            `}
            gap="tiny"
            vertical="center"
          >
            <Text
              css={css`
                font-size: 12px;
              `}
              color={colors.grey400}
            >
              {t(`How was this question made?`)}
            </Text>
            <img
              css={css`
                height: 15px;
              `}
              src="/question.png"
              onDoubleClick={() => copyToClipboard(props.text)}
            />
            {props.text && (
              <Flex
                horizontal="center"
                vertical="center"
                column
                gap="small"
                css={css`
                  left: 50%;
                  background-color: ${colors.grey300};
                  position: absolute;
                  border-radius: 20px;
                  margin-top: 50px;
                  padding: 20px;
                  transform: translate(-50%, 50%);
                  width: 720px;
                  height: 130px;
                  opacity: 0;
                  pointer-events: none;
                `}
              >
                <div
                  css={css`
                    top: 0;
                    position: absolute;
                    width: 0;
                    height: 0;
                    border-left: 10px solid transparent;
                    border-right: 10px solid transparent;
                    border-bottom: 10px solid ${colors.grey300}; /* Change color here */
                    transform: translate(0, -100%);
                  `}
                ></div>
                <Text
                  css={css`
                    font-size: 12px;
                  `}
                  color="black"
                >
                  {t(`This question was made using the fact:`)}
                </Text>
                <Heading level={6} color="black" textAlign="center">
                  {props.text}
                </Heading>
              </Flex>
            )}
          </Flex>
        )}
        <PrimaryButton
          onClick={() => {
            // If the question is not a career path question
            if (orgQuestion && !('careerPathPlayer' in orgQuestion)) {
              const differentQuestion = orgQuestion.question !== question
              const differentAlternatives =
                JSON.stringify(orgQuestion.alternatives) !==
                JSON.stringify(alternatives)
              if ((differentQuestion || differentAlternatives) && !props.text) {
                if (!confirm('Exit without saving?')) {
                  return
                }
              }
            }
            props.finish()
          }}
        >
          {t(`Back`)}
        </PrimaryButton>
      </Flex>
      {props.questionType === QuizType.CAREERPATH && (
        <CareerPathQuestionForm
          setCareerPathPlayer={setCareerPathQuestion}
          careerPathQuestion={careerPathQuestion}
        />
      )}

      {props.questionType === QuizType.PREDICTION && (
        <PredictionQuestionForm
          question={question}
          setQuestion={setQuestion}
          alternatives={alternatives}
          setAlternatives={setAlternatives}
          languageOverride={`${language}`}
        />
      )}

      {props.questionType !== QuizType.CAREERPATH &&
        props.questionType !== QuizType.PREDICTION && (
          <QuestionForm
            question={question}
            setQuestion={setQuestion}
            alternatives={alternatives}
            setAlternatives={setAlternatives}
            languageOverride={`${language}`}
          />
        )}

      <Spacer height="medium" />

      {!props.leagueSlug && (
        <Flex
          css={css`
            width: 100%;
            margin-bottom: 20px;
          `}
          horizontal="center"
        >
          <SelectCategories
            selectedCategories={selectedCategories}
            setSelectedCategories={setSelectedCategories}
            languageOverride={`${language}` as Locale}
          />
        </Flex>
      )}

      {props.questionType !== QuizType.PREDICTION && (
        <Flex
          css={css`
            flex: 1;

            width: 100%;
          `}
          horizontal="space-between"
          gap="small"
        >
          <Flex column>
            <Heading
              level={5}
              css={css`
                margin: auto 0;
              `}
            >
              {t(`Is this question timeless?`)}
            </Heading>
            <Text
              size="tiny"
              color={colors.grey400}
              css={css`
                margin: auto 0;
              `}
            >
              {t(`Timeless questions can be answered at any time`)}
            </Text>
          </Flex>

          <Spacer width="medium" />
          <Flex gap="small" vertical="center">
            <Text
              size="tiny"
              css={css`
                margin: auto 0;
              `}
            >
              {t(`Yes`)}
            </Text>

            <input
              type="radio"
              checked={timeless === true}
              onChange={() => {
                setTimeless(true)
              }}
              css={css`
                margin: 0;
                height: 30px;
                width: 30px;
              `}
            />

            <Text
              size="tiny"
              css={css`
                margin: auto 0;
              `}
            >
              {t(`No`)}
            </Text>
            <input
              type="radio"
              checked={timeless === false}
              onChange={() => {
                setTimeless(false)
              }}
              css={css`
                margin: 0;
                height: 30px;
                width: 30px;
                :checked:after {
                  background-color: red;
                }
              `}
            />
          </Flex>
        </Flex>
      )}
      <Flex gap="medium">
        {props.text && (
          <SecondaryButton
            css={css`
              width: 100%;
              height: 60px;
              margin-top: 30px;
              border-radius: 5px;
              &:hover {
                cursor: pointer;
              }
            `}
            onClick={() => {
              setSubmitError('')
              setAnimationDone(false)
              props.nextText()
            }}
          >
            {t(`Discard`)}
          </SecondaryButton>
        )}

        <PrimaryButton
          disabled={isButtonDisabled()}
          variant="black"
          css={css`
            width: 100%;
            height: 60px;
            margin-top: 30px;
            border-radius: 5px;
            &:hover {
              cursor: pointer;
            }
          `}
          onClick={() => {
            if (
              (props.questionType === QuizType.CAREERPATH &&
                !careerPathQuestion) ||
              (props.questionType !== QuizType.CAREERPATH && question === '')
            ) {
              setSubmitError('Question must be filled')
              return
            }
            if (
              props.questionType !== QuizType.PREDICTION &&
              props.questionType !== QuizType.CAREERPATH &&
              (alternatives[0].text === '' || alternatives[1].text === '')
            ) {
              setSubmitError('At least two alternatives must be filled')
              return
            }

            setSubmitError('')
            addQuestion()

            props.nextText()
            setAnimationDone(false)
            setTimeless(undefined)
            if (!props.text) {
              setQuestion('')
              setAlternatives(
                Array.from({ length: 4 }, () => ({
                  text: '',
                }))
              )
              if (props.leagueSlug) {
                props.finish()
              }
            }
          }}
        >
          {t(`Save`)}
        </PrimaryButton>
      </Flex>
      {submitError && (
        <ErrorMessage
          css={css`
            align-self: center;
            width: 550px;
          `}
        >
          {submitError}
        </ErrorMessage>
      )}
      <Spacer height={64} />
    </Flex>
  )
}
