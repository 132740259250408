/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { Flex } from '../../../components/layout/Flex'
import { ReferenceFieldInput } from './ReferenceFieldInput'

export type Alternative = {
  text: string
  reference?: string
  index?: number
  _key?: string
}

export const PredictionQuestionForm = (props: {
  question: string
  setQuestion: (value: string) => void
  alternatives: Alternative[]
  setAlternatives: (value: Alternative[]) => void
  questionLabel?: string
  alternativesLabel?: string[]
  showDiscard?: boolean
  languageOverride?: string
}) => {
  // Function to update alternative at specific index
  const setAlternative =
    (index: number) => (input: { text: string; reference?: string }) => {
      const updatedAlternatives = [...props.alternatives]

      // Extract text and reference from the input object
      updatedAlternatives[index] = {
        text: input.text,
        reference: input.reference,
        index: index,
      }
      props.setAlternatives(updatedAlternatives)
    }

  return (
    <Flex
      column
      gap="small"
      css={css`
        height: 100%;
      `}
    >
      {/* Input for the main question */}
      <ReferenceFieldInput
        field={props.question}
        setField={props.setQuestion}
        type="Question"
        label={props.questionLabel}
        css={css`
          margin-bottom: 24px;
        `}
        autoFocus
        languageOverride={props.languageOverride}
      />

      {/* Inputs for alternatives */}
      {props.alternatives.map((alternative, index) => (
        <Flex
          column
          css={css`
            position: relative;
            width: 100%;
          `}
        >
          <ReferenceFieldInput
            key={index}
            field={alternative.text}
            reference={alternative.reference}
            setField={setAlternative(index)}
            type="Alternative"
            index={index}
            label={props.alternativesLabel && props.alternativesLabel[index]}
            discardField={
              props.alternativesLabel && props.alternativesLabel[index]
            }
            languageOverride={props.languageOverride}
          />
        </Flex>
      ))}
    </Flex>
  )
}
