import { gql, useApolloClient, useMutation, useQuery } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'
import { FirebaseError } from '@firebase/util'
import {
  AuthCredential,
  AuthProvider,
  FacebookAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  User,
  getRedirectResult,
  linkWithCredential,
  linkWithRedirect,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInAnonymously,
  signInWithCredential,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from 'firebase/auth'
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { LeagueCategory } from '../views/Home/queries'
import { analytics } from './analytics'
import { apolloClient } from './apollo/apollo'
import { auth } from './firebase'
import { Locale } from './i18n/locale'

const AuthContext = createContext<{
  authUser: User | null
  loading: boolean
  pendingCred?: AuthCredential | null
  signin: (email: string, password: string) => Promise<User>
  customTokenSignin: (token: string) => Promise<any>
  signInAsAnonymous: () => Promise<User>
  signout: () => Promise<void>
  sendResetPasswordEmail: (email: string) => Promise<any>
  signInWithGoogle: () => Promise<User | string | undefined>
  signInWithApple: () => Promise<User | string | undefined>
  signInWithFacebook: () => Promise<User | string | undefined>
  signinWithTv2: () => Promise<void>
  checkForRedirectResult: () => Promise<User | undefined>
  clubLeagueSubscriptionId?: string
}>({
  authUser: null,
  loading: true,
  pendingCred: null,
  signin: () => Promise.reject(),
  customTokenSignin: () => Promise.reject(),
  signInAsAnonymous: () => Promise.reject(),
  signout: () => Promise.reject(),
  sendResetPasswordEmail: () => Promise.reject(),
  signInWithGoogle: () => Promise.reject(),
  signInWithApple: () => Promise.reject(),
  signInWithFacebook: () => Promise.reject(),
  signinWithTv2: () => Promise.reject(),
  checkForRedirectResult: () => Promise.reject(),
  clubLeagueSubscriptionId: undefined,
})

let pendingCred: AuthCredential | null = null

export const useAuthContext = () => {
  return useContext(AuthContext)
}

export const AuthContextProvider = ({ children }: { children: ReactNode }) => {
  const [authUser, setAuthUser] = useState<User | null>(null)
  const [loading, setLoading] = useState(true)
  const [clubLeagueSubscriptionId, setClubLeagueSubscriptionId] =
    useState<string>('')

  const { loginWithRedirect: auth0Login } = useAuth0()

  useEffect(() => {
    if (authUser) {
      window.location.reload()
    }
  }, [setAuthUser])

  useEffect(() => {
    // Get the clubLeagueSubscriptionId from the URL
    const urlParams = new URLSearchParams(window.location.search)
    const clubLeagueSubscriptionId = urlParams.get('clubLeagueSubscriptionId')
    if (clubLeagueSubscriptionId) {
      setClubLeagueSubscriptionId(clubLeagueSubscriptionId)
    }
  }, [])

  const signin = async (email: string, password: string) => {
    const response = await signInWithEmailAndPassword(auth, email, password)
    setAuthUser(response.user)
    return response.user
  }

  const customTokenSignin = async (token: string) => {
    const response = await signInWithCustomToken(auth, token)
    setAuthUser(response.user)
    return response.user
  }

  const signInAsAnonymous = async () => {
    return signInAnonymously(auth).then((response) => {
      setAuthUser(response.user)
      return response.user
    })
  }

  const signout = async () => {
    await signOut(auth)
    apolloClient.clearStore()
    console.log('successfully signed out')
  }

  const sendResetPasswordEmail = async (email: string) => {
    await sendPasswordResetEmail(auth, email)
    apolloClient.clearStore()
    console.log('successfully sent reset email')
  }

  const checkForRedirectResult = async () => {
    try {
      const result = await getRedirectResult(auth)
      if (result && auth.currentUser?.isAnonymous === true) {
        setAuthUser(result.user)
        return result.user
      }
    } catch (error: FirebaseError | any) {
      console.log(error)
      if (error && error.code === 'auth/credential-already-in-use') {
        if (GoogleAuthProvider.credentialFromError(error)) {
          pendingCred = GoogleAuthProvider.credentialFromError(error)
        } else if (FacebookAuthProvider.credentialFromError(error)) {
          pendingCred = FacebookAuthProvider.credentialFromError(error)
        } else {
          pendingCred = OAuthProvider.credentialFromError(error)
        }
        if (pendingCred instanceof AuthCredential) {
          signInWithCredential(auth, pendingCred)
            .then(async (userCred) => {
              setAuthUser(userCred.user)
              return userCred.user
            })
            .catch((error) => {
              console.log(error)
              return undefined
            })
          pendingCred = null
        }
      }
    }
    return undefined
  }

  const linkAnonymousUser = async (user: User, provider: AuthProvider) => {
    try {
      await linkWithRedirect(user, provider)
    } catch (error: any) {
      console.log(error)
    }
  }

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider()
    // If there's an anonymous user, try to link it with Google
    if (auth.currentUser !== null && auth.currentUser.isAnonymous) {
      await linkAnonymousUser(auth.currentUser, provider)
      return undefined
    } else {
      // If there's no anonymous user or linking was not possible, use signInWithPopup
      try {
        const response = await signInWithPopup(auth, provider)
        // Handle the sign-in with popup result
        if (pendingCred) {
          try {
            const userCred = await linkWithCredential(
              response.user,
              pendingCred
            )
            setAuthUser(userCred.user)
            return userCred.user
          } catch (error) {
            console.log(error)
            return undefined
          }
        }
        setAuthUser(response.user)
        return response.user
      } catch (error: any) {
        console.log(error)
        return undefined
      }
    }
  }

  const signInWithApple = async () => {
    const provider = new OAuthProvider('apple.com')

    if (auth.currentUser !== null && auth.currentUser.isAnonymous) {
      await linkAnonymousUser(auth.currentUser, provider)
      return undefined
    }

    return await signInWithPopup(auth, provider)
      .then(async (response) => {
        if (pendingCred != null) {
          try {
            const userCred = await linkWithCredential(
              response.user,
              pendingCred
            )
            setAuthUser(userCred.user)
            return userCred.user
          } catch (error) {
            console.log(error)
            return undefined
          }
        }
        setAuthUser(response.user)
        return response.user
      })
      .catch((error) => {
        console.log(error)
        return undefined
      })
  }

  const signInWithFacebook = async () => {
    const provider = new FacebookAuthProvider()

    if (auth.currentUser !== null && auth.currentUser.isAnonymous) {
      await linkAnonymousUser(auth.currentUser, provider)
      return undefined
    }

    return await signInWithPopup(auth, provider)
      .then((response) => {
        setAuthUser(response.user)
        return response.user
      })
      .catch((error) => {
        if (error.code === 'auth/account-exists-with-different-credential') {
          // The pending Facebook credentials.
          pendingCred = FacebookAuthProvider.credentialFromError(error)
          console.log(error)
        }
        return error.code as string
      })
  }

  const signinWithTv2 = async () => {
    auth0Login()
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setAuthUser(user)
      } else {
        setAuthUser(null)
      }
      setLoading(false)
    })

    return () => unsubscribe()
  }, [])

  useEffect(() => {
    if (authUser) {
      analytics.setIsAuthenticated(true)
      analytics.setIsAnonymous(authUser.isAnonymous)
    } else {
      analytics.setIsAuthenticated(false)
      analytics.setIsAnonymous(false)
    }
  }, [authUser])

  const value = {
    authUser,
    loading,
    pendingCred,
    signin,
    customTokenSignin,
    signInAsAnonymous,
    signout,
    sendResetPasswordEmail,
    signInWithGoogle,
    signInWithApple,
    signInWithFacebook,
    signinWithTv2,
    checkForRedirectResult,
    clubLeagueSubscriptionId,
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export enum UserFlag {
  hidePushNotificationBanner = 'hidePushNotificationBanner',
}

export type Viewer = {
  id: string
  slug: string
  username?: string
  email?: string
  avatarData?: string
  coins?: number
  styles?: string[]
  isAdmin?: boolean
  locale?: Locale
  flags: UserFlag[]
  displayName?: string
  isTranslater?: boolean
  onboardingCompleted?: boolean
  favoriteCategories?: LeagueCategory[]
}

export const viewerQuery = gql`
  query viewer {
    viewer {
      id
      username
      slug
      email
      avatarData
      coins
      unlockedStyles
      isAdmin
      locale
      flags
      displayName
      isTranslater
      onboardingCompleted
      favoriteCategories {
        id
        title
      }
    }
  }
`
export const useViewerQuery = (params?: { skip: boolean }) => {
  return useQuery<{ viewer?: Viewer }>(viewerQuery, {
    skip: params?.skip ?? false,
  })
}

export const useRefetchViewerQuery = () => {
  const apolloClient = useApolloClient()
  return useCallback(() => {
    apolloClient.refetchQueries({ include: [viewerQuery] })
  }, [apolloClient])
}

export const useViewerFlags = () => {
  const { data, loading } = useViewerQuery()

  const [addUserFlagMutation] = useMutation<unknown, { flag: UserFlag }>(gql`
    mutation addUserFlag($flag: UserFlag!) {
      addUserFlag(flag: $flag) {
        id
        flags
      }
    }
  `)
  const [removeUserFlagMutation] = useMutation<unknown, { flag: UserFlag }>(gql`
    mutation removeUserFlag($flag: UserFlag!) {
      removeUserFlag(flag: $flag) {
        id
        flags
      }
    }
  `)

  const addFlag = useCallback(
    (flag: UserFlag) => {
      addUserFlagMutation({ variables: { flag } })
    },
    [addUserFlagMutation]
  )
  const removeFlag = useCallback(
    (flag: UserFlag) => {
      removeUserFlagMutation({ variables: { flag } })
    },
    [removeUserFlagMutation]
  )

  return {
    loading,
    flags: data?.viewer?.flags ?? [],
    addFlag,
    removeFlag,
  }
}
