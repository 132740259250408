/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { Checkbox } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { PrimaryButton, SecondaryButton } from '../../components/Button'
import { Header } from '../../components/Header'
import { HeadingNew } from '../../components/HeadingNew'
import { Flex } from '../../components/layout/Flex'
import { Layout } from '../../components/layout/Layout'
import { PageContent } from '../../components/layout/PageContent'
import { Loader } from '../../components/Loader'
import { TextNew } from '../../components/TextNew'
import { useT } from '../../lib/i18n/useT'
import { Spacer } from '../../styles/margin'
import { colors } from '../../styles/theme'
import { formatDate } from '../../utils/date'
import { useNow } from '../../utils/useNow'
import { AdminAccessControlComponent } from './AdminAccessControlComponent'
import { AdminNavbar } from './Navbar'
import {
  LeagueQuizWithQuestions,
  useAnswerPredictionQuizMutation,
  useGetPredictionQuizAnswersQuery,
  useGetPredictionQuizzesToBeAnsweredQuery,
} from './queries'
import { calculateTimeRemaining, timeRemainingToString } from './Utils'

const AnswerQuiz = ({
  quiz,
  setSelectedQuiz,
}: {
  quiz: LeagueQuizWithQuestions
  setSelectedQuiz: (quiz: LeagueQuizWithQuestions | null) => void
}) => {
  const [modifiedQuiz, setModifiedQuiz] =
    useState<LeagueQuizWithQuestions>(quiz)

  const [fetchAnswers, setFetchAnswers] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const {
    data: predictionAnswers,
    loading: predictionAnswersLoading,
    refetch,
  } = useGetPredictionQuizAnswersQuery(quiz.id, {
    skip: !fetchAnswers,
  })

  const [answerPredictionQuiz, { loading: mutationLoading }] =
    useAnswerPredictionQuizMutation()

  // Update quiz with correct answers from predictionAnswers
  useEffect(() => {
    if (predictionAnswers?.getPredictionQuizAnswers) {
      const updatedQuiz = {
        ...modifiedQuiz,
        questions: modifiedQuiz.questions.map((question) => {
          const correctAnswer = predictionAnswers.getPredictionQuizAnswers.find(
            (answer) => answer.question_id === question._id
          )
          return {
            ...question,
            alternatives: question.alternatives.map((alt) => ({
              ...alt,
              correct: alt._key === correctAnswer?.alternative_key,
            })),
          }
        }),
      }
      setModifiedQuiz(updatedQuiz)
    }
  }, [predictionAnswers, fetchAnswers])

  useEffect(() => {
    if (mutationLoading || predictionAnswersLoading) {
      setIsLoading(true)
    } else {
      setIsLoading(false)
    }
  }, [mutationLoading, predictionAnswersLoading])

  return (
    <Flex column gap={'medium'}>
      <HeadingNew level={1} looksLikeLevel={3}>
        {quiz.title}
      </HeadingNew>
      <PrimaryButton
        onClick={() => {
          setIsLoading(true)
          if (fetchAnswers) {
            refetch()
              .then(() => setIsLoading(false))
              .catch((e) => {
                console.error(e)
                setIsLoading(false)
              })
          } else {
            setFetchAnswers(true)
          }
        }}
        disabled={isLoading || !quiz.fixtures}
      >
        🤖 Fetch Prediction Answers
      </PrimaryButton>
      {isLoading ? (
        <Flex
          horizontal={'center'}
          vertical={'center'}
          css={css`
            padding: 16px;
            width: 100%;
            min-height: 32px;
          `}
        >
          <Loader />
        </Flex>
      ) : (
        <Flex column gap={'small'}>
          <HeadingNew level={2} looksLikeLevel={4}>
            Questions
          </HeadingNew>
          {modifiedQuiz.questions.map((question, questionIndex) => (
            <Flex
              key={question._id}
              column
              css={css`
                width: 100%;
                padding: 16px;
                border-radius: 8px;
                background-color: ${colors.grey200};
                margin-bottom: 16px;
              `}
            >
              <TextNew>{question.question}</TextNew>
              <Flex gap={'small'} column>
                {question.alternatives.map((alt, altIndex) => (
                  <Flex
                    key={alt._key}
                    vertical={'center'}
                    css={css`
                      padding: 8px;
                      border-radius: 8px;
                      background-color: ${colors.grey100};
                      margin-top: 8px;
                      cursor: pointer;
                      &:hover {
                        background-color: #f5f5f5;
                      }
                    `}
                  >
                    <Checkbox
                      checked={alt.correct}
                      onChange={() => {
                        const newQuiz = {
                          ...modifiedQuiz,
                          questions: modifiedQuiz.questions.map((q, qIndex) =>
                            qIndex === questionIndex
                              ? {
                                  ...q,
                                  alternatives: q.alternatives.map(
                                    (a, aIndex) =>
                                      aIndex === altIndex
                                        ? { ...a, correct: !a.correct }
                                        : { ...a, correct: false }
                                  ),
                                }
                              : q
                          ),
                        }
                        setModifiedQuiz(newQuiz)
                      }}
                    />
                    <TextNew>{alt.content}</TextNew>
                  </Flex>
                ))}
              </Flex>
            </Flex>
          ))}
        </Flex>
      )}
      <Flex horizontal={'space-between'}>
        <PrimaryButton
          onClick={() => {
            setModifiedQuiz(quiz)
            setSelectedQuiz(null)
          }}
        >
          Back
        </PrimaryButton>
        <Flex gap={'medium'} horizontal={'flex-end'}>
          <SecondaryButton
            onClick={() => {
              setModifiedQuiz(quiz)
            }}
            disabled={isLoading}
          >
            Reset
          </SecondaryButton>
          <PrimaryButton
            disabled={
              !modifiedQuiz.questions.some((q) =>
                q.alternatives.some((a) => a.correct)
              ) || isLoading
            }
            onClick={() => {
              answerPredictionQuiz({
                variables: {
                  id: quiz.id,
                  questions: modifiedQuiz.questions.map((question) => ({
                    _id: question._id,
                    question: question.question,
                    alternatives: question.alternatives.map((alt) => ({
                      _key: alt._key,
                      content: alt.content,
                      correct: alt.correct,
                    })),
                  })),
                },
              })
                .then(() => {
                  setSelectedQuiz(null)
                })
                .catch((e) => {
                  console.error(e)
                })
            }}
          >
            Save
          </PrimaryButton>
        </Flex>
      </Flex>
    </Flex>
  )
}

const Content = () => {
  const [selectedQuiz, setSelectedQuiz] =
    useState<LeagueQuizWithQuestions | null>(null)

  const { data, loading, refetch } = useGetPredictionQuizzesToBeAnsweredQuery()

  const quizzes = data?.getPredictionQuizzesToBeAnswered || []

  const sorted = useMemo(() => {
    return [...quizzes].sort((a, b) => {
      if (!a.availableTo) return 1
      if (!b.availableTo) return -1
      return (
        new Date(a.availableTo).getTime() - new Date(b.availableTo).getTime()
      )
    })
  }, [quizzes])

  const t = useT()

  const now = useNow({ updateFrequencyInMs: 1000 })

  useEffect(() => {
    refetch()
  }, [selectedQuiz])

  return (
    <PageContent>
      {selectedQuiz ? (
        <AnswerQuiz quiz={selectedQuiz} setSelectedQuiz={setSelectedQuiz} />
      ) : (
        <Flex
          column
          gap={'medium'}
          css={css`
            width: 100%;
          `}
        >
          <HeadingNew level={1} looksLikeLevel={3}>
            Unanswered Prediction Quizzes
          </HeadingNew>

          {loading && <Loader />}

          {sorted.map((quiz) => {
            if (!quiz.availableTo) {
              return (
                <Flex
                  key={quiz.id}
                  css={css`
                    background-color: ${colors.red200};
                    width: 100%;
                    padding: 16px;
                    border-radius: 8px;
                    cursor: pointer;
                  `}
                  onClick={() => {
                    navigator.clipboard.writeText(quiz.id)
                  }}
                >
                  <TextNew>Invalid prediction</TextNew>
                  <TextNew>{quiz.id}</TextNew>
                </Flex>
              )
            }

            const hasExpired = new Date(quiz.availableTo) < new Date()

            const timeUntilExpiration = calculateTimeRemaining(
              now,
              new Date(quiz.availableTo)
            )

            return (
              <Flex
                key={quiz.id}
                horizontal={'space-between'}
                vertical="flex-end"
                css={[
                  css`
                    width: 100%;
                    padding: 16px;
                    border-radius: 8px;
                    background-color: ${colors.grey200};
                    cursor: not-allowed;
                  `,
                  hasExpired &&
                    css`
                      cursor: pointer;
                      background-color: ${colors.yellow200};

                      &:hover {
                        opacity: 0.8;
                      }
                    `,
                ]}
                onClick={() => {
                  if (!hasExpired) return

                  setSelectedQuiz(quiz)
                }}
              >
                <Flex column gap={'medium'}>
                  <TextNew weight={600} size={'large'}>
                    {quiz.title}
                  </TextNew>
                  <TextNew italic>
                    {hasExpired
                      ? '‼️'
                      : timeRemainingToString(timeUntilExpiration, t)}
                  </TextNew>
                </Flex>
                <Flex column gap={'medium'} horizontal={'flex-end'}>
                  <TextNew>
                    {t('{{count}} question', {
                      count: quiz.questions.length,
                    })}
                  </TextNew>
                  <TextNew textAlign="right">
                    {hasExpired
                      ? 'Set the correct answers'
                      : formatDate(
                          new Date(quiz.availableTo),
                          'dd-MM-yyyy HH:mm'
                        )}
                  </TextNew>
                </Flex>
              </Flex>
            )
          })}
          <Spacer height={'huge'} />
        </Flex>
      )}
    </PageContent>
  )
}

export const AdminPredictionQuiz = () => {
  return (
    <Layout>
      <AdminAccessControlComponent>
        <PageContent>
          <Header color="green" />
          <AdminNavbar activePage="predictionQuiz" />
        </PageContent>
        <Content />
        <Spacer height={'huge'} />
      </AdminAccessControlComponent>
    </Layout>
  )
}
