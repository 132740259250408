/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { FaArrowRight } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { Flex } from '../../components/layout/Flex'
import { TextNew } from '../../components/TextNew'
import { useAuthContext } from '../../lib/AuthContext'
import { useT } from '../../lib/i18n/useT'
import { Spacer } from '../../styles/margin'
import { prettyLetterSpacing } from '../../styles/styles'
import { colors } from '../../styles/theme'
import { ShimmerComponent } from '../Home/ShimmerComponent'
import { LeaderboardItem } from '../League/queries'
import { GlobalLeaderboardItemComponent } from './LeaderboardsPage'

export const TopPlayersTodayPreviewComponent = ({
  entries,
  loading,
}: {
  entries: LeaderboardItem[]
  loading?: boolean
}) => {
  const { authUser } = useAuthContext()

  const t = useT()

  const topEntries = entries.filter((entry) => entry.rank <= 3)
  const textColor = colors.white

  return (
    <Link
      to={'/leaderboards'}
      css={css`
        text-decoration: none;
      `}
    >
      <Flex
        column
        css={css`
          padding: 20px;
          background-color: ${colors.grey500};
          border-radius: 10px;
          position: relative;
        `}
      >
        <Flex
          horizontal="center"
          css={css`
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
          `}
        >
          <TextNew
            css={css`
              font-size: 74px;

              @media (max-width: 599px) {
                font-size: 56px;
              }
            `}
          >
            🏅
          </TextNew>
        </Flex>
        <Flex horizontal="space-between" vertical="center">
          <TextNew
            color={textColor}
            condensed
            italic
            shadow
            textAlign="center"
            css={css`
              font-size: 40px;
              line-height: 40px;
              font-weight: 600;

              @media (max-width: 599px) {
                font-size: 24px;
                line-height: 1;
              }
            `}
          >
            {t(`Top today`)}
          </TextNew>
          <Flex
            vertical="center"
            horizontal="space-between"
            gap={'tiny'}
            css={css`
              background-color: ${colors.yellow400};

              padding: 8px 8px 8px 16px;

              border-radius: 64px;
              @media (max-width: 599px) {
                padding: 4px 4px 4px 8px;
              }
            `}
          >
            <TextNew
              strong
              condensed
              css={css`
                white-space: nowrap;
                font-size: 16px;
                letter-spacing: ${prettyLetterSpacing};

                line-height: 1.2;

                @media (max-width: 599px) {
                  font-size: 14px;
                }
              `}
              color={colors.black}
            >
              {t(`View all leaderboards`)}
            </TextNew>

            <FaArrowRight
              css={css`
                height: 16px;

                @media (max-width: 599px) {
                  height: 14px;
                }
              `}
            />
          </Flex>
        </Flex>
        <Spacer height={28} />
        {loading && (
          <Flex column horizontal="center" gap={'tiny'}>
            <ShimmerComponent
              height={58}
              css={css`
                border-radius: 64px;
                width: 100%;
              `}
            />
            <ShimmerComponent
              height={50}
              css={css`
                border-radius: 64px;
                width: 95%;
              `}
            />
            <ShimmerComponent
              height={44}
              css={css`
                border-radius: 64px;
                width: 93%;
              `}
            />
          </Flex>
        )}
        {!loading && (
          <Flex column horizontal="center" gap={'tiny'}>
            {topEntries.map((entry, index) => {
              const isAuthUser = authUser?.uid === entry.user.id

              return (
                <GlobalLeaderboardItemComponent
                  key={`${index}_${entry.user.id}`}
                  item={entry}
                  index={index}
                  isAuthUser={isAuthUser}
                  clickable={false}
                />
              )
            })}
          </Flex>
        )}
      </Flex>
    </Link>
  )
}
