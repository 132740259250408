/** @jsxImportSource @emotion/react */

import { Flex } from '../../components/layout/Flex'
import { Layout } from '../../components/layout/Layout'
import { PageContent } from '../../components/layout/PageContent'

import { useState } from 'react'
import { PrimaryButton } from '../../components/Button'
import { useDetectAdBlock } from '../../components/campaign/useDetectAdBlock'
import { HeadingNew } from '../../components/HeadingNew'
import {
  AdId,
  AdSize,
  RevIqAdComponent,
} from '../../components/reviq/RevIqAdComponent'
import { TextNew } from '../../components/TextNew'
import { Spacer } from '../../styles/margin'
import { colors } from '../../styles/theme'
import { setRevIqAdsEnabled } from '../../utils/thirdPartyScripts'

export const AdTestPage = () => {
  const adblockDetected = useDetectAdBlock({ skip: false })

  const [adsEnabled, setAdsEnabled] = useState(true)

  return (
    <Layout backgroundColor={colors.green600} withHeader>
      <Flex column grow>
        <PageContent gap={'huge'}>
          <HeadingNew color={colors.white} level={1}>
            {'Ad Test'}
          </HeadingNew>

          <TextNew color="white" size={'small'} italic>
            {`ads enabled: ${adsEnabled}`}
          </TextNew>
          <Flex gap={'medium'}>
            <PrimaryButton
              noWrap
              rounded
              variant="white"
              onClick={() => {
                setRevIqAdsEnabled(true)
                setAdsEnabled(true)
              }}
            >
              Enable RevIqAds
            </PrimaryButton>
            <PrimaryButton
              noWrap
              rounded
              variant="white"
              onClick={() => {
                setRevIqAdsEnabled(false)
                setAdsEnabled(false)
              }}
            >
              Disable RevIqAds
            </PrimaryButton>
          </Flex>

          <TextNew color={colors.white}>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum.
          </TextNew>

          <TextNew color="white">
            t is a long established fact that a reader will be distracted by the
            readable content of a page when looking at its layout. The point of
            using Lorem Ipsum is that it has a more-or-less normal distribution
            of letters, as opposed to using 'Content here, content here', making
            it look like readable English. Many desktop publishing packages and
            web page editors now use Lorem Ipsum as their default model text,
            and a search for 'lorem ipsum' will uncover many web sites still in
            their infancy. Various versions have evolved over the years,
            sometimes by accident, sometimes on purpose (injected humour and the
            like).
          </TextNew>

          <TextNew color="white" size={'small'} italic>
            {`size: ${AdSize.LARGE_RECTANGLE} | data-ad=${AdId.videoTop} | adblockDetected: ${adblockDetected}`}
          </TextNew>
          <RevIqAdComponent
            adSize={AdSize.LARGE_RECTANGLE}
            adId={AdId.videoTop}
            adblockDetected={adblockDetected}
          />

          <TextNew color={colors.white}>
            Contrary to popular belief, Lorem Ipsum is not simply random text.
            It has roots in a piece of classical Latin literature from 45 BC,
            making it over 2000 years old. Richard McClintock, a Latin professor
            at Hampden-Sydney College in Virginia, looked up one of the more
            obscure Latin words, consectetur, from a Lorem Ipsum passage, and
            going through the cites of the word in classical literature,
            discovered the undoubtable source. Lorem Ipsum comes from sections
            1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes
            of Good and Evil) by Cicero, written in 45 BC. This book is a
            treatise on the theory of ethics, very popular during the
            Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit
            amet..", comes from a line in section 1.10.32.
          </TextNew>

          <TextNew color="white">
            The standard chunk of Lorem Ipsum used since the 1500s is reproduced
            below for those interested. Sections 1.10.32 and 1.10.33 from "de
            Finibus Bonorum et Malorum" by Cicero are also reproduced in their
            exact original form, accompanied by English versions from the 1914
            translation by H. Rackham.
          </TextNew>

          <TextNew color="white" size={'small'} italic>
            {`size: ${AdSize.MEDIUM_RECTANGLE} | data-ad=${AdId.rightRail1} | adblockDetected: ${adblockDetected}`}
          </TextNew>
          <RevIqAdComponent
            adSize={AdSize.MEDIUM_RECTANGLE}
            adId={AdId.rightRail1}
            adblockDetected={adblockDetected}
          />

          <TextNew color={colors.white}>
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour, or randomised words which don't look even slightly
            believable. If you are going to use a passage of Lorem Ipsum, you
            need to be sure there isn't anything embarrassing hidden in the
            middle of text. All the Lorem Ipsum generators on the Internet tend
            to repeat predefined chunks as necessary, making this the first true
            generator on the Internet. It uses a dictionary of over 200 Latin
            words, combined with a handful of model sentence structures, to
            generate Lorem Ipsum which looks reasonable. The generated Lorem
            Ipsum is therefore always free from repetition, injected humour, or
            non-characteristic words etc.
          </TextNew>

          <TextNew color={colors.white}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur
            feugiat orci eu metus imperdiet, et tempus ligula faucibus. Etiam
            aliquet turpis eget massa ultricies, quis ornare lorem interdum.
            Donec metus magna, rhoncus in maximus vel, tempor quis sem. Donec
            dui risus, ullamcorper a nunc at, gravida varius nisi. Nunc sit amet
            massa suscipit, ornare metus quis, tristique ipsum. Suspendisse
            vulputate ullamcorper leo, in consectetur nibh porttitor molestie.
            Nullam sit amet dolor elit.
          </TextNew>

          <TextNew color={colors.white}>
            Fusce vel pellentesque dolor. Quisque iaculis magna vitae mi
            gravida, molestie luctus orci vulputate. Fusce suscipit varius
            finibus. Cras eget nibh vitae magna dapibus ullamcorper a quis nunc.
            Integer id mi nunc. Praesent aliquam, massa eget dignissim iaculis,
            dolor lectus aliquam metus, nec scelerisque sapien dui id ipsum.
            Donec tempus sed metus id tincidunt. Mauris orci ex, vehicula vitae
            justo in, lobortis interdum magna. Nunc quam dolor, posuere quis
            orci eu, tempor ornare enim. Etiam sodales, magna at vulputate
            elementum, erat libero suscipit turpis, a pellentesque urna magna eu
            dui. Donec sagittis turpis vitae posuere eleifend.
          </TextNew>

          <TextNew color={colors.white}>
            Donec non lectus euismod, convallis metus et, iaculis lorem. Sed sit
            amet nunc in lectus fermentum dictum. Nulla facilisi. Nullam et orci
            in erat euismod dictum. Sed vestibulum, nunc vel ullamcorper
            elementum, nisl dolor convallis nunc, eget ullamcorper magna nibh ut
            nibh. Integer quis nibh non nibh bibendum dictum. Nullam sit amet
            nunc in lectus fermentum dictum. Nulla facilisi. Nullam et orci in
            erat euismod dictum. Sed vestibulum, nunc vel ullamcorper elementum,
            nisl dolor convallis nunc, eget ullamcorper magna nibh ut nibh.
            Integer quis nibh non nibh bibendum dictum.
          </TextNew>

          <TextNew color={colors.white}>
            Sed sit amet nunc in lectus fermentum dictum. Nulla facilisi. Nullam
            et orci in erat euismod dictum. Sed vestibulum, nunc vel ullamcorper
            elementum, nisl dolor convallis nunc, eget ullamcorper magna nibh ut
            nibh. Integer quis nibh non nibh bibendum dictum. Nullam sit amet
            nunc in lectus fermentum dictum. Nulla facilisi. Nullam et orci in
            erat euismod dictum. Sed vestibulum, nunc vel ullamcorper elementum,
            nisl dolor convallis nunc, eget ullamcorper magna nibh ut nibh.
            Integer quis nibh non nibh bibendum dictum.
          </TextNew>

          <TextNew color={colors.white}>
            Nullam sit amet nunc in lectus fermentum dictum. Nulla facilisi.
            Nullam et orci in erat euismod dictum. Sed vestibulum, nunc vel
            ullamcorper elementum, nisl dolor convallis nunc, eget ullamcorper
            magna nibh ut nibh. Integer quis nibh non nibh bibendum dictum.
            Nullam sit amet nunc in lectus fermentum dictum. Nulla facilisi.
            Nullam et orci in erat euismod dictum. Sed vestibulum, nunc vel
            ullamcorper elementum, nisl dolor convallis nunc, eget ullamcorper
            magna nibh ut nibh. Integer quis nibh non nibh bibendum dictum.
          </TextNew>

          {/* <TextNew color="white" size={'small'} italic>
            {`size: ${AdSize.LARGE_RECTANGLE} | data-ad=${AdId.rightRail2} | adblockDetected: ${adblockDetected}`}
          </TextNew>
          <RevIqAdComponent
            adSize={AdSize.LARGE_RECTANGLE}
            adId={AdId.rightRail2}
            adblockDetected={adblockDetected}
          />

          <TextNew color="white" size={'small'} italic>
            {`size: ${AdSize.LARGE_BANNER} | data-ad=${AdId.leftRail1} | adblockDetected: ${adblockDetected}`}
          </TextNew>
          <RevIqAdComponent
            adSize={AdSize.LARGE_BANNER}
            adId={AdId.leftRail1}
            adblockDetected={adblockDetected}
          />

          <TextNew color="white" size={'small'} italic>
            {`size: ${AdSize.BANNER} | data-ad=${AdId.leftRail2} | adblockDetected: ${adblockDetected}`}
          </TextNew>
          <RevIqAdComponent
            adSize={AdSize.BANNER}
            adId={AdId.leftRail2}
            adblockDetected={adblockDetected}
          /> */}

          <Spacer height={'huge'} />
        </PageContent>
      </Flex>
    </Layout>
  )
}
