/** @jsxImportSource @emotion/react */

import { GenerateQuizButton } from '../../components/GenerateQuizButton'
import { Header } from '../../components/Header'
import { Layout } from '../../components/layout/Layout'
import { PageContent } from '../../components/layout/PageContent'
import { AdminAccessControlComponent } from './AdminAccessControlComponent'
import { AdminNavbar } from './Navbar'

const Content = () => {
  return (
    <PageContent gap={'medium'}>
      <AdminNavbar activePage="quiz" />

      <GenerateQuizButton />
    </PageContent>
  )
}

export const AdminQuizPage = () => {
  return (
    <Layout>
      <AdminAccessControlComponent>
        <Header color="green" />
        <Content />
      </AdminAccessControlComponent>
    </Layout>
  )
}
