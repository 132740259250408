/**
 * Important!
 * Keep this file in sync with webNativeApi.ts in the /web and /native folders
 */

/**
 * WebviewToNative
 */

export type WebviewToNativeMessage =
  | {
      type: 'ask-for-push-notification'
    }
  | {
      type: 'request-push-notification-permission-status'
    }
  | {
      type: 'set-is-logged-in'
      isLoggedIn: boolean
    }
  | {
      type: 'request-tracking-permissions'
    }
  | {
      type: 'open-login-modal'
    }
  | {
      type: 'auth-error'
      message: string | null
    }
  | { type: 'start-quiz' }
  | { type: 'quiz-completed' }

export function serializeWebviewToNativeMessage(
  message: WebviewToNativeMessage
): string {
  return JSON.stringify(message)
}

export function parseWebviewToNativeMessage(
  data: string
): WebviewToNativeMessage | undefined {
  try {
    return JSON.parse(data) as WebviewToNativeMessage
  } catch (error) {
    console.error(error)
  }

  return undefined
}

/**
 * NativeToWebview
 */

export type PermissionStatus = 'granted' | 'undetermined' | 'denied'
export type NativeToWebviewMessage =
  | {
      type: 'set-push-notification-token'
      token: string
    }
  | {
      type: 'set-push-notifications-permission-status'
      status: PermissionStatus
    }
  | {
      type: 'request-tracking-permissions'
      status: PermissionStatus
    }
  | {
      type: 'set-auth-user'
      token: string
    }
  | {
      type: 'sign-up'
      details: {
        email: string
        password: string
        playerName: string
      }
    }

export function serializeNativeToWebviewMessage(
  message: NativeToWebviewMessage
): string {
  return JSON.stringify(message)
}

export function parseNativeToWebviewMessage(
  data: string
): NativeToWebviewMessage | undefined {
  try {
    return JSON.parse(data) as NativeToWebviewMessage
  } catch (error) {
    console.error(error)
  }

  return undefined
}

export const WEBVIEW_ON_MESSAGE_FUNCTION_NAME = 'webviewOnMessage'
export const NATIVE_TO_WEBVIEW_CONSTANTS_VARIABLE_NAME =
  'nativeToWebviewConstants'

export type NativeToWebviewConstants = {
  platform: 'ios' | 'android'
  applicationId: string | null
  nativeBuildVersion: string | null
}
